import React from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";

interface FormInterface {
  label: string;
  helperText: string;
  type: string;
  value: any;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function FormControlComponent({
  label,
  helperText,
  type,
  value,
  placeholder,
  onChange,
}: FormInterface) {
  return (
    <FormControl mt={2}>
      <FormLabel color={useColorModeValue("#000", "#000")}>{label}</FormLabel>
      <Input
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        color={useColorModeValue("#000", "#000")}
        bg={useColorModeValue("#fff", "#fff")}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
