import React from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";

interface InputProps {
  isRequired: boolean;
  label: string;
  placeholder: string;
  value: string;
  onChange: any;
  type: string;
}
export default function InputComponent({
  isRequired,
  label,
  placeholder,
  value,
  onChange,
  type,
}: InputProps) {
  return (
    <FormControl isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <Input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </FormControl>
  );
}
