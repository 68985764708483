import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import usuarios from "../../../services/usuarios";
import { DataTable } from "../../../components/HOC's/DataTable";
import SkeletonComponent from "../../../components/Form/Skeleton";
import CustomersArray from "../../../utils/Usuarios";
import { createColumnHelper } from "@tanstack/react-table";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import ButtonComponents from "../../../components/Form/Button";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Select,
  Button,
  useToast,
} from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";

export default function Usuarios() {
  const [columns, setcolumns] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [update, setupdate] = useState(false);
  const [form, setform] = useState({
    name: "",
    email: "",
    password: "",
    rol: "",
    status: "",
  });
  const toast = useToast();

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  const getData = () => {
    usuarios.userGet().then((response) => {
      const columnHelper = createColumnHelper<any>();
      const columns = CustomersArray.Columns().map(({ title, campo, buscador }: any) => {
        return columnHelper.accessor(campo, {
          cell: (info: any) => info.getValue(),
          header: title ?? "",
          enableColumnFilter: buscador ?? false,
        });
      });
      setcolumns(columns);
      const data = response;
      setdata(data);
    });
  };

  const ExportExcel = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte-general" + fileExtension);
  };

  const cleanForm = () => {
    setform({
      name: "",
      email: "",
      password: "",
      rol: "",
      status: "",
    });
    setloading(false);
  };

  const guardarUsuario = () => {
    if (
      form.name === "" ||
      form.email === "" ||
      form.password === "" ||
      form.rol === "" ||
      form.status === ""
    ) {
      toast({
        title: "Error!",
        description: "Todos los campos son obligatorios!",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    setloading(true);
    if (update) {
      usuarios
        .userUpdate(form)
        .then((response) => {
          setloading(false);
          if (!response.errors) {
            cleanForm();
            setOpen(false);
            getData();
            toast({
              title: "Correcto!",
              description: "Usuario actualizado correctamente!",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            const errors = Object.keys(response.errors).map((key) => {
              return response.errors[key][0];
            });
            toast({
              title: "Error!",
              description: errors.join(", "),
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          setloading(false);
          toast({
            title: "Error!",
            description: err.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        });
    } else {
      usuarios
        .userAdd(form)
        .then((response) => {
          setloading(false);
          if (!response.errors) {
            cleanForm();
            setOpen(false);
            getData();
            toast({
              title: "Correcto!",
              description: "Usuario creado correctamente!",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            const errors = Object.keys(response.errors).map((key) => {
              return response.errors[key][0];
            });
            toast({
              title: "Error!",
              description: errors.join(", "),
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          setloading(false);
          toast({
            title: "Error!",
            description: err.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        });
    }
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiFile size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Usuarios"
                textTransform={"uppercase"}
              />
            </Flex>
            <Box>
              <Image
                src={"/stihl-logo.svg"}
                height={50}
              />
            </Box>
          </Box>

          <Box mt={5} display={"flex"} justifyContent={"end"}>
            <Box gap={5} display="flex">
              <ButtonComponents
                text="Crear nuevo usuario"
                color="orange"
                onClick={() => {
                  setform({
                    name: "",
                    email: "",
                    password: "",
                    rol: "",
                    status: "",
                  });
                  setOpen(true);
                }}
                size="sm"
              />
            </Box>
          </Box>
          <Box overflowX="auto" mt={4}>
            {data.length === 0 && <SkeletonComponent />}
            <DataTable
              columns={columns}
              data={data}
              edit={true}
              onOpenUpdate={() => {
                setOpen(true);
                setupdate(true);
              }}
              setArray={setform}
              ExportExcel={ExportExcel}
            />
          </Box>
        </Box>

        {open && (
          <Modal
            isOpen={open}
            onClose={() => {
              setOpen(false);
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {update ? "Actualizar usuario" : "Crear nuevo usuario"}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box
                  bg={"white"}
                  p={5}
                  borderRadius={20}
                  gap={2}
                  display="flex"
                  flexDirection="column"
                >
                  <Input
                    type="email"
                    placeholder="Correo electronico"
                    value={form.email}
                    onChange={(e) =>
                      setform({ ...form, email: e.target.value })
                    }
                  />
                  <Input
                    type="text"
                    placeholder="Nombre"
                    value={form.name}
                    onChange={(e) => setform({ ...form, name: e.target.value })}
                  />
                  <Input
                    type="password"
                    placeholder="Contraseña"
                    value={form.password}
                    onChange={(e) =>
                      setform({ ...form, password: e.target.value })
                    }
                  />
                  <Select
                    placeholder="Seleccione un rol"
                    value={form.rol}
                    onChange={(e) => setform({ ...form, rol: e.target.value })}
                  >
                    {[
                      "Master",
                      "Servicio Tecnico",
                      "Contacto Stihl",
                      "Ventas",
                    ].map((rol) => (
                      <option key={rol} value={rol}>
                        {rol}
                      </option>
                    ))}
                  </Select>
                  <Select
                    placeholder="Seleccione un estatus"
                    value={form.status}
                    onChange={(e) =>
                      setform({ ...form, status: e.target.value })
                    }
                  >
                    {["Activo", "Cancelado"].map((rol) => (
                      <option key={rol} value={rol}>
                        {rol}
                      </option>
                    ))}
                  </Select>
                </Box>
              </ModalBody>
              <ModalFooter display="flex" gap={2}>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    setOpen(false);
                  }}
                  size="md"
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  onClick={guardarUsuario}
                  size="md"
                  isLoading={loading}
                >
                  Guardar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Sidebar>
    </div>
  );
}
