/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  useToast,
  Image,
  Flex,
  Heading,
  Progress,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ButtonComponents from "../../../components/Form/Button";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import salesOrder from "../../../services/salesOrder";
import { DataTable } from "../../../components/HOC's/DataTableVentasN";
import SkeletonComponent from "../../../components/Form/Skeleton";
import { createColumnHelper } from "@tanstack/react-table";
import { options, generateData, Columns } from "../../../utils/SalesOrder";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { FiDollarSign } from "react-icons/fi";
import dashboard from "../../../services/dashboard";
import { getValues } from "../../../reducers/loginReducer";
import { useSelector } from "react-redux";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/locale/es";
registerLocale("es", es);
setDefaultLocale("es");

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function PosiblesClientes() {
  const { id } = useSelector(getValues);
  const toast = useToast();
  const [form, setform] = useState<any>({
    desde: null,
    hasta: null,
  });
  const [arrayDataFuente, setarrayDataFuente] = useState<any>([]);
  const [loader, setloader] = useState(false);
  const [totalVenta, settotalVenta] = useState<any>(0);
  const [data, setdata] = useState<any>({
    clientes: 0,
    venta_anual: 0,
    satisfaccion: {
      insatisfecho: 0,
      neutral: 0,
      satisfechos: 0,
    },
    venta_mes: 0,
    venta_mes_anterior: 0,
  });
  const [ColumnsEquipos, setColumnsEquipos] = useState<any>([]);
  const [dataEquipos, setdataEquipos] = useState<any>([]);
  const [ColumnsEquiposVendidos, setColumnsEquiposVendidos] = useState<any>([]);
  const [dataEquiposVendidos, setdataEquiposVendidos] = useState<any>([]);
  const [ColumnsAccesoriosVendidos, setColumnsAccesoriosVendidos] =
    useState<any>([]);
  const [dataAccesoriosVendidos, setdataAccesoriosVendidos] = useState<any>([]);
  const [newData, setNewData] = useState<any>({
    implementos: 0,
    productos: 0,
  });
  const [informacion, setInformacion] = useState<any>({
    cantidad: 0,
    total_precio: 0,
    total_total: 0,
  });

  useEffect(() => {
    dashboard.DashboardGet(id).then((res) => {
      setdata(res);
    });
    dashboard.productosVendidosGet().then((res) => {
      setNewData((prev: any) => {
        return {
          ...prev,
          implementos: res.implementos,
          productos: res.productos,
        };
      });
    });
    return () => {};
  }, []);

  const buscarReporte = () => {
    if (!form.desde) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de inicio",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    if (!form.hasta) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de finalización",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    setloader(true);
    salesOrder
      .getSalesOrderGet({
        desde: new Date(form.desde).toISOString().split("T")[0],
        hasta: new Date(form.hasta).toISOString().split("T")[0],
      })
      .then((response: any) => {
        const total = response.total_venta;
        const totalVenta = total;
        const totalVentaNumber = parseFloat(totalVenta);

        setarrayDataFuente(generateData(response.resultados_por_fuente));
        settotalVenta(totalVentaNumber);
        setloader(false);

        createArrayListadoProductos(response);
        createArrayProductosVendidos(response);
        createArrayAccesoriosVendidos(response);
      });
  };

  const createArrayListadoProductos = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = Columns().map(({ title, campo }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
      });
    });
    setColumnsEquipos(columns);
    const data = response.listado_productos;
    const newData = data.map((item: any) => {
      return {
        ...item,
        cantidad: "" + item.cantidad,
        precio: "" + item.precio,
        total: "" + item.total,
      };
    });
    setdataEquipos(newData);
  };

  const createArrayProductosVendidos = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = Columns().map(({ title, campo }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
      });
    });
    setColumnsEquiposVendidos(columns);
    const data = response.listado_productos;
    const newData = data.map((item: any) => {
      return {
        ...item,
        cantidad: "" + item.cantidad,
        precio: "" + item.precio,
        total: "" + item.total,
      };
    });
    setdataEquiposVendidos(newData);
  };

  const createArrayAccesoriosVendidos = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = Columns().map(({ title, campo, buscador }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
        enableColumnFilter: buscador ?? false,
      });
    });
    setColumnsAccesoriosVendidos(columns);
    /*const data = response.accesorios_vendidos;
    const newData = data.map((item: any) => {
      return {
        ...item,
        cantidad: "" + item.cantidad,
        precio: "" + item.precio,
        total: "" + item.total,
      };
    });*/

    const data_productos = response.listado_productos;
    const newData = data_productos.map((item: any) => {
      return {
        ...item,
        cantidad: "" + item.cantidad,
        precio: "" + (item?.precio > 0 ? item?.precio : 0),
        total: "" + (item?.total > 0 ? item?.total : 0),
      };
    });

    newData.sort((a: any, b: any) => {
      const totalA = parseFloat(a.cantidad);
      const totalB = parseFloat(b.cantidad);
      if (totalA > totalB) {
        return -1;
      }
      if (totalA < totalB) {
        return 1;
      }
      return 0;
    });
    // sacar el total de productos vendidos
    const total = newData.reduce((acc: any, item: any) => {
      return acc + parseFloat(item.cantidad);
    }, 0);
    setInformacion((prev: any) => {
      return {
        ...prev,
        cantidad: total,
        total_precio: newData.reduce((acc: any, item: any) => {
          return acc + parseFloat(item.precio);
        }, 0),
        total_total: newData.reduce((acc: any, item: any) => {
          return acc + parseFloat(item.total);
        }, 0),
      };
    });
    setdataAccesoriosVendidos(newData);
  };

  const ExportExcel = (array: any) => {
    const newArray = dataAccesoriosVendidos.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      delete res.cantidad_number;
      delete res.monto_number;
      delete res.zonas;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Productos vendidos" + fileExtension);
  };

  const parseMoney = (money: any) => {
    return money.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const parsearNumeroInMilion = (numero: number) => {
    return (
      (numero / 1000000)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " M"
    );
  };

  const sacarPorcentaje = (numero: number, tope: number) => {
    const dato = (numero * 100) / tope;
    return Math.round(dato * 100) / 100 > 100
      ? 100
      : Math.round(dato * 100) / 100;
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiDollarSign size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Reporte de ventas"
                textTransform={"uppercase"}
              />
            </Flex>
            <Image src={"/stihl-logo.svg"} height={50} />
          </Box>
          <Flex width={"100%"} justifyContent={"space-between"} gap={10}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              mt={5}
              bg={"#eeeeee"}
              px={10}
              borderRadius={20}
              width={"50%"}
              height={"100%"}
            >
              <Box mt={2} />
              <DatePicker
                isClearable={true}
                selectsRange={true}
                startDate={form.desde}
                endDate={form.hasta}
                onChange={(date: any) => {
                  setform({ ...form, desde: date[0], hasta: date[1] });
                }}
                dateFormat="yyyy-MM-dd"
                placeholderText="Fecha de inicio - Fecha de finalización"
                className="form-control w-100"
                showYearDropdown
                locale={"es"}
              />

              <Flex mt={5} justifyContent={"flex-end"} gap={4}>
                <Box mt={5}>
                  <ButtonComponents
                    text="Buscar"
                    color="#FF7B05"
                    onClick={buscarReporte}
                    size="md"
                  />
                </Box>
              </Flex>

              <Box mt={10}>
                {totalVenta > 0 && (
                  <Box w="100%" bg="#eeeeee" p={4} borderRadius="md">
                    <Heading as="h3" size="md" textAlign="center">
                      TOTAL DE VENTAS
                    </Heading>

                    <Box width="100%" height="100%" mt={5}>
                      <Flex justifyContent="center" alignItems="center">
                        <div className="circle-orange2x">
                          <Heading as="h3" size="xl" textAlign="center">
                            {parseMoney(totalVenta)}
                          </Heading>
                        </div>
                      </Flex>
                    </Box>
                  </Box>
                )}
                {/*totalVenta > 0 && (
                  <Barometer
                    title="Total de ventas"
                    id="Total"
                    value={totalVenta}
                  />
                )*/}
              </Box>

              <Box
                display={"flex"}
                justifyContent={"center"}
                marginTop={"20px"}
                marginBottom={"50px"}
              >
                {arrayDataFuente?.labels?.length > 0 && (
                  <Bar options={options} data={arrayDataFuente} />
                )}
              </Box>

              {arrayDataFuente?.labels?.length > 0 && (
                <>
                  <Box w="100%" paddingInline={5}>
                    <Heading as="h3" size="sm" textAlign="center">
                      Ventas por mes{" "}
                      {sacarPorcentaje(
                        data?.venta_mes ?? 0,
                        data?.tope_mes ?? 0
                      )}
                      %
                    </Heading>
                    <Flex gap={2} mt={2}>
                      <Heading as="h5" size="sm" textAlign="center" width={63}>
                        {parsearNumeroInMilion(data?.venta_mes ?? 0)}
                      </Heading>
                      <Progress
                        width={"80%"}
                        colorScheme="orange"
                        height="20px"
                        value={sacarPorcentaje(
                          data?.venta_mes ?? 0,
                          data?.tope_mes ?? 0
                        )}
                      />
                      <Heading as="h5" size="sm" textAlign="center">
                        {parsearNumeroInMilion(data?.tope_mes ?? 0)}
                      </Heading>
                    </Flex>
                  </Box>

                  <Box w="100%" paddingInline={5} mt={7}>
                    <Heading as="h3" size="sm" textAlign="center">
                      Comparativo del mes vs año pasado{" "}
                      {sacarPorcentaje(
                        data?.venta_mes ?? 0,
                        data?.["tope_año"] ?? 0
                      )}
                      %
                    </Heading>
                    <Flex gap={2} mt={2}>
                      <Heading as="h5" size="sm" textAlign="center" width={63}>
                        {parsearNumeroInMilion(data?.venta_mes ?? 0)}
                      </Heading>
                      <Progress
                        width={"80%"}
                        colorScheme="orange"
                        height="20px"
                        value={sacarPorcentaje(
                          data?.venta_mes ?? 0,
                          data?.["tope_año"] ?? 0
                        )}
                      />
                      <Heading as="h5" size="sm" textAlign="center">
                        {parsearNumeroInMilion(data?.["tope_año"] ?? 0)}
                      </Heading>
                    </Flex>
                  </Box>

                  <Box w="100%" paddingInline={5} mt={7}>
                    <Heading as="h3" size="sm" textAlign="center">
                      Acumulado del año{" "}
                      {sacarPorcentaje(
                        data?.total_ano ?? 0,
                        data?.tope_mes ?? 0
                      )}
                      %
                    </Heading>
                    <Flex gap={2} mt={2}>
                      <Heading as="h5" size="sm" textAlign="center" width={63}>
                        {parsearNumeroInMilion(data?.total_ano ?? 0)}
                      </Heading>
                      <Progress
                        width={"80%"}
                        colorScheme="orange"
                        height="20px"
                        value={sacarPorcentaje(
                          data?.total_ano ?? 0,
                          data?.["tope_año"] ?? 0
                        )}
                      />
                      <Heading as="h5" size="sm" textAlign="center">
                        {parsearNumeroInMilion(data?.["tope_año"] ?? 0)}
                      </Heading>
                    </Flex>
                  </Box>

                  <Box w="100%" paddingInline={5} mt={7}>
                    <Heading as="h3" size="sm" textAlign="center">
                      Implementos vendidos{" "}
                    </Heading>
                    <Flex gap={2} mt={2}>
                      <Heading as="h5" size="sm" textAlign="center" width={63}>
                        {newData?.implementos ?? 0}
                      </Heading>
                      <Progress
                        width={"80%"}
                        colorScheme="orange"
                        height="20px"
                        value={sacarPorcentaje(
                          newData?.implementos ?? 0,
                          500 ?? 0
                        )}
                      />
                      <Heading as="h5" size="sm" textAlign="center">
                        {500}
                      </Heading>
                    </Flex>
                  </Box>

                  <Box w="100%" paddingInline={5} mt={7} mb={20}>
                    <Heading as="h3" size="sm" textAlign="center">
                      Productos vendidos{" "}
                    </Heading>
                    <Flex gap={2} mt={2}>
                      <Heading as="h5" size="sm" textAlign="center" width={63}>
                        {newData?.productos ?? 0}
                      </Heading>
                      <Progress
                        width={"80%"}
                        colorScheme="orange"
                        height="20px"
                        value={sacarPorcentaje(
                          newData?.productos ?? 0,
                          500 ?? 0
                        )}
                      />
                      <Heading as="h5" size="sm" textAlign="center">
                        {500}
                      </Heading>
                    </Flex>
                  </Box>
                </>
              )}
            </Box>

            <Box display={"flex"} flexDirection={"row"} width={"50%"}>
              <Box overflowX="auto" mt={4} width={"100%"}>
                {/*dataEquipos.length > 0 && (
                  <>
                    <TextComponent
                      color={"#000"}
                      fontSize={"2xl"}
                      fontWeight={"bold"}
                      textAlign={"center"}
                      text="Listado de productos"
                    />
                    <DataTable
                      columns={ColumnsEquipos}
                      data={dataEquipos}
                      edit=""
                      onOpenUpdate={() => {}}
                      setArray={[]}
                      total={true}
                      size={"sm"}
                    />
                  </>
                )*/}

                {dataEquiposVendidos.length > 0 && (
                  <>
                    <TextComponent
                      color={"#000"}
                      fontSize={"2xl"}
                      fontWeight={"bold"}
                      textAlign={"center"}
                      text="Productos vendidos"
                    />
                    <div style={{ marginBottom: 10 }} />
                    <DataTable
                      columns={ColumnsAccesoriosVendidos}
                      data={dataAccesoriosVendidos}
                      edit=""
                      onOpenUpdate={() => {}}
                      setArray={[]}
                      total={true}
                      size={""}
                      ExportExcel={ExportExcel}
                      cantidad={informacion.cantidad}
                      total_precio={informacion.total_precio}
                      total_total={informacion.total_total}
                    />
                  </>
                )}

                {/*dataEquiposVendidos.length > 0 && (
                  <>
                    <TextComponent
                      color={"#000"}
                      fontSize={"2xl"}
                      fontWeight={"bold"}
                      textAlign={"center"}
                      text="Accesorios vendidos"
                    />
                    <DataTable
                      columns={ColumnsEquiposVendidos}
                      data={dataEquiposVendidos}
                      edit=""
                      onOpenUpdate={() => {}}
                      setArray={[]}
                      total={true}
                      size={"sm"}
                    />
                  </>
                )*/}
              </Box>
            </Box>
          </Flex>
          {loader && <SkeletonComponent />}
        </Box>
      </Sidebar>
    </div>
  );
}
