/* eslint-disable import/no-anonymous-default-export */
function Columns() {
  return [
    { title: "Folio", campo: "folio_interno", buscador: false },
    { title: "Fecha", campo: "fecha_venta", buscador: false },
    { title: "Distribuidor", campo: "distribuidor", buscador: false },
    { title: "Nombre", campo: "nombre", buscador: false },
    { title: "Apellido", campo: "apellido", buscador: false },
    { title: "Titulo", campo: "titulo", buscador: false },
    { title: "Total", campo: "total", buscador: false },
    { title: "Factura", campo: "factura", buscador: false, type: "file" },
  ];
}

export default {
  Columns,
};
