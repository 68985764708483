/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import ticket from "../../../services/ticket";
import { DataTable } from "../../../components/HOC's/DataTableTickets";
import SkeletonComponent from "../../../components/Form/Skeleton";
import CustomersArray from "../../../utils/Tickets";
import { createColumnHelper } from "@tanstack/react-table";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { FiFile } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { getValues } from "../../../reducers/loginReducer";
import { useSelector } from "react-redux";

export default function Usuarios() {
  const navigate = useNavigate();
  const [columns, setcolumns] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const { id } = useSelector(getValues);

  useEffect(() => {
    getData();
    return () => {};
  }, []);



  const getData = () => {
    ticket.GetTickets(id).then((response) => {
      const columnHelper = createColumnHelper<any>();
      const columns = CustomersArray.Columns().map(({ title, campo, buscador }: any) => {
        return columnHelper.accessor(campo, {
          cell: (info: any) => info.getValue(),
          header: title ?? "",
          enableColumnFilter: buscador ?? false,
        });
      });
      setcolumns(columns);
      const data = response.tickets?.reverse();
      const newData = data?.map((res: any) => {
        return {
          ...res,
          nombre_completo: (res?.nombre ?? "No proporcionado") + " " + (res?.apellido ?? "No proporcionado"),
          correo: res?.correo ?? "No proporcionado",
          telefono: res?.telefono ?? "No proporcionado",
          name2: res?.name2 ?? "Aun no asignado",
        };
      })
      setdata(newData);
    });
  };

  const ExportExcel = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte-general" + fileExtension);
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiFile size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Listado de tickets"
                textTransform={"uppercase"}
              />
            </Flex>
            <Box>
              <Image
                src={"/stihl-logo.svg"}
                height={50}
              />
            </Box>
          </Box>
          <Box overflowX="auto" mt={4}>
            {data.length === 0 && <SkeletonComponent />}
            <DataTable
              columns={columns}
              data={data}
              edit={true}
              onOpenUpdate={(data: any) => {
                navigate("/crm/tickets/"+ data.folio +"/ver");
              }}
              setArray={() => {}}
              ExportExcel={ExportExcel}
            />
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
}
