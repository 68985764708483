import React from "react";
import Nav from "../../components/UI/Navbar";
import SidebarWeb from "../../components/UI/SidebarWeb";

export default function Index() {
  return (
    <div>
      <Nav />
      <SidebarWeb>
      </SidebarWeb>
    </div>
  );
}
