import {
  Box,
  Flex,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ButtonComponents from "../../../components/Form/Button";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import leads from "../../../services/leads";
import { DataTable } from "../../../components/HOC's/DataTableClientesNew";
import SkeletonComponent from "../../../components/Form/Skeleton";
import LeadsArray from "../../../utils/Leads";
import { createColumnHelper } from "@tanstack/react-table";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { FiUsers } from "react-icons/fi";
import dealers from "../../../services/dealers";
import Toast from "../../../utils/Toast";

export default function PosiblesClientes() {
  const [columns, setcolumns] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [loading, setloading] = useState<boolean>(false);
  const [search, setsearch] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenNotas, setisOpenNotas] = useState<boolean>(false);
  const [array, setArray] = useState<any>({});
  const [estados, setestados] = useState<any>([]);
  const [medios, setmedios] = useState<any>([]);
  const [fuentes, setfuentes] = useState<any>([]);
  const [notasData, setNotasData] = useState<any>({});
  const [notasArray, setNotasArray] = useState<any>([]);

  useEffect(() => {
    dealers.estadosGet().then((response) => {
      setestados(response.estados);
    });
    dealers.mediosGet().then((response) => {
      setmedios(response);
    });
    dealers.fuentesGet().then((response) => {
      setfuentes(response);
    });
  }, []);

  const obtenerInformacion = () => {
    leads.clienteGet().then((response) => {
      const columnHelper = createColumnHelper<any>();
      const columns = LeadsArray.ColumnsCliente().map(
        ({ title, campo }: any) => {
          return columnHelper.accessor(campo, {
            cell: (info: any) => info.getValue(),
            header: title ?? "",
            enableColumnFilter: false,
          });
        }
      );
      setcolumns(columns);
      const data = response;

      const newData = data.map((res: any) => {
        return {
          ...res,
          name:
            (res?.nombre ?? "") + " " + (res?.apellido ?? "") ??
            "No proporcionado",
          correo: res?.correo ?? "No proporcionado",
          telefono: res?.telefono ?? "No proporcionado",
          estatus: res?.estatus ?? "No proporcionado",
        };
      });
      setdata(newData);
      setloading(false);
    });
  };

  useEffect(() => {
    if (search === "") {
      obtenerInformacion();
    }
    return () => {};
  }, [search]);

  const getData = () => {
    leads.clienteSearch(search).then((response) => {
      const columnHelper = createColumnHelper<any>();
      const columns = LeadsArray.ColumnsCliente().map(
        ({ title, campo }: any) => {
          return columnHelper.accessor(campo, {
            cell: (info: any) => info.getValue(),
            header: title ?? "",
            enableColumnFilter: false,
          });
        }
      );
      setcolumns(columns);
      const data = response;

      const newData = data.map((res: any) => {
        return {
          ...res,
          name:
            (res?.nombre ?? "") + " " + (res?.apellido ?? "") ??
            "No proporcionado",
          correo: res?.correo ?? "No proporcionado",
          telefono: res?.telefono ?? "No proporcionado",
          estatus: res?.estatus ?? "No proporcionado",
        };
      });
      setdata(newData);
      setloading(false);
    });
  };

  const obteniendoInformacionNotas = (id: any) => {
    leads.noteGet(id, "cliente").then((response) => {
      setNotasArray(response);
    });
  };

  const ExportExcel = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte-general" + fileExtension);
  };

  const actualizar = () => {
    leads.clientUpdate(array).then((response) => {
      if (response.guardado) {
        Toast.fire({
          icon: "success",
          title: "Cliente actualizado correctamente",
        });
        setIsOpen(false);
        obtenerInformacion();
      } else {
        Toast.fire({
          icon: "error",
          title: "Error al actualizar",
        });
      }
    });
  };

  const guardarNotas = () => {
    leads
      .noteAdd({
        id: array.id,
        descripcion: notasData.descripcion,
        tipo: "cliente",
      })
      .then((response) => {
        if (response.guardado) {
          Toast.fire({
            icon: "success",
            title: "Nota guardada correctamente",
          });
          obteniendoInformacionNotas(array.id);
        } else {
          Toast.fire({
            icon: "error",
            title: "Error al guardar la nota",
          });
        }
      });
  };

  const actualizarNotas = () => {
    leads
      .noteUpdate({
        id: notasData.id,
        descripcion: notasData.descripcion,
        tipo: "cliente",
      })
      .then((response) => {
        if (response.guardado) {
          Toast.fire({
            icon: "success",
            title: "Nota guardada correctamente",
          });
          setNotasData({});
          obteniendoInformacionNotas(array.id);
        } else {
          Toast.fire({
            icon: "error",
            title: "Error al guardar la nota",
          });
        }
      });
  };

  const eliminarNota = (id: any) => {
    leads
      .noteDelete({
        id,
      })
      .then((response) => {
        if (response.eliminado) {
          Toast.fire({
            icon: "success",
            title: "Nota guardada correctamente",
          });
          setNotasData({});
          obteniendoInformacionNotas(array.id);
        } else {
          Toast.fire({
            icon: "error",
            title: "Error al guardar la nota",
          });
        }
      });
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        {isOpen && (
          <ModalEdición
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            title={"Editar " + array.nombre ?? "" + " " + array.apellido ?? ""}
            array={array}
            setArray={setArray}
            orderAddFactura={actualizar}
            estados={estados}
            medios={medios}
            fuentes={fuentes}
          />
        )}
        {isOpenNotas && (
          <ModalNotas
            isOpen={isOpenNotas}
            onClose={() => setisOpenNotas(false)}
            title={
              "Notas de " + array.nombre ?? "" + " " + array.apellido ?? ""
            }
            notasData={notasData}
            setNotasData={setNotasData}
            guardarNotas={guardarNotas}
            notasArray={notasArray}
            setNotasArray={setNotasArray}
            actualizarNotas={actualizarNotas}
            eliminarNota={eliminarNota}
          />
        )}
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiUsers size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Clientes"
                textTransform={"uppercase"}
              />
            </Flex>
            <Image src={"/stihl-logo.svg"} height={50} />
          </Box>

          <Box display={"flex"} justifyContent={"flex-end"} mt={4}>
            <input
              type="text"
              placeholder="Buscar"
              className="input-search"
              style={{
                width: "300px",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #f06e26",
                marginRight: "10px",
              }}
              value={search}
              onChange={(e) => setsearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setloading(true);
                  getData();
                }
              }}
            />

            <ButtonComponents
              text="Buscar"
              onClick={() => {
                setloading(true);
                getData();
              }}
              color={"#f06e26"}
              borderRadius="5px"
              size="md"
              loading={loading}
            />
          </Box>
          <Box overflowX="auto" mt={4}>
            {data.length === 0 && <SkeletonComponent />}
            <DataTable
              columns={columns}
              data={data}
              edit=""
              onOpenUpdate={() => {
                setIsOpen(true);
              }}
              onOpenNotas={(row: any) => {
                setisOpenNotas(true);
                obteniendoInformacionNotas(row.id);
              }}
              setArray={setArray}
              ExportExcel={ExportExcel}
            />
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
}

const ModalEdición = ({
  isOpen,
  onClose,
  title,
  array,
  setArray,
  orderAddFactura,
  estados,
  medios,
  fuentes,
}: any) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <Box p={5}>
          <Box p={5}>
            <TextComponent
              color={"#f06e26"}
              fontSize={"3xl"}
              fontWeight={"bolder"}
              textAlign={"center"}
              text={title}
              textTransform={"uppercase"}
            />
          </Box>
          {/* form array */}
          <Box>
            <TextComponent
              color={"#000"}
              fontSize={"xs"}
              fontWeight={"300"}
              textAlign={"start"}
              text={"Nombre"}
              textTransform={"uppercase"}
            />
            <Input
              placeholder="Nombre"
              value={array.nombre}
              mb={2}
              type="text"
              onChange={(e: any) => {
                setArray({ ...array, nombre: e.target.value });
              }}
            />
          </Box>
          <Box>
            <TextComponent
              color={"#000"}
              fontSize={"xs"}
              fontWeight={"300"}
              textAlign={"start"}
              text={"Apellido"}
              textTransform={"uppercase"}
            />
            <Input
              placeholder="Apellido"
              value={array.apellido}
              mb={2}
              type="text"
              onChange={(e: any) => {
                setArray({ ...array, apellido: e.target.value });
              }}
            />
          </Box>
          <Box>
            <TextComponent
              color={"#000"}
              fontSize={"xs"}
              fontWeight={"300"}
              textAlign={"start"}
              text={"Telefono"}
              textTransform={"uppercase"}
            />
            <Input
              placeholder="Telefono"
              value={array.telefono}
              mb={2}
              type="text"
              onChange={(e: any) => {
                setArray({ ...array, telefono: e.target.value });
              }}
            />
          </Box>
          <Box>
            <TextComponent
              color={"#000"}
              fontSize={"xs"}
              fontWeight={"300"}
              textAlign={"start"}
              text={"Correo Electronico"}
              textTransform={"uppercase"}
            />
            <Input
              placeholder="Correo Electronico"
              value={array.correo}
              mb={2}
              type="text"
              onChange={(e: any) => {
                setArray({ ...array, correo: e.target.value });
              }}
            />
          </Box>
          <Box>
            <TextComponent
              color={"#000"}
              fontSize={"xs"}
              fontWeight={"300"}
              textAlign={"start"}
              text={"Ciudad"}
              textTransform={"uppercase"}
            />
            <Input
              placeholder="Ciudad"
              value={array.ciudad}
              mb={2}
              type="text"
              onChange={(e: any) => {
                setArray({ ...array, ciudad: e.target.value });
              }}
            />
          </Box>
          <Box>
            <TextComponent
              color={"#000"}
              fontSize={"xs"}
              fontWeight={"300"}
              textAlign={"start"}
              text={"Estado"}
              textTransform={"uppercase"}
            />
            <select
              defaultValue={array.estado}
              onChange={(e) => {
                setArray({ ...array, estado: e.target.value });
              }}
              style={{
                width: "100%",
                padding: 10,
                borderRadius: 5,
                border: "1px solid lightgray",
              }}
            >
              <option value={""}>Selecciona un estado</option>
              {estados.map((res: any) => {
                return (
                  <option
                    value={res.label}
                    selected={array.estado === res.label}
                  >
                    {res.label}
                  </option>
                );
              })}
            </select>
          </Box>
          <Box mt={2}>
            <TextComponent
              color={"#000"}
              fontSize={"xs"}
              fontWeight={"300"}
              textAlign={"start"}
              text={"Medio"}
              textTransform={"uppercase"}
            />
            <select
              defaultValue={array.medio}
              onChange={(e) => {
                setArray({ ...array, medio: e.target.value });
              }}
              style={{
                width: "100%",
                padding: 10,
                borderRadius: 5,
                border: "1px solid lightgray",
              }}
            >
              <option value={""}>Selecciona un medio</option>
              {medios.map((res: any) => {
                return (
                  <option value={res} selected={array.medio === res}>
                    {res}
                  </option>
                );
              })}
            </select>
          </Box>
          <Box mt={2}>
            <TextComponent
              color={"#000"}
              fontSize={"xs"}
              fontWeight={"300"}
              textAlign={"start"}
              text={"Fuente"}
              textTransform={"uppercase"}
            />
            <select
              defaultValue={array.fuente}
              onChange={(e) => {
                setArray({ ...array, fuente: e.target.value });
              }}
              style={{
                width: "100%",
                padding: 10,
                borderRadius: 5,
                border: "1px solid lightgray",
              }}
            >
              <option value={""}>Selecciona una fuente</option>
              {fuentes.map((res: any) => {
                return (
                  <option value={res} selected={array.fuente === res}>
                    {res}
                  </option>
                );
              })}
            </select>
          </Box>
          <Box mt={2}>
            <TextComponent
              color={"#000"}
              fontSize={"xs"}
              fontWeight={"300"}
              textAlign={"start"}
              text={"CP"}
              textTransform={"uppercase"}
            />
            <Input
              placeholder="cp"
              value={array.cp}
              mb={2}
              type="text"
              onChange={(e: any) => {
                setArray({ ...array, cp: e.target.value });
              }}
            />
          </Box>
        </Box>
        <ModalFooter>
          <Box
            bg={"#f06e26"}
            borderRadius={10}
            color={"white"}
            cursor={"pointer"}
            p={3}
            w={"50%"}
            _hover={{ bg: "#f06e26" }}
            textAlign={"center"}
            onClick={() => {
              orderAddFactura(array);
            }}
          >
            Guardar
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ModalNotas = ({
  isOpen,
  onClose,
  title,
  notasData,
  setNotasData,
  guardarNotas,
  actualizarNotas,
  eliminarNota,
  notasArray,
  setNotasArray,
}: any) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <Box p={5}>
          <Box p={5}>
            <TextComponent
              color={"#f06e26"}
              fontSize={"3xl"}
              fontWeight={"bolder"}
              textAlign={"center"}
              text={title}
              textTransform={"uppercase"}
            />
          </Box>
          {/* form array */}
          <Box>
            <TextComponent
              color={"#000"}
              fontSize={"xs"}
              fontWeight={"300"}
              textAlign={"start"}
              text={"Descripcion"}
              textTransform={"uppercase"}
            />
            <Input
              placeholder="Descripcion"
              value={notasData.descripcion}
              mb={2}
              type="text"
              onChange={(e: any) => {
                setNotasData({ ...notasData, descripcion: e.target.value });
              }}
            />
          </Box>
          <Box mt={2}></Box>
          <Flex justifyContent={"end"}>
            <Box
              bg={"#f06e26"}
              borderRadius={10}
              color={"white"}
              cursor={"pointer"}
              p={1}
              w={"25%"}
              _hover={{ bg: "#f06e26" }}
              textAlign={"center"}
              onClick={() => {
                if (notasData.id) {
                  actualizarNotas(notasData);
                } else {
                  guardarNotas(notasData);
                }
              }}
            >
              {notasData.id ? "Actualizar" : "Guardar"}
            </Box>
          </Flex>
        </Box>
        <Box px={5} py={10}>
          <TextComponent
            color={"#000"}
            fontSize={"xs"}
            fontWeight={"300"}
            textAlign={"start"}
            text={"Notas"}
            textTransform={"uppercase"}
          />
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Descripción</Th>
                  <Th>Fecha</Th>
                  <Th>Acciones</Th>
                </Tr>
              </Thead>
              <Tbody>
                {notasArray.map((res: any, index: any) => {
                  return (
                    <Tr>
                      <Td>{index + 1}</Td>
                      <Td>{res.descripcion}</Td>
                      <Td>{new Date(res.created_at).toLocaleDateString()}</Td>
                      <Td>
                        <ButtonComponents
                          text="Editar"
                          color={"#f06e26"}
                          borderRadius="5px"
                          size="sm"
                          onClick={() => {
                            setNotasData(res);
                          }}
                        />
                      </Td>
                      <Td>
                        <ButtonComponents
                          text="Eliminar"
                          color={"#f06e26"}
                          borderRadius="5px"
                          size="sm"
                          onClick={() => {
                            eliminarNota(res.id);
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </ModalContent>
    </Modal>
  );
};
