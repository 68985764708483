/* eslint-disable import/no-anonymous-default-export */
const ENDPOINT = process.env.REACT_APP_URL;

async function tipoReporteGet() {
  return await fetch(`${ENDPOINT}tipoReporteGet`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function tipoReporteAdd(data: any) {
  return await fetch(`${ENDPOINT}tipoReporteAdd`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}


async function tipoReporteUpdate(data: any) {
  return await fetch(`${ENDPOINT}tipoReporteUpdate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function tipoReporteDelete(data: any) {
  return await fetch(`${ENDPOINT}tipoReporteDelete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

export default {
  tipoReporteGet,
  tipoReporteAdd,
  tipoReporteUpdate,
  tipoReporteDelete
};
