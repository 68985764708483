import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TextComponent from "../../../components/Form/Text";
import Input from "../../../components/Form/Input";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import dealers from "../../../services/dealers";
import { DataTable } from "../../../components/HOC's/DataTableDistribuidor";
import SkeletonComponent from "../../../components/Form/Skeleton";
import DealersArray from "../../../utils/Dealers";
import { createColumnHelper } from "@tanstack/react-table";
import AutoComplete from "../../../components/Form/AutoComplete";
import { useParams } from "react-router-dom";

export default function PosiblesClientes() {
  const { id } = useParams();
  const [columns, setcolumns] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    dealers.dealersIdGet(id).then((response) => {
      setdata(response.distri)
    })
    return () => {
    }
  }, [])


  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box
            px="20"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap"}
          >
            <TextComponent
              color={"#000"}
              fontSize={"3xl"}
              fontWeight={"bold"}
              textAlign={"left"}
              text={data?.nombre_comercial}
            />
          </Box>
          <Box overflowX="auto" mt={4}>
            {loader && <SkeletonComponent />}
          </Box>

          <Box px={20} justifyContent={"space-between"} display={"flex"}>
            <Box display={"flex"} gap={5} flexDirection={"column"}>
              <TextComponent
                color={"#000"}
                fontSize={"lg"}
                fontWeight={"normal"}
                textAlign={"left"}
                text={`Telefono: (${data?.lada}) ${data?.telefono}`}
              />
              <TextComponent
                color={"#000"}
                fontSize={"lg"}
                fontWeight={"normal"}
                textAlign={"left"}
                text={`Correo: ${data?.correo_electronico}`}
              />
              <TextComponent
                color={"#000"}
                fontSize={"lg"}
                fontWeight={"normal"}
                textAlign={"left"}
                text={`Centro de Servicio: ${data?.centro_de_servicio}`}
              />
              <TextComponent
                color={"#000"}
                fontSize={"xl"}
                fontWeight={"bold"}
                textAlign={"left"}
                text={`Horario`}
              />
              <TextComponent
                color={"#000"}
                fontSize={"lg"}
                fontWeight={"normal"}
                textAlign={"left"}
                text={`Telefono: (${data?.lada}) ${data?.telefono}`}
              />
            </Box>
            <Box>

            </Box>
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
}
