/* eslint-disable react-hooks/rules-of-hooks */
// add filter table
import * as React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Text, Box } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useColorModeValue } from "@chakra-ui/react";
import ButtonComponents from "../Form/Button";
import Filter from "./Filter";
import SelectForm from "../Form/SelectForm";
import TextComponent from "../Form/Text";
import FormControlDefaultComponent from "../Form/FormControlDefault";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  edit: any;
  onOpenUpdate: any;
  setArray: any;
  activarFuentes: any;
  size?: string;
};

export function DataTable<Data extends object>({
  data,
  columns,
  edit,
  onOpenUpdate,
  setArray,
  activarFuentes,
  size = "",
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <>
      <Table variant="striped" colorScheme="gray" size="md">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header: any) => {
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    isNumeric={meta?.isNumeric}
                    color={useColorModeValue("black", "black")}
                  >
                    <div
                      role={"button"}
                      {...{
                        className: header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      <Text fontSize={14} fontWeight={600}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Text>
                      {{
                        asc: (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ),
                        desc: <TriangleUpIcon aria-label="sorted ascending" />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>

                    {header.column.getCanFilter() ? (
                      <div>
                        <Filter column={header.column} table={table} />
                      </div>
                    ) : null}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} onClick={() => activarFuentes(row)} role="button">
              {row.getVisibleCells().map((cell) => {
                const meta: any = cell.column.columnDef.meta;
                return (
                  <Td
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    color={useColorModeValue("black", "black")}
                    fontSize={size === "" ? "20px" : "14px"}
                    padding={size === "" ? "15px" : "5px"}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
              {/** add button */}
              {edit ? (
                <Td>
                  <ButtonComponents
                    color={useColorModeValue("#2f5941", "#711c3d")}
                    size="sm"
                    text="Editar"
                    onClick={() => {
                      setArray(row.original);
                      onOpenUpdate();
                    }}
                  />
                </Td>
              ) : null}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box
        display={"flex"}
        justifyContent="end"
        alignItems={"center"}
        gap={5}
        mt={5}
      >
        {table.getCanPreviousPage() && (
          <ButtonComponents
            color={useColorModeValue("#2f5941", "#711c3d")}
            onClick={() => table.setPageIndex(0)}
            size="sm"
            text="<<"
          />
        )}
        <ButtonComponents
          color={useColorModeValue("#2f5941", "#711c3d")}
          onClick={() => table.previousPage()}
          size="sm"
          text="<"
        />
        <ButtonComponents
          color={useColorModeValue("#2f5941", "#711c3d")}
          onClick={() => table.nextPage()}
          size="sm"
          text=">"
        />
        <ButtonComponents
          color={useColorModeValue("#2f5941", "#711c3d")}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          size="sm"
          text=">>"
        />
        <TextComponent
          text={`Pagina ${
            table.getState().pagination.pageIndex + 1
          } de ${table.getPageCount()}`}
          color="#000"
          fontSize={size === "" ? "25px" : "15px"}
          fontWeight="600"
          textAlign={"center"}
        />

        <Box mt={5}>
          <FormControlDefaultComponent
            label=""
            helperText="Ir a la pagina"
            type="text"
            placeholder="Ir a la pagina"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </Box>

        <Box>
          <SelectForm
            helperText=""
            placeholder="Seleccione una opcion"
            label=""
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </SelectForm>
        </Box>
      </Box>
    </>
  );
}
