/* eslint-disable import/no-anonymous-default-export */
const ENDPOINT = process.env.REACT_APP_URL;

async function dealers() {
  return await fetch(`${ENDPOINT}dealersGet`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function dealersSearch(data: any) {
  return await fetch(`${ENDPOINT}dealersSearch`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function dealersIdGet(id: any) {
  return await fetch(`${ENDPOINT}dealersIdGet/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function estadosGet() {
  return await fetch(`${ENDPOINT}estadosGet`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function fuentesGet() {
  return await fetch(`${ENDPOINT}FuentesGet`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}


async function mediosGet() {
  return await fetch(`${ENDPOINT}MediosGet`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function dealersEstadoGet(id: any) {
  return await fetch(`${ENDPOINT}dealersEstadoGet/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}


async function dealersUpdate(data: any) {
  return await fetch(`${ENDPOINT}dealersUpdate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}


export default {
  dealersSearch,
  dealersIdGet,
  dealers,
  estadosGet,
  dealersEstadoGet,
  dealersUpdate,
  fuentesGet,
  mediosGet
};
