/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-operators */
import {
  useColorModeValue,
  Flex,
  Box,
  BoxProps,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Icon,
} from "@chakra-ui/react";
import NavItem from "./NavItem";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiUsers,
} from "react-icons/fi";
import { IconType } from "react-icons/lib";
import { useSelector } from "react-redux";
import { getValues } from "../../reducers/loginReducer";
import Image_CRM from "../../images/contacto_2019.png";
import { useLocation } from "react-router-dom";

interface LinkItemProps {
  name: string;
  icon: IconType;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Inicio", icon: FiHome },
  { name: "Geolocalización", icon: FiSettings },
  { name: "Distribuidores", icon: FiTrendingUp },
  { name: "Tipo de clientes", icon: FiUsers },
  { name: "Ordenes de compra", icon: FiUsers },
  { name: "Posibles clientes", icon: FiUsers },
  { name: "Clientes", icon: FiUsers },
  //{ name: "Posibles clientes", icon: FiHome },
  { name: "Usuarios", icon: FiUsers },
  { name: "Crear ticket de seguimiento", icon: FiSettings },
  { name: "Tickets de seguimiento", icon: FiSettings },
];

const reportesArray: Array<LinkItemProps> = [
  { name: "Ventas", icon: FiCompass },
  { name: "Tráfico", icon: FiStar },
  { name: "Comparativo", icon: FiSettings },
  { name: "Venta distribuidor", icon: FiSettings },
  //{ name: "Fuentes", icon: FiSettings },
  { name: "Categorías", icon: FiSettings },
  { name: "Categorías por producto", icon: FiSettings },
  //{ name: "Top ventas", icon: FiSettings },
];

const LinkItemsServicio: Array<LinkItemProps> = [
  { name: "Tickets de seguimiento", icon: FiCompass },
];

const LinkItemsCall: Array<LinkItemProps> = [
  { name: "Distribuidores", icon: FiTrendingUp },
];

const reportesVArray: Array<LinkItemProps> = [
  { name: "Ventas", icon: FiCompass },
  { name: "Venta distribuidor", icon: FiSettings },
  { name: "Top ventas", icon: FiSettings },
];

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

export default function SidebarContent({ onClose, ...rest }: SidebarProps) {
  const { pathname } = useLocation();
  const { rol, email} = useSelector(getValues);
  return (
    <Box
      bg={useColorModeValue("#eeeeee", "#eeeeee")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 40 }}
      pos="fixed"
      overflow={"auto"}
      h="full"
      style={{
        height: "100vh",
        overflow: "scroll",
      }}
      {...rest}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        marginTop={5}
        marginBottom={3}
      >
        <Image height={50} src={Image_CRM} roundedTop="lg" />
      </Flex>
      {rol === "Servicio Tecnico" &&
        LinkItemsServicio.map((link) => (
          <NavItem key={link.name} icon={link.icon}>
            {link.name}
          </NavItem>
        ))}
      {rol === "Ventas" &&
        LinkItemsCall.map((link, index) => {
          if (index === 0) {
            return (
              <>
                <Accordion allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        boxShadow="md"
                        paddingInline="5"
                        paddingBlock="5"
                        borderRadius="lg"
                        cursor="pointer"
                        bg={"transparent"}
                        color={"black"}
                        _hover={{
                          bg: "#f06e26",
                          color: "white",
                        }}
                      >
                        {link.icon && (
                          <Icon
                            mr="4"
                            fontSize="14"
                            _groupHover={{
                              color: "white",
                            }}
                            as={link.icon}
                          />
                        )}

                        <Box as="span" flex="1" textAlign="left">
                          Reportes
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel paddingInline={0}>
                      {reportesVArray.map((link) => (
                        <NavItem key={link.name} icon={link.icon}>
                          {link.name}
                        </NavItem>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>

                <NavItem key={link.name} icon={link.icon}>
                  {link.name}
                </NavItem>
              </>
            );
          }
          return (
            <NavItem key={link.name} icon={link.icon}>
              {link.name}
            </NavItem>
          );
        })}
      {(rol === "Master" || rol === "Contacto Stihl") &&
        LinkItems.map((link, index) => {
          if (index === 1) {
            return (
              <>
                <Accordion allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        boxShadow="md"
                        paddingInline="5"
                        paddingBlock="5"
                        borderRadius="lg"
                        cursor="pointer"
                        bg={"transparent"}
                        color={"black"}
                        _hover={{
                          bg: "#f06e26",
                          color: "white",
                        }}
                        fontWeight={"bolder"}
                        fontSize={"xs"}
                      >
                        {link.icon && (
                          <Icon
                            mr="4"
                            fontSize="14"
                            _groupHover={{
                              color: "white",
                            }}
                            as={link.icon}
                          />
                        )}

                        <Box as="span" flex="1" textAlign="left">
                          Reportes
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel paddingInline={0}>
                      {reportesArray.map((link) => (
                        <NavItem key={link.name} icon={link.icon}>
                          {link.name}
                        </NavItem>
                      ))}
                      {
                        email === "programacion2@alberi.com.mx" && (
                          <NavItem key="Top ventas" icon={FiSettings}>
                            Top ventas
                          </NavItem>
                        )
                      }
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>

                <NavItem key={link.name} icon={link.icon}>
                  {link.name}
                </NavItem>
              </>
            );
          }
          return (
            <NavItem key={link.name} icon={link.icon}>
              {link.name}
            </NavItem>
          );
        })}

      {/*LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon}>
          {link.name}
        </NavItem>
      ))*/}
    </Box>
  );
}
