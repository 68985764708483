/* eslint-disable react-hooks/rules-of-hooks */
// add filter table
import * as React from "react";
import {
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  TableContainer,
} from "@chakra-ui/react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useColorModeValue } from "@chakra-ui/react";
import ButtonComponents from "../Form/Button";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  edit: any;
  onOpenUpdate: any;
  setArray: any;
  total?: boolean;
  ExportExcel?: any;
  size?: string;
  getProductosZona?: any;
};

export function DataTable<Data extends object>({
  data,
  columns,
  edit,
  onOpenUpdate,
  setArray,
  total = false,
  ExportExcel = () => {},
  size = "",
  getProductosZona = () => {},
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <>
      <TableContainer>
        <Table variant="striped" colorScheme="gray" size="md">
          {size === "" ? (
            <Tbody>
              <Tr></Tr>
              <Tr>
                <Td
                  colSpan={edit ? columns.length + 1 : columns.length}
                  textAlign={"end"}
                  style={{ backgroundColor: "#fff !important" }}
                  className="bg-white"
                >
                  <ButtonComponents
                    text="Reporte general"
                    color="#3aa817"
                    onClick={() => ExportExcel(data)}
                    size="sm"
                  />
                </Td>
              </Tr>
            </Tbody>
          ) : null}
          <Tbody>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => {
                  return (
                    <Th
                      key={header.id}
                      color={useColorModeValue("black", "black")}
                    >
                      <div
                      >
                        <Text
                          fontSize={12}
                          fontWeight={600}
                          textAlign={"center"}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Text>
                      </div>
                    </Th>
                  );
                })}
              </Tr>
            ))}

            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}
                onClick={() => {
                  getProductosZona(row.original);
                }}
                role="button"
              >
                {row.getVisibleCells().map((cell) => {
                  const meta: any = cell.column.columnDef.meta;
                  return (
                    <Td
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      isTruncated={true}
                      color={useColorModeValue("black", "black")}
                      fontSize={size === "" ? "14px" : "12px"}
                      padding={size === "" ? "4px" : "5px"}
                      textAlign={size === "" ? "center" : "center"}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
                {/** add button */}
                {edit ? (
                  <Td textAlign={"center"}>
                    <ButtonComponents
                      color={useColorModeValue("#2f5941", "#711c3d")}
                      size="xs"
                      text="Editar"
                      onClick={() => {
                        setArray(row.original);
                        onOpenUpdate();
                      }}
                    />
                  </Td>
                ) : null}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
