/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import ticket from "../../../services/ticket";
import { Input, Select, Button, useToast, Textarea } from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import { useSelector } from "react-redux";
import { getValues } from "../../../reducers/loginReducer";
import geomark from "../../../services/geomark";
import SelectComponentsName from "../../../components/Form/SelectName";
import dealers from "../../../services/dealers";

export default function Ticket() {
  const toast = useToast();
  const { id } = useSelector(getValues);
  const [loading, setloading] = useState(false);
  const [form, setform] = useState<any>({
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    cp: "",
    estado: "",
    municipio: "",
    fuente: "",
    medio: "",
    prioridad: "",
    pregunta: "",
    observaciones: "",
    tipo_ticket: "",
    otro_tipo_ticket: "",
    tipo_reporte: "",
    zone: "",

    equipo: "",
    fecha_compra: "",
    pieza: "",
    num_serie: "",
    distribuidor: "",
    foto_video: "",

    equipo_value: "",
    distribuidor_value: "",
  });

  const [estadosArray, setestadosArray] = useState([]);
  const [municipiosArray, setmunicipiosArray] = useState([]);
  const [productosArray, setproductosArray] = useState([]);
  const [dealersArray, setdealersArray] = useState([]);

  useEffect(() => {
    geomark.geomark().then((res) => {
      const newArray = res.productos.map((res:any) => {
        return{
          ...res,
          value: res.label
        }
      })
      setproductosArray(newArray);
    });

    dealers.dealers().then((res) => {
      const newArray = res.distribuidor.map((res: any) => {
        return {
          ...res,
          value: res.nombre_comercial + " - " + res.numero,
          label: res.nombre_comercial + " - " + res.numero,
        };
      });
      setdealersArray(newArray);
    });
  }, []);

  const cleanForm = () => {
    setform({
      nombre: "",
      apellido: "",
      correo: "",
      telefono: "",
      cp: "",
      estado: "",
      municipio: "",
      fuente: "",
      medio: "",
      prioridad: "",
      pregunta: "",
      observaciones: "",
      tipo_ticket: "",
      otro_tipo_ticket: "",
      tipo_reporte: "",
      zone: "",
      equipo: "",
      fecha_compra: "",
      pieza: "",
      num_serie: "",
      distribuidor: "",
      foto_video: "",
      equipo_value: "",
      distribuidor_value: "",
    });
  };

  const createTicket = () => {
    setloading(true);
    if (
      form.nombre === "" ||
      form.apellido === "" ||
      form.correo === "" ||
      form.telefono === "" ||
      form.cp === "" ||
      form.estado === "" ||
      form.fuente === "" ||
      form.medio === "" ||
      form.prioridad === "" ||
      form.observaciones === ""
    ) {
      setloading(false);
      toast({
        title: "Error",
        description: "Todos los campos son obligatorios",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (form.foto_video === "") {
      form.tipo_reporte = form.tipo_ticket;
      form.user_id = id;
      form.tipo_reporte =
        form.tipo_ticket === "Otro" ? form.otro_tipo_ticket : form.tipo_ticket;

      form.equipo = form.equipo_value
      form.distribuidor = form.distribuidor_value

      ticket
        .ClienteAdd(form)
        .then((res) => {
          if (res.error) {
            setloading(false);
            toast({
              title: "Error",
              description: res.message,
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            setloading(false);
            toast({
              title: "Ticket creado",
              description: "El ticket se ha creado correctamente",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
            cleanForm();
          }
        })
        .catch((err) => {
          setloading(false);
          toast({
            title: "Error",
            description: err.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        });
    } else {
      const formData = new FormData();
      formData.append("user_id", id);
      formData.append("nombre", form.nombre);
      formData.append("apellido", form.apellido);
      formData.append("correo", form.correo);
      formData.append("telefono", form.telefono);
      formData.append("cp", form.cp);
      formData.append("estado", form.estado);
      formData.append("municipio", form.municipio);
      formData.append("fuente", form.fuente);
      formData.append("medio", form.medio);
      formData.append("prioridad", form.prioridad);
      formData.append("pregunta", form.pregunta);
      formData.append("observaciones", form.observaciones);
      formData.append(
        "tipo_ticket",
        form.tipo_ticket === "Otro" ? form.otro_tipo_ticket : form.tipo_ticket
      );
      formData.append("otro_tipo_ticket", form.otro_tipo_ticket);
      formData.append(
        "tipo_reporte",
        form.tipo_ticket === "Otro" ? form.otro_tipo_ticket : form.tipo_ticket
      );
      formData.append("zone", form.zone);
      formData.append("equipo", form.equipo_value);
      formData.append("fecha_compra", form.fecha_compra);
      formData.append("pieza", form.pieza);
      formData.append("num_serie", form.num_serie);
      formData.append("distribuidor", form.distribuidor_value);
      // foto_video es un array
      if (form?.foto_video?.name) {
        formData.append("foto_video", form.foto_video);
      } else {
        formData.append("varias_imagenes", form.foto_video.length);
        if (form.foto_video.length === 1) {
          formData.append("foto_video", form.foto_video[0]);
        } else {
          for (let i = 0; i < form.foto_video.length; i++) {
            formData.append("foto_video_" + i, form.foto_video[i]);
          }
        }
      }

      ticket
        .ClienteAddFile(formData)
        .then((res) => {
          if (res.error) {
            setloading(false);
            toast({
              title: "Error",
              description: res.message,
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            setloading(false);
            toast({
              title: "Ticket creado",
              description: "El ticket se ha creado correctamente",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
            cleanForm();
          }
        })
        .catch((err) => {
          setloading(false);
          toast({
            title: "Error",
            description: err.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        });
    }
  };

  const getInformacion = () => {
    ticket.estadosGet().then((res) => {
      setestadosArray(res.estados);
    });
  };

  const getMunicipios = () => {
    const estado: any = estadosArray.find(
      (res: any) => res.label === form.estado
    );
    if (!estado) return setmunicipiosArray([]);

    ticket.municipiosGet(estado?.value).then((res) => {
      setmunicipiosArray(res.municipios);
    });

    ticket.zonasGet(estado?.zonas_id).then((res) => {
      setform({ ...form, zone: res.zonas[0].label });
    });
  };

  useEffect(() => {
    getMunicipios();
  }, [form.estado]);

  useEffect(() => {
    getInformacion();
  }, []);

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiFile size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Generar ticket"
                textTransform={"uppercase"}
              />
            </Flex>
            <Box>
              <Image
                src={"/stihl-logo.svg"}
                height={50}
              />
            </Box>
          </Box>

          <Box
            bg={"white"}
            borderRadius={20}
            gap={2}
            display="flex"
            flexDirection="row"
            marginTop={3}
          >
            <Select
              placeholder="Seleccione un tipo de ticket"
              value={form.tipo_ticket}
              onChange={(e) =>
                setform({ ...form, tipo_ticket: e.target.value })
              }
            >
              {[
                "Centro de servicio autorizado más cercano",
                "Ser distribuidor",
                "Refacciones",
                "Cotización",
                "Reporte (Proceso de garantia)",
                "Marketing",
                "Dudas tecnicas",
                "Otro",
              ].map((rol) => (
                <option key={rol} value={rol}>
                  {rol}
                </option>
              ))}
            </Select>
          </Box>

          {form.tipo_ticket === "Otro" && (
            <Box
              bg={"white"}
              borderRadius={20}
              gap={2}
              display="flex"
              flexDirection="row"
              marginTop={3}
            >
              <Input
                type="text"
                placeholder="Otro tipo de ticket"
                value={form.otro_tipo_ticket}
                onChange={(e) =>
                  setform({ ...form, otro_tipo_ticket: e.target.value })
                }
              />
            </Box>
          )}

          {form.tipo_ticket !== "" && (
            <>
              <Flex gap={4}>
                <Box
                  bg={"white"}
                  borderRadius={20}
                  gap={2}
                  display="flex"
                  marginTop={5}
                  flexDirection={"column"}
                  width={
                    form.tipo_ticket === "Distribuidor" ||
                    form.tipo_ticket === "Refacciones" ||
                    form.tipo_ticket === "Cotización" ||
                    form.tipo_ticket === "Reporte (Proceso de garantia)" ||
                    form.tipo_ticket === "Dudas tecnicas"
                      ? "50%"
                      : "100%"
                  }
                >
                  <Box borderBottom={"1px solid #f06e26"}>
                    <TextComponent
                      color={"#f06e26"}
                      fontSize={"xl"}
                      fontWeight={"bolder"}
                      textAlign={"center"}
                      text="Datos del reporte"
                      textTransform={"uppercase"}
                    />
                  </Box>
                  <Box>
                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Input
                        type="text"
                        placeholder="Nombre"
                        value={form.nombre}
                        onChange={(e) =>
                          setform({ ...form, nombre: e.target.value })
                        }
                      />
                      <Input
                        type="text"
                        placeholder="Apellidos"
                        value={form.apellido}
                        onChange={(e) =>
                          setform({ ...form, apellido: e.target.value })
                        }
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Input
                        type="email"
                        placeholder="Correo electronico"
                        value={form.correo}
                        onChange={(e) =>
                          setform({ ...form, correo: e.target.value })
                        }
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Input
                        type="number"
                        placeholder="Telefono"
                        value={form.telefono}
                        onChange={(e) =>
                          setform({ ...form, telefono: e.target.value })
                        }
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Input
                        type="number"
                        placeholder="Codigo postal"
                        value={form.cp}
                        onChange={(e) =>
                          setform({ ...form, cp: e.target.value })
                        }
                      />
                      <Select
                        placeholder="Seleccione un estado"
                        value={form.estado}
                        onChange={(e) =>
                          setform({ ...form, estado: e.target.value })
                        }
                      >
                        {estadosArray.map((rol: any) => (
                          <option key={rol.label} value={rol.label}>
                            {rol.label}
                          </option>
                        ))}
                      </Select>

                      <Select
                        placeholder="Seleccione un municipio"
                        value={form.municipio}
                        onChange={(e) =>
                          setform({ ...form, municipio: e.target.value })
                        }
                      >
                        {municipiosArray.map((rol: any) => (
                          <option key={rol.label} value={rol.label}>
                            {rol.label}
                          </option>
                        ))}
                      </Select>
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Select
                        placeholder="Seleccione una opcion de fuente"
                        value={form.fuente}
                        onChange={(e) =>
                          setform({ ...form, fuente: e.target.value })
                        }
                      >
                        {[
                          "Tienda en línea",
                          "Investigación web",
                          "Google AdWords",
                          "Instagram",
                          "Directo",
                          "Facebook",
                          "Display",
                          "YouTube",
                          "Ninguno",
                          "Waze",
                          "Twitter",
                          "Barda pintada",
                          "Espectacular",
                        ].map((rol) => (
                          <option key={rol} value={rol}>
                            {rol}
                          </option>
                        ))}
                      </Select>
                      <Select
                        placeholder="Seleccione una opcion de medio"
                        value={form.medio}
                        onChange={(e) =>
                          setform({ ...form, medio: e.target.value })
                        }
                      >
                        {[
                          "Chat",
                          "Correo electronico",
                          "Formulario",
                          "Llamada",
                          "Messenger",
                          "ML",
                          "Ninguno",
                          "SMS",
                          "Whatsapp",
                        ].map((rol) => (
                          <option key={rol} value={rol}>
                            {rol}
                          </option>
                        ))}
                      </Select>
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Input
                        placeholder="Pregunta cliente"
                        value={form.pregunta}
                        onChange={(e) =>
                          setform({ ...form, pregunta: e.target.value })
                        }
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Textarea
                        placeholder="Observaciones"
                        value={form.observaciones}
                        onChange={(e) =>
                          setform({ ...form, observaciones: e.target.value })
                        }
                      />
                    </Box>

                    {form.tipo_ticket === "Marketing" && (
                      <Box
                        bg={"white"}
                        borderRadius={20}
                        gap={2}
                        display="flex"
                        flexDirection="row"
                        marginTop={3}
                        marginBottom={3}
                      >
                        <TextComponent
                          color={"black"}
                          fontSize={"sm"}
                          fontWeight={"medium"}
                          textAlign={"center"}
                          text="Foto"
                        />
                        <Input
                          type="file"
                          placeholder="Foto"
                          accept="image/*"
                          multiple
                          onChange={(e: any) => {
                            setform({ ...form, foto_video: e.target.files });
                          }}
                        />
                      </Box>
                    )}

                    {(form.tipo_ticket ===
                      "Centro de servicio autorizado más cercano" ||
                      form.tipo_ticket === "Ser distribuidor" ||
                      form.tipo_ticket === "Marketing" ||
                      form.tipo_ticket === "Otro") && (
                      <Box
                        bg={"white"}
                        borderRadius={20}
                        gap={5}
                        display="flex"
                        flexDirection="row"
                        marginTop={3}
                        alignItems={"center"}
                      >
                        <Select
                          placeholder="Seleccione una complejidad"
                          value={form.prioridad}
                          onChange={(e) =>
                            setform({ ...form, prioridad: e.target.value })
                          }
                          width={300}
                        >
                          {["Critico", "Urgente", "Normal"].map((rol) => (
                            <option key={rol} value={rol}>
                              {rol}
                            </option>
                          ))}
                        </Select>
                      </Box>
                    )}
                  </Box>
                </Box>

                {form.tipo_ticket === "Cotización" && (
                  <Box
                    bg={"white"}
                    borderRadius={20}
                    gap={2}
                    display="flex"
                    marginTop={5}
                    flexDirection={"column"}
                    width={"50%"}
                  >
                    <Box borderBottom={"1px solid #f06e26"}>
                      <TextComponent
                        color={"#f06e26"}
                        fontSize={"xl"}
                        fontWeight={"bolder"}
                        textAlign={"center"}
                        text="Datos de compra"
                        textTransform={"uppercase"}
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <SelectComponentsName
                        options={productosArray}
                        label="Equipo"
                        placeholder="Seleccione un equipo"
                        value={form.equipo}
                        onChange={(e) => {
                          setform({
                            ...form,
                            equipo_value: e.value,
                            equipo: e,
                          });
                        }}
                      />
                      <SelectComponentsName
                        options={dealersArray}
                        label="Distribuidor"
                        placeholder="Seleccione un distribuidor"
                        value={form.distribuidor}
                        onChange={(e) => {
                          setform({
                            ...form,
                            distribuidor_value: e.value,
                            distribuidor: e,
                          });
                        }}
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                      marginBottom={3}
                    >
                      <TextComponent
                        color={"black"}
                        fontSize={"sm"}
                        fontWeight={"medium"}
                        textAlign={"center"}
                        text="Cotización"
                      />
                      <Input
                        type="file"
                        placeholder="Cotización"
                        accept="application/pdf"
                        onChange={(e: any) => {
                          setform({ ...form, foto_video: e.target.files[0] });
                        }}
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={5}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                      alignItems={"center"}
                    >
                      <Select
                        placeholder="Seleccione una complejidad"
                        value={form.prioridad}
                        onChange={(e) =>
                          setform({ ...form, prioridad: e.target.value })
                        }
                        width={300}
                      >
                        {["Critico", "Urgente", "Normal"].map((rol) => (
                          <option key={rol} value={rol}>
                            {rol}
                          </option>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                )}

                {(form.tipo_ticket === "Distribuidor" ||
                  form.tipo_ticket === "Refacciones" ||
                  form.tipo_ticket === "Reporte (Proceso de garantia)" ||
                  form.tipo_ticket === "Dudas tecnicas") && (
                  <Box
                    bg={"white"}
                    borderRadius={20}
                    gap={2}
                    display="flex"
                    marginTop={5}
                    flexDirection={"column"}
                    width={"50%"}
                  >
                    <Box borderBottom={"1px solid #f06e26"}>
                      <TextComponent
                        color={"#f06e26"}
                        fontSize={"xl"}
                        fontWeight={"bolder"}
                        textAlign={"center"}
                        text="Datos de compra"
                        textTransform={"uppercase"}
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <SelectComponentsName
                        options={productosArray}
                        label="Equipo"
                        placeholder="Seleccione un equipo"
                        value={form.equipo}
                        onChange={(e) => {
                          setform({
                            ...form,
                            equipo_value: e.value,
                            equipo: e,
                          });
                        }}
                      />
                      <Input
                        type="date"
                        placeholder="Fecha de compra"
                        value={form.fecha_compra}
                        onChange={(e) =>
                          setform({ ...form, fecha_compra: e.target.value })
                        }
                      />
                      <Input
                        type="text"
                        placeholder="Pieza"
                        value={form.pieza}
                        onChange={(e) =>
                          setform({ ...form, pieza: e.target.value })
                        }
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Input
                        type="text"
                        placeholder="Num. Serie"
                        value={form.num_serie}
                        onChange={(e) =>
                          setform({ ...form, num_serie: e.target.value })
                        }
                      />
                      <SelectComponentsName
                        options={dealersArray}
                        label="Distribuidor"
                        placeholder="Seleccione un distribuidor"
                        value={form.distribuidor}
                        onChange={(e) => {
                          setform({
                            ...form,
                            distribuidor_value: e.value,
                            distribuidor: e,
                          });
                        }}
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <TextComponent
                        color={"black"}
                        fontSize={"sm"}
                        fontWeight={"medium"}
                        textAlign={"center"}
                        text="Foto"
                      />
                      <Input
                        type="file"
                        placeholder="Foto"
                        accept="image/*"
                        multiple
                        onChange={(e: any) => {
                          setform({ ...form, foto_video: e.target.files });
                        }}
                      />
                    </Box>

                    <Box
                      borderBottom={"1px solid #f06e26"}
                      borderTop={"1px solid #f06e26"}
                      mt={3}
                    >
                      <TextComponent
                        color={"#f06e26"}
                        fontSize={"xl"}
                        fontWeight={"bolder"}
                        textAlign={"center"}
                        text="Acciones de seguimiento"
                        textTransform={"uppercase"}
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={5}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                      alignItems={"center"}
                    >
                      <Select
                        placeholder="Seleccione una complejidad"
                        value={form.prioridad}
                        onChange={(e) =>
                          setform({ ...form, prioridad: e.target.value })
                        }
                        width={300}
                      >
                        {["Critico", "Urgente", "Normal"].map((rol) => (
                          <option key={rol} value={rol}>
                            {rol}
                          </option>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                )}
              </Flex>

              <Box display={"flex"} justifyContent={"flex-end"} mt={5} gap={2}>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    cleanForm();
                  }}
                  size="md"
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  size="md"
                  isLoading={loading}
                  onClick={() => {
                    createTicket();
                  }}
                >
                  Generar ticket
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Sidebar>
    </div>
  );
}
