/* eslint-disable import/no-anonymous-default-export */
const ENDPOINT = process.env.REACT_APP_URL;

async function Top10Get(data: any) {
  return await fetch(`${ENDPOINT}Top10Get`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function ventasZonasGet() {
  return await fetch(`${ENDPOINT}ventasZonasGet`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

export default {
  Top10Get,
  ventasZonasGet
};
