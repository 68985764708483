import Autocomplete from "react-google-autocomplete";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  useColorModeValue,
} from "@chakra-ui/react";

interface AutoCompleteInterface {
  label: string;
  helperText: string;
  onChangePlace: (place: any) => void;
}

const AutoComplete = ({
  label,
  helperText,
  onChangePlace,
}: AutoCompleteInterface) => {
  return (
    <FormControl mt={2}>
      <FormLabel color={useColorModeValue("#000", "#000")}>{label}</FormLabel>
      <Autocomplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        onPlaceSelected={onChangePlace}
        className="chakra-input w-36 border shadow rounded css-1kp110w"
        placeholder="Busqueda de ubicación"
        language="es"
        options={{
          types: ["(regions)"],
          componentRestrictions: { country: "mx" },
        }}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default AutoComplete;
