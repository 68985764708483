import { Card, CardBody, Text } from "@chakra-ui/react";

interface CardInterface {
  text: string;
}

export default function CardComponent({ text }: CardInterface) {
  return (
    <Card width={"100%"} bg={"gray.200"}>
      <CardBody>
        <Text fontWeight={"800"} textAlign={"center"}>{text}</Text>
      </CardBody>
    </Card>
  );
}
