import { Box, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import ButtonComponents from "../../../components/Form/Button";
import TextComponent from "../../../components/Form/Text";
import FormControlComponent from "../../../components/Form/FormControl";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import master from "../../../services/master";
import { DataTable } from "../../../components/HOC's/DataTable";
import SkeletonComponent from "../../../components/Form/Skeleton";
import { createColumnHelper } from "@tanstack/react-table";
import { Columns } from "../../../utils/Master";

export default function PosiblesClientes() {
  const toast = useToast();
  const [form, setform] = useState({
    desde: "",
    hasta: "",
  });
  const [columns, setcolumns] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [loader, setloader] = useState(false);

  const buscarReporte = () => {
    if (!form.desde) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de inicio",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    if (!form.hasta) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de finalización",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    setloader(true);
    master
      .masterGet({
        desde: form.desde,
        hasta: form.hasta,
      })
      .then((response: any) => {
        console.log(response);
        setloader(false);
        //createArrayListadoProductos(response);
      });
  };

  const createArrayListadoProductos = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = Columns().map(({ title, campo }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
      });
    });
    setcolumns(columns);
    const data = response.listado_productos;
    setdata(data);
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box px="20" display={"flex"} justifyContent={"space-between"}>
            <TextComponent
              color={"#000"}
              fontSize={"2xl"}
              fontWeight={"bold"}
              textAlign={"left"}
              text="Reporte master"
            />
          </Box>
          <Box
            px="20"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={10}
          >
            <FormControlComponent
              helperText=""
              placeholder=""
              label="Desde"
              type="date"
              value={form.desde}
              onChange={(e) => setform({ ...form, desde: e.target.value })}
            />
            <FormControlComponent
              helperText=""
              placeholder=""
              label="Hasta"
              type="date"
              value={form.hasta}
              onChange={(e) => setform({ ...form, hasta: e.target.value })}
            />
            <Box mt={5}>
              <ButtonComponents
                text="Buscar"
                color="#FF7B05"
                onClick={buscarReporte}
                size="lg"
              />
            </Box>
          </Box>
          {loader && <SkeletonComponent />}
          <Box px={30} overflowX="auto" mt={4}>
            {data.length > 0 && (
              <DataTable
                columns={columns}
                data={data}
                edit=""
                onOpenUpdate={() => { }}
                setArray={[]}
              />
            )}
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
}
