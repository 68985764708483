/* eslint-disable import/no-anonymous-default-export */
const ENDPOINT = process.env.REACT_APP_URL;

async function sourcesGet(data: any) {
  return await fetch(`${ENDPOINT}sourcesGet`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function productView(desde: any, hasta: any, fuente: any, estado: any) {
  return await fetch(
    `${ENDPOINT}productView/${desde}/${hasta}/${fuente}/${estado}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
      },
    }
  ).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

export default {
  sourcesGet,
  productView,
};
