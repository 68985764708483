import {
  useColorModeValue,
  Text,
  Flex,
  Box,
  BoxProps,
  CloseButton,
} from "@chakra-ui/react";
import NavItem from "./NavItem";
import { IconType } from "react-icons/lib";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getValues } from "../../reducers/loginReducer";

interface LinkItemProps {
  name: string;
  icon: IconType;
}
const LinkItems: Array<LinkItemProps> = [
];

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

export default function SidebarWebContent({ onClose, ...rest }: SidebarProps) {
  const { rol } = useSelector(getValues);
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      overflowY={"clip"}
      {...rest}
    >
      <Flex bg={"#FF7B05"} h="10" alignItems="center" justifyContent="center">
        <Text
          color={"white"}
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold"
        >
          Edición CRM
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {rol === "Administrador" &&
        LinkItems.map((link) => (
          <NavItem key={link.name} icon={link.icon}>
            {link.name}
          </NavItem>
        ))}
      {/*LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon}>
          {link.name}
        </NavItem>
      ))*/}
    </Box>
  );
}
