export const options = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const,
    },
    title: {
      display: true,
      text: "Ventas en pesos",
    },
  },
};

export function generateData(array: any) {
  const labels = array.map((item: any) => item.fuente);
  return {
    labels,
    datasets: [
      {
        label: "Ventas en pesos",
        data: array.map((item: any) => item.resultado),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
}

export function Columns() {
  return [
    { title: "Categoria", campo: "categoria", buscador: false },
    { title: "Cantidad", campo: "cantidad", buscador: false },
    { title: "Total", campo: "monto", buscador: false },
  ];
}

export function ColumnsProducts() {
  return [
    { title: "Fecha venta", campo: "fecha_venta" },
    { title: "Modelo", campo: "modelo" },
    { title: "Nombre", campo: "categoria_nombre" },
    { title: "Fuente", campo: "fuente" },
    { title: "Medio", campo: "medio" },
    { title: "Zona", campo: "zonas_id" },
  ];
}



export function ColumnsEliminados() {
  return [
    { title: "Modelo", campo: "modelo" },
    { title: "Categoria", campo: "categoria" },
    { title: "Cantidad", campo: "cantidad" },
    { title: "Total", campo: "monto" },
  ];
}
