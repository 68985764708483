export const options = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const,
    },
    title: {
      display: true,
      text: "Ventas en pesos",
    },
  },
};

export function generateData(array: any) {
  const labels = array.map((item: any) => item.fuente);
  return {
    labels,
    datasets: [
      {
        label: "Ventas en pesos",
        data: array.map((item: any) => item.resultado),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
}

export function Columns() {
  return [
    { title: "#", campo: "_id" },
    { title: "Modelo", campo: "modelo" },
    { title: "Cantidad", campo: "cantidad" },
  ];
}


export function ColumnsZonas() {
  return [
    { title: "Zona", campo: "zonas_id" },
    { title: "Total", campo: "total" },
    { title: "Asesor", campo: "correos" },
  ];
}