import { Box, Flex, Image, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import ButtonComponents from "../../../components/Form/Button";
import TextComponent from "../../../components/Form/Text";
import FormControlComponent from "../../../components/Form/FormControl";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import breakdown from "../../../services/breakdown";
import { DataTable } from "../../../components/HOC's/DataTableVentas";
import SkeletonComponent from "../../../components/Form/Skeleton";
import { createColumnHelper } from "@tanstack/react-table";
import { Columns } from "../../../utils/Breakdown";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { FiDollarSign } from "react-icons/fi";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";

export default function PosiblesClientes() {
  const toast = useToast();
  const [form, setform] = useState({
    desde: null,
    hasta: null,
  });
  const [loader, setloader] = useState(false);
  const [columnVentas, setcolumnVentas] = useState<any>([]);
  const [dataVentas, setdataVentas] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [client, setclient] = useState<any>([]);
  const [distribuidor, setdistribuidor] = useState<any>([]);

  const buscarReporte = () => {
    if (!form.desde) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de inicio",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    if (!form.hasta) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de finalización",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    setloader(true);
    breakdown
      .salesBreakdownGet({
        desde: new Date(form.desde).toISOString().split("T")[0],
        hasta: new Date(form.hasta).toISOString().split("T")[0],
      })
      .then((response: any) => {
        createArrayListadoProductos(response);
        setloader(false);
      });
  };

  const createArrayListadoProductos = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = Columns().map(({ title, campo, buscador }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
        enableColumnFilter: buscador ?? false,
      });
    });
    setcolumnVentas(columns);

    setdata(response);
    const array = response;
    const data = array.map((item: any, index: any) => {
      return {
        ...item,
        _id: "" + (index + 1),
        folio: item.folio,
        cliente: item?.cliente[0]?.nombre + " " + item?.cliente[0]?.apellido,
        fecha: fecha(item.fecha_venta),
        total: precio(parseFloat(item.total)),
        productos:
          item?.productos.map(({ modelo }: any) => `${modelo} *`)[0] ?? "",
        distribuidor:
          item?.distribuidor.map(
            ({
              razon_social,
              nombre_comercial,
              lada,
              telefono,
              correo_electronico,
              direccion,
              ciudad,
              colonia,
              codigo_postal,
              provincia_comercial,
              exterior,
              interior,
              sap,
            }: any) =>
              `Núm. SAP: ${sap ?? ""} * Razón social: ${
                razon_social ?? ""
              } * Nombre comercial: ${nombre_comercial ?? ""} * Tel: (${
                lada ?? ""
              }) ${telefono ?? ""} * Correo: ${
                correo_electronico ?? ""
              } * Dirección: ${direccion ?? ""} , ${colonia ?? ""} , ${
                codigo_postal ?? ""
              } , ${ciudad ?? ""} , ${provincia_comercial ?? ""} , ${
                exterior ?? ""
              } , ${interior ?? ""}`
          )[0] ?? "",
        fuente: item?.cliente?.[0]?.fuente ?? "Sin fuente",
      };
    });

    data.map((item: any) => {
      setclient((client: any) => [
        ...client,
        { id: item.cliente, label: item.cliente },
      ]);
      setdistribuidor((distribuidor: any) => [
        ...distribuidor,
        { id: item.distribuidor, label: item.distribuidor },
      ]);
    });

    setdataVentas(data);
  };

  const fecha = (fecha: any) => {
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const date = new Date(fecha);
    const day = date.getDay() + 1;
    const month = meses[date.getMonth() + 1];
    const year = date.getFullYear();

    if (day <= 9) {
      return `0${day}/${month}/${year}`;
    } else {
      return `${day}/${month}/${year}`;
    }
  };

  const precio = (precio: any) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(precio);
  };

  const ExportExcel = (array: any) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const newArray = array.map((item: any) => {
      const productosSplitText = item.productos.split("*");
      const distribuidorSplitText = item.distribuidor.split("*");
      return {
        ...item,
        productos: productosSplitText.join(" "),
        distribuidor: distribuidorSplitText.join(" "),
      };
    });

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte-general" + fileExtension);
  };

  const subirFactura = (file: any, e: any) => {
    const formData = new FormData();
    formData.append("factura", file.target.files[0]);
    formData.append("id", e.id);

    breakdown.facturaAdd(formData).then((response: any) => {
      if (response.error) {
        toast({
          title: "Error",
          description: response.error,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Exito",
          description: response.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });

        buscarReporte()
      }
    });
  };
  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiDollarSign size={50} />
              <Box width={500}>
                <TextComponent
                  color={"#f06e26"}
                  fontSize={"2xl"}
                  fontWeight={"bolder"}
                  textAlign={"left"}
                  text="Reporte de desglose de ventas por distribuidor"
                  textTransform={"uppercase"}
                />
              </Box>
            </Flex>
            <Box>
              <Image src={"/stihl-logo.svg"} height={50} />
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#f3f3f3",
              paddingRight: "20px",
              paddingLeft: "20px",
              paddingBottom: "25px",
              paddingTop: "25px",
              borderRadius: "10px",
            }}
            mt={2}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              gridGap={5}
              width={"100%"}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                mt={5}
                px={10}
                borderRadius={20}
                width={"50%"}
                height={"100%"}
              >
                <DatePicker
                  isClearable={true}
                  selectsRange={true}
                  startDate={form.desde}
                  endDate={form.hasta}
                  onChange={(date: any) => {
                    setform({ ...form, desde: date[0], hasta: date[1] });
                  }}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Fecha de inicio - Fecha de fin"
                  className="form-control w-100"
                  showYearDropdown
                  locale={"es"}
                />
              </Box>

              <Box mt={5}>
                <ButtonComponents
                  text="Buscar"
                  color="#FF7B05"
                  onClick={buscarReporte}
                  size="md"
                />
              </Box>
              <Box mt={5}>
                {dataVentas.length > 0 && (
                  <ButtonComponents
                    text="Reporte general"
                    color="green"
                    onClick={() => ExportExcel(dataVentas)}
                    size="md"
                  />
                )}
              </Box>
            </Box>
          </Box>
          {loader && <SkeletonComponent />}
          <Box
            justifyContent="center"
            display={"flex"}
            flexDirection={"row"}
            mt={3}
            width={"100%"}
          >
            <Box overflowX="auto" width={"100%"}>
              {dataVentas.length > 0 && (
                <DataTable
                  columns={columnVentas}
                  data={dataVentas}
                  edit=""
                  onOpenUpdate={() => {}}
                  setArray={[]}
                  client={client}
                  distribuidor={distribuidor}
                  subirFactura={subirFactura}
                  subirfactura_bool={true}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
}
