/* eslint-disable import/no-anonymous-default-export */
const ENDPOINT = process.env.REACT_APP_URL;

async function userGet() {
  return await fetch(`${ENDPOINT}userGet`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function userAdd(data: any) {
  return await fetch(`${ENDPOINT}userAdd`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}


async function userUpdate(data: any) {
  return await fetch(`${ENDPOINT}userUpdate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function userDelete(data: any) {
  return await fetch(`${ENDPOINT}userDelete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

export default {
  userGet,
  userAdd,
  userUpdate,
  userDelete
};
