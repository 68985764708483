import { Checkbox, CheckboxGroup } from '@chakra-ui/react'

interface CheckboxInterface {
    defaultChecked: boolean;
    value: string;
    onChange: (e: any) => void;
    text: string;
}

export default function CheckboxComponents({
    defaultChecked,
    value,
    onChange,
    text
}: CheckboxInterface) {
    return <Checkbox defaultChecked={defaultChecked}
        value={value}
        onChange={onChange}
    >{text}</Checkbox>
}