import { Box, Flex, Image, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import ButtonComponents from "../../../components/Form/Button";
import TextComponent from "../../../components/Form/Text";
import FormControlComponent from "../../../components/Form/FormControl";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import top from "../../../services/top";
import { DataTable } from "../../../components/HOC's/DataTable";
import SkeletonComponent from "../../../components/Form/Skeleton";
import { createColumnHelper } from "@tanstack/react-table";
import { Columns } from "../../../utils/Top";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { FiDollarSign } from "react-icons/fi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function PosiblesClientes() {
  const toast = useToast();
  const [form, setform] = useState({
    desde: "",
    hasta: "",
  });
  const [columns, setcolumns] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [loader, setloader] = useState(false);

  const ExportExcel = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Top-venta" + fileExtension);
  };

  const buscarReporte = () => {
    if (!form.desde) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de inicio",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    if (!form.hasta) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de finalización",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    setloader(true);
    top
      .Top10Get({
        desde: form.desde,
        hasta: form.hasta,
      })
      .then((response: any) => {
        createArrayListadoProductos(response);
        setloader(false);
      });
  };

  const createArrayListadoProductos = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = Columns().map(({ title, campo }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
      });
    });
    setcolumns(columns);
    const data = response;
    const newData = data.map((res: any, index: any) => {
      return {
        _id: (index + 1).toString(),
        modelo: res.modelo,
        cantidad: res.cantidad.toString(),
      };
    });
    setdata(newData);
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiDollarSign size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Top ventas"
                textTransform={"uppercase"}
              />
            </Flex>
            <Image
              src={"/stihl-logo.svg"}
              height={50}
            />
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={10}
            mt={3}
          >
            <FormControlComponent
              helperText=""
              placeholder=""
              label="Desde"
              type="date"
              value={form.desde}
              onChange={(e) => setform({ ...form, desde: e.target.value })}
            />
            <FormControlComponent
              helperText=""
              placeholder=""
              label="Hasta"
              type="date"
              value={form.hasta}
              onChange={(e) => setform({ ...form, hasta: e.target.value })}
            />
            <Box mt={5}>
              <ButtonComponents
                text="Buscar"
                color="#FF7B05"
                onClick={buscarReporte}
                size="lg"
              />
            </Box>
          </Box>
          {loader && <SkeletonComponent />}
          <Box overflowX="auto" mt={4}>
            {data.length > 0 && (
              <DataTable
                columns={columns}
                data={data}
                edit=""
                onOpenUpdate={() => {}}
                setArray={[]}
                ExportExcel={ExportExcel}
              />
            )}
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
}
