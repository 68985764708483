import {
  Box,
  Flex,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import customers from "../../../services/customers";
import { DataTable } from "../../../components/HOC's/DataTableClientes";
import SkeletonComponent from "../../../components/Form/Skeleton";
import CustomersArray from "../../../utils/Customers";
import { createColumnHelper } from "@tanstack/react-table";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { FiUsers } from "react-icons/fi";
import leads from "../../../services/leads";
import dealers from "../../../services/dealers";
import Toast from "../../../utils/Toast";
import ButtonComponents from "../../../components/Form/Button";

export default function PosiblesClientes() {
  const [columns, setcolumns] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [estados, setestados] = useState<any>([]);
  const [edit, setedit] = useState(false);
  const [array, setArray] = useState<any>([]);

  useEffect(() => {
    let ArrayInformacion: any = [];
    getInformacion(ArrayInformacion);
    return () => {};
  }, []);

  const getInformacion = async (ArrayInformacion: any) => {
    const informacion = await leads.getLeads();
    // add new campo to array
    const newArray = informacion.map((res: any) => {
      return {
        ...res,
        tipo_cliente: "Posible cliente",
        nombre: res?.nombre ?? "No proporcionado",
        correo: res?.correo ?? "No proporcionado",
        telefono: res?.telefono ?? "No proporcionado",
        ciudad: res?.ciudad ?? "No proporcionado",
        estado: res?.estado ?? "No proporcionado",
      };
    });

    ArrayInformacion.push(newArray);

    customers.getCustomers().then((response) => {
      const columnHelper = createColumnHelper<any>();
      const columns = CustomersArray.Columns().map(
        ({ title, campo, buscador }: any) => {
          return columnHelper.accessor(campo, {
            cell: (info: any) => info.getValue(),
            header: title ?? "",
            enableColumnFilter: buscador ?? false,
          });
        }
      );
      const dataInoformacion = response.map((res: any) => {
        return {
          ...res,
          tipo_cliente: "Cliente",
          nombre: res?.nombre ?? "No proporcionado",
          correo: res?.correo ?? "No proporcionado",
          telefono: res?.telefono ?? "No proporcionado",
          ciudad: res?.ciudad ?? "No proporcionado",
          estado: res?.estado ?? "No proporcionado",
        };
      });
      setcolumns(columns);
      ArrayInformacion.push(dataInoformacion);
      const unir = ArrayInformacion.flat();
      // unir acomodar por fecha
      unir.sort((a: any, b: any) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });

      // hacer un mapeo y agregar un campo que diga duplicado
      unir.map((res: any) => {
        // validar si hay duplicados
        const duplicado = unir.filter(
          (item: any) => item.correo === res.correo
        );
        if (duplicado.length > 1) {
          res.duplicado = "Si";
        } else {
          res.duplicado = "No";
        }
        return res;
      });
      setdata(unir);
    });
  };

  useEffect(() => {
    dealers.estadosGet().then((response) => {
      setestados(response.estados);
    });
  }, []);

  const ExportExcel = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      delete res.longitud;
      delete res.latitud;
      delete res.zonas_id;
      delete res.id_zonas;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte-general" + fileExtension);
  };

  const onOpenUpdate = () => {
    setedit(true);
  };

  const updateCliente = async (array: any) => {
    const informacion = await leads.clientUpdate(array);
    if (informacion.guardado) {
      setedit(false);
      getInformacion([]);
    } else {
      Toast.fire({
        icon: "error",
        title: "Error al actualizar",
      });
    }
  };

  const updateLeads = async (array: any) => {
    const informacion = await leads.leadUpdate(array);
    if (informacion.guardado) {
      setedit(false);
      getInformacion([]);
    } else {
      Toast.fire({
        icon: "error",
        title: "Error al actualizar",
      });
    }
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        <ModalEdit
          isOpen={edit}
          onClose={() => setedit(false)}
          array={array}
          setArray={setArray}
          estados={estados}
          title={
            array.tipo_cliente === "Cliente"
              ? "Editar cliente"
              : "Editar posible cliente"
          }
          updateCliente={
            array.tipo_cliente === "Cliente" ? updateCliente : updateLeads
          }
        />
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiUsers size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Clientes/Posibles clientes"
                textTransform={"uppercase"}
              />
            </Flex>
            <Image src={"/stihl-logo.svg"} height={50} />
          </Box>
          <Box display={"flex"} marginTop={5} justifyContent={"flex-end"} gap={3}>
            <ButtonComponents
              color={"#f06e26"}
              text={"Agregar Cliente"}
              onClick={() => {}}
              borderRadius="10px"
              size="md"
            />
            <ButtonComponents
              color={"#f06e26"}
              text={"Agregar Posible Cliente"}
              onClick={() => {}}
              borderRadius="10px"
              size="md"
            />
          </Box>
          <Box overflowX="auto" mt={4}>
            {data.length === 0 && <SkeletonComponent />}
            <DataTable
              columns={columns}
              data={data}
              edit="true"
              onOpenUpdate={onOpenUpdate}
              setArray={setArray}
              ExportExcel={ExportExcel}
              estados={estados}
            />
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
}

const ModalEdit = ({
  isOpen,
  onClose,
  array,
  updateCliente,
  estados,
  setArray,
  title,
}: any) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box p={5}>
          <Box p={5}>
            <TextComponent
              color={"#f06e26"}
              fontSize={"3xl"}
              fontWeight={"bolder"}
              textAlign={"center"}
              text={title}
              textTransform={"uppercase"}
            />
          </Box>
          {/* form array */}
          <Box>
            <Input
              placeholder="Nombre"
              defaultValue={array.nombre}
              mb={2}
              type="text"
              onChange={(e) => {
                setArray({ ...array, nombre: e.target.value });
              }}
            />
            <Input
              placeholder="Apellido"
              defaultValue={array.apellido}
              mb={2}
              type="email"
              onChange={(e) => {
                setArray({ ...array, apellido: e.target.value });
              }}
            />
            <select
              defaultValue={array.estado}
              onChange={(e) => {
                setArray({ ...array, estado: e.target.value });
              }}
              style={{
                width: "100%",
                padding: 10,
                borderRadius: 5,
                border: "1px solid lightgray",
              }}
            >
              {estados.map((res: any) => {
                return (
                  <option
                    value={res.label}
                    selected={array.estado === res.label}
                  >
                    {res.label}
                  </option>
                );
              })}
            </select>
            <Box mb={2} />
            <Input
              placeholder="CP"
              defaultValue={array.cp}
              mb={2}
              type="text"
              onChange={(e) => {
                setArray({ ...array, cp: e.target.value });
              }}
            />
          </Box>
        </Box>
        <ModalFooter>
          <Box
            bg={"#f06e26"}
            borderRadius={10}
            color={"white"}
            cursor={"pointer"}
            p={3}
            w={"50%"}
            _hover={{ bg: "#f06e26" }}
            textAlign={"center"}
            onClick={() => {
              updateCliente(array);
            }}
          >
            Guardar
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
