/* eslint-disable import/no-anonymous-default-export */
function Columns() {
  return [
    { title: "Nombre", campo: "name", buscador: true },
    { title: "Correo", campo: "email", buscador: false },
    { title: "Rol", campo: "rol", buscador: false },
    { title: "Estatus", campo: "status", buscador: false },
  ];
}

export default {
  Columns,
};
