import React from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";

interface FormInterface {
  label: string;
  helperText: string;
  type: string;
  placeholder: string;
  defaultValue: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function FormControlDefaultComponent({
  label,
  helperText,
  type,
  placeholder,
  defaultValue,
  onChange,
}: FormInterface) {
  return (
    <FormControl mt={2}>
      <FormLabel color={useColorModeValue("#000", "#000")}>{label}</FormLabel>
      <Input
        placeholder={placeholder}
        type={type}
        defaultValue={defaultValue}
        onChange={onChange}
        color={useColorModeValue("#000", "#000")}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
