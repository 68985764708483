/* eslint-disable react-hooks/rules-of-hooks */
// add filter table
import * as React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Text, Box } from "@chakra-ui/react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useColorModeValue } from "@chakra-ui/react";
import ButtonComponents from "../Form/Button";
import SelectForm from "../Form/SelectForm";
import TextComponent from "../Form/Text";
import FormControlDefaultComponent from "../Form/FormControlDefault";
import Filter from "./Filter";
import SelectComponents from "../Form/SelectName";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  edit: any;
  onOpenUpdate: any;
  setArray: any;
  client?: any;
  distribuidor?: any;
  subirfactura_bool?: any;
  subirFactura?: any;
  ExportExcel?: any;
};

export function DataTable<Data extends object>({
  data,
  columns,
  edit,
  onOpenUpdate,
  setArray,
  client,
  distribuidor,
  subirfactura_bool = false,
  subirFactura = () => {},
  ExportExcel = () => {},
}: DataTableProps<Data>) {
  const [selectValue, setSelectValue] = React.useState("");
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
    },
  });
  return (
    <>
      <Table variant="striped" colorScheme="gray" size="md">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header: any) => {
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    isNumeric={meta?.isNumeric}
                    color={"black"}
                    paddingLeft={1}
                    paddingRight={1}
                  >
                    <div>
                      <Text
                        fontSize={14}
                        fontWeight={600}
                        textAlign={"center"}
                        marginTop={header.column.getCanFilter() ? "0px" : -30}
                        marginBottom={header.column.getCanFilter() ? "5px" : 0}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Text>
                    </div>

                    {header.column.getCanFilter() ? (
                      header.column.columnDef.header === "Cliente" ? (
                        <div>
                          <select
                            onChange={(e) => {
                              header.column.setFilterValue(e.target.value);
                            }}
                            style={{
                              padding: "10px",
                              width: "100%",
                              borderRadius: "10px",
                              border: "1px solid gray",
                            }}
                          >
                            <option value="">Todos</option>
                            {client.map((cliente: any) => (
                              <option key={cliente.id} value={cliente.id}>
                                {cliente.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : header.column.columnDef.header === "Distribuidor" ? (
                        <div>
                          <SelectComponents
                            label=""
                            onChange={(e) => {
                              header.column.setFilterValue(e.value);
                              setSelectValue(e);
                            }}
                            options={distribuidor.map((dist: any) => {
                              return {
                                value: dist.id,
                                label: dist.label,
                              };
                            })}
                            placeholder="Seleccione un distribuidor"
                            value={selectValue}
                          />
                        </div>
                      ) : (
                        <div>
                          <Filter column={header.column} table={table} />
                        </div>
                      )
                    ) : null}
                  </Th>
                );
              })}
              {subirfactura_bool && (
                <Th>
                  <Text
                    fontSize={14}
                    fontWeight={600}
                    textAlign={"center"}
                    marginTop={-31}
                    marginBottom={"5px"}
                    color={"black"}
                  >
                    Factura
                  </Text>
                </Th>
              )}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          <Tr></Tr>
          {table.getRowModel().rows.map((row: any, index: any) => (
            <Tr key={row.id + index}>
              {row.getVisibleCells().map((cell: any) => {
                const meta: any = cell.column.columnDef.meta;
                if (
                  cell.column.id === "productos" ||
                  cell.column.id === "distribuidor"
                ) {
                  const valueData = cell.getValue();
                  const textAsteric = valueData.split("*");
                  return (
                    <Td
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      color={useColorModeValue("black", "black")}
                      style={{
                        borderBottom: "1px solid #f06e26",
                      }}
                    >
                      {textAsteric.map((res: any) => {
                        return <Text>{res ?? ""}</Text>;
                      })}
                    </Td>
                  );
                }
                return (
                  <Td
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    color={useColorModeValue("black", "black")}
                    style={{
                      borderBottom: "1px solid #f06e26",
                    }}
                  >
                    {flexRender(
                      cell?.column?.columnDef?.cell ?? "",
                      cell.getContext()
                    )}
                  </Td>
                );
              })}
              {subirfactura_bool && (
                <Td>
                  {row?.original?.factura ? (
                    <ButtonComponents
                      color={useColorModeValue("green", "#711c3d")}
                      size="sm"
                      text="Ver factura"
                      onClick={() => {
                        const url =
                          "https://api-crm.contactostihl.com/images/factura/" +
                          row?.original?.factura;
                        window.open(url, "_blank");
                      }}
                    />
                  ) : (
                    <>
                      <input
                        type="file"
                        className="form-control"
                        style={{
                          width: "200px",
                        }}
                        accept="application/pdf, image/*"
                        onChange={(e) => subirFactura(e, row.original)}
                      />
                    </>
                  )}
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box
        display={"flex"}
        justifyContent="end"
        alignItems={"center"}
        gap={5}
        mt={5}
      >
        {table.getCanPreviousPage() && (
          <ButtonComponents
            color={useColorModeValue("#f06e26", "#711c3d")}
            onClick={() => table.setPageIndex(0)}
            size="sm"
            text="<<"
          />
        )}
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.previousPage()}
          size="sm"
          text="<"
        />
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.nextPage()}
          size="sm"
          text=">"
        />
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          size="sm"
          text=">>"
        />
        <TextComponent
          text={`Pagina ${
            table.getState().pagination.pageIndex + 1
          } de ${table.getPageCount()}`}
          color="#000"
          fontSize={"25px"}
          fontWeight="600"
          textAlign={"center"}
        />

        <Box mt={5}>
          <FormControlDefaultComponent
            label=""
            helperText="Ir a la pagina"
            type="text"
            placeholder="Ir a la pagina"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </Box>

        <Box>
          <SelectForm
            helperText=""
            placeholder="Seleccione una opcion"
            label=""
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </SelectForm>
        </Box>
      </Box>
    </>
  );
}
