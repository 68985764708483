/* eslint-disable react-hooks/rules-of-hooks */
// add filter table
import * as React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Text, Box } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useColorModeValue } from "@chakra-ui/react";
import ButtonComponents from "../Form/Button";
import Filter from "./Filter";
import SelectForm from "../Form/SelectForm";
import TextComponent from "../Form/Text";
import FormControlDefaultComponent from "../Form/FormControlDefault";
import { useNavigate } from "react-router-dom";
import ImageMasterPro from "../../images/MasterProactual.jpg";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  edit: any;
  onOpenUpdate: any;
  setArray: any;
  subirfactura_bool?: any;
  subirFactura?: any;
};

export function DataTable<Data extends object>({
  data,
  columns,
  edit,
  onOpenUpdate,
  setArray,
  subirFactura,
  subirfactura_bool = false,
}: DataTableProps<Data>) {
  const navigate = useNavigate();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
    },
  });

  const goToRoute = (route: any) => {
    navigate("/crm/distribuidores/" + route.id);
  };
  return (
    <>
      <Table variant="striped" colorScheme="gray" size="md">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header: any) => {
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    isNumeric={meta?.isNumeric}
                    color={useColorModeValue("black", "black")}
                  >
                    <div>
                      <Text fontSize={14} fontWeight={600} marginBottom={2}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Text>
                    </div>

                    {header.column.getCanFilter() ? (
                      <div>
                        <Filter column={header.column} table={table} />
                      </div>
                    ) : null}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header: any) => {
                return (
                  <Th
                    key={header.id}
                    color={useColorModeValue("black", "black")}
                  >
                    <div>
                      <Text fontSize={14} fontWeight={600}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Text>
                    </div>
                  </Th>
                );
              })}
            </Tr>
          ))}
          {table.getRowModel().rows.map((row: any) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell: any) => {
                const meta: any = cell.column.columnDef.meta;
                if (cell.column.id === "nombre_completo") {
                  const valueData = cell.getValue();
                  const textAsteric = valueData.split("*");
                  return (
                    <Td
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      color={useColorModeValue("black", "black")}
                    >
                      {textAsteric.map((res: any) => {
                        return <Text>{res}</Text>;
                      })}
                    </Td>
                  );
                }
                return (
                  <Td
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    color={useColorModeValue("black", "black")}
                  >
                    {cell.column.id === "distancia" &&
                      row.original.master_pro === "Master PRO" && (
                        <img
                          style={{
                            width: "50px",
                          }}
                          className="mb-5"
                          src={ImageMasterPro}
                          alt=""
                        />
                      )}
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
              {/** add button */}
              {edit ? (
                <>
                  <Td>
                    <ButtonComponents
                      color={useColorModeValue("#f06e26", "#711c3d")}
                      size="sm"
                      text="Ver"
                      onClick={() => {
                        goToRoute(row.original);
                      }}
                    />
                  </Td>
                  <Td>
                    <ButtonComponents
                      color={useColorModeValue("green", "#711c3d")}
                      size="sm"
                      text="Editar"
                      onClick={() => {
                        console.log(row.original);
                        setArray(row.original);
                        onOpenUpdate();
                      }}
                    />
                  </Td>
                </>
              ) : null}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box
        display={"flex"}
        justifyContent="end"
        alignItems={"center"}
        gap={5}
        mt={5}
      >
        <TextComponent
          text={`Pagina ${
            table.getState().pagination.pageIndex + 1
          } de ${table.getPageCount()}`}
          color="#000"
          fontSize={"15px"}
          fontWeight="600"
          textAlign={"center"}
        />

        {table.getCanPreviousPage() && (
          <ButtonComponents
            color={useColorModeValue("#f06e26", "#711c3d")}
            onClick={() => table.setPageIndex(0)}
            size="sm"
            text="<<"
            borderRadius="10px"
          />
        )}
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.previousPage()}
          size="sm"
          text="<"
          borderRadius="10px"
        />
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.nextPage()}
          size="sm"
          text=">"
          borderRadius="10px"
        />
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          size="sm"
          text=">>"
          borderRadius="10px"
        />

        <Box mt={5}>
          <FormControlDefaultComponent
            label=""
            helperText="Ir a la pagina"
            type="text"
            placeholder="Ir a la pagina"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </Box>

        <Box>
          <SelectForm
            helperText=""
            placeholder="Seleccione una opcion"
            label=""
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </SelectForm>
        </Box>
      </Box>
    </>
  );
}
