import React from "react";
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";

interface FormInterface {
  label: string;
  helperText: string;
  value: any;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  children: React.ReactNode;
}

export default function SelectForm({
  label,
  helperText,
  placeholder,
  value,
  onChange,
  children,
}: FormInterface) {
  return (
    <FormControl mt={2} >
      <FormLabel color={useColorModeValue("#000", "#000")}>{label}</FormLabel>
      <Select
        color={useColorModeValue("#000", "#000")}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      >
        {children}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
