export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Fuentes',
    },
  },
};

export function generateData(array: any) {
  const labels = array.map((item: any) => item.fecha);
  return {
    labels,
    datasets: [
      {
        label: "Ventas en pesos",
        data: array.map((item: any) => item.cantidad),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
}

export function generateDataFuentes(array: any) {
  const labels = array.map((item: any) => item.fuente);
  return {
    labels,
    datasets: [
      {
        label: "Cantidad",
        data: array.map((item: any) => item.cantidad),
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(153, 102, 255)",
          "rgb(255, 159, 64)",
        ],
      },
    ],
  };
}

export function generateDataMedio(array: any) {
  const labels = array.map((item: any) => item.medio);
  return {
    labels,
    datasets: [
      {
        label: "Cantidad",
        data: array.map((item: any) => parseInt(item.total ?? item.cantidad)),
        backgroundColor: [
          "rgb(255, 99, 132)",
          "rgb(54, 162, 235)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(153, 102, 255)",
          "rgb(255, 159, 64)",
        ],
      },
    ],
  };
}

export function Columns() {
  return [
    { title: "Fuente", campo: "fuente" },
    { title: "Cantidad", campo: "cantidad" },
  ];
}

export function Columns2() {
  return [
    { title: "Medio", campo: "medio" },
    { title: "Cantidad", campo: "cantidad" },
  ];
}
export const select = [
  { value: "Clientes", label: "Clientes" },
  { value: "Posibles Clientes", label: "Posibles Clientes" },
]