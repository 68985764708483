import {
  Box,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Input,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TextComponent from "../../../components/Form/Text";
import Inputs from "../../../components/Form/Input";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import dealers from "../../../services/dealers";
import { DataTable } from "../../../components/HOC's/DataTableDistribuidor";
import SkeletonComponent from "../../../components/Form/Skeleton";
import DealersArray from "../../../utils/Dealers";
import { createColumnHelper } from "@tanstack/react-table";
import AutoComplete from "../../../components/Form/AutoComplete";
import { FiTruck } from "react-icons/fi";
import SelectComponents from "../../../components/Form/Select";
import Toast from "../../../utils/Toast";
import geomark from "../../../services/geomark";

export default function PosiblesClientes() {
  const [columns, setcolumns] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [estados, setestados] = useState<any>([]);
  const [loader, setloader] = useState(false);
  const [form, setform] = useState<any>({
    lat: "",
    lng: "",
    estado: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [array, setArray] = useState<any>({});
  const [title, setTitle] = useState("");
  const [edit, setedit] = useState(false);
  const [zonaArray, setzonaArray] = useState<any>([]);

  const onChangePlace = (place: any) => {
    if (place?.geometry?.location) {
      setloader(true);
      setform((form: any) => {
        return {
          ...form,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
      });

      dealers
        .dealersSearch({
          latitud: place.geometry.location.lat(),
          longitud: place.geometry.location.lng(),
        })
        .then((response) => {
          const { distribuidor } = response;
          const NewData = distribuidor.map(
            ({
              numero,
              razon_social,
              nombre_comercial,
              lada,
              telefono,
              correo_electronico,
              direccion,
              colonia,
              codigo_postal,
              ciudad,
              provincia_comercial,
              exterior,
              interior,
              distancia,
              sap,
              ...res
            }: any) => {
              return {
                ...res,
                numero,
                razon_social,
                nombre_comercial,
                lada,
                telefono,
                correo_electronico,
                direccion,
                colonia,
                codigo_postal,
                ciudad,
                provincia_comercial,
                exterior,
                interior,
                sap,
                nombre_completo: `Núm. SAP: ${sap ?? ""} * Razón social: ${
                  razon_social ?? ""
                } * Nombre comercial: ${nombre_comercial ?? ""} * Tel: (${
                  lada ?? ""
                }) ${telefono ?? ""} * Correo: ${correo_electronico ?? ""}`,
                direccion_completa: `${direccion} , ${exterior} ${
                  interior ? "," + interior : ""
                } , ${colonia} , ${codigo_postal}, ${ciudad}, ${provincia_comercial}`,
                distancia: NumberToTwoDecimals(distancia) + " km",
              };
            }
          );

          const columnHelper = createColumnHelper<any>();
          const columns = DealersArray.Columns().map(
            ({ title, campo }: any) => {
              return columnHelper.accessor(campo, {
                cell: (info: any) => info.getValue(),
                header: title ?? "",
              });
            }
          );
          setcolumns(columns);
          setdata(NewData);

          setloader(false);
        });
    }
  };

  const NumberToTwoDecimals = (number: any) =>
    number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  useEffect(() => {
    dealers.estadosGet().then((response) => {
      setestados(response.estados);
    });
  }, []);

  useEffect(() => {
    geomark.geomark().then((res) => {
      setzonaArray(res.zonas);
    });

    return () => {};
  }, []);

  const getEstadosDat = (estado: any) => {
    dealers.dealersEstadoGet(estado?.label).then((response) => {
      const { distri } = response;
      const NewData = distri.map(
        ({
          numero,
          razon_social,
          nombre_comercial,
          lada,
          telefono,
          correo_electronico,
          direccion,
          colonia,
          codigo_postal,
          ciudad,
          provincia_comercial,
          exterior,
          interior,
          distancia,
          sap,
          ...res
        }: any) => {
          return {
            ...res,
            numero,
            razon_social,
            nombre_comercial,
            lada,
            telefono,
            correo_electronico,
            direccion,
            colonia,
            codigo_postal,
            ciudad,
            provincia_comercial,
            exterior,
            interior,
            sap,
            nombre_completo: `Núm. SAP: ${sap ?? ""} * Razón social: ${
              razon_social ?? ""
            } * Nombre comercial: ${nombre_comercial ?? ""} * Tel: (${
              lada ?? ""
            }) ${telefono ?? ""} * Correo: ${correo_electronico ?? ""}`,
            direccion_completa: `${direccion} , ${exterior} ${
              interior ? "," + interior : ""
            } , ${colonia} , ${codigo_postal}, ${ciudad}, ${provincia_comercial}`,
            distancia: NumberToTwoDecimals(distancia ?? 0) + " km",
          };
        }
      );

      const columnHelper = createColumnHelper<any>();
      const columns = DealersArray.Columns2().map(({ title, campo }: any) => {
        return columnHelper.accessor(campo, {
          cell: (info: any) => info.getValue(),
          header: title ?? "",
        });
      });
      setcolumns(columns);
      setdata(NewData);

      setloader(false);
    });
  };

  const onOpenUpdate = () => {
    setIsOpen(true);
    setTitle("Editar Distribuidor");
    setedit(true);
  };

  const updateDistribuidor = async (data: any) => {
    const informacion = await dealers.dealersUpdate(array);
    if (informacion.guardado) {
      setedit(false);
      setIsOpen(false);
      getEstadosDat(form?.estado);
      Toast.fire({
        icon: "success",
        title: "Actualizado correctamente",
      });
    } else {
      Toast.fire({
        icon: "error",
        title: "Error al actualizar",
      });
    }
  }

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} px={10} py={10} borderRadius={20}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexWrap={"wrap"}
          >
            <Flex gridGap={3} alignItems={"center"}>
              <FiTruck size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Distribuidores"
                textTransform={"uppercase"}
              />
            </Flex>
            <Image src={"/stihl-logo.svg"} height={50} />
          </Box>
          <Box display={"flex"} justifyContent={"flex-start"} mt={4} gap={4}>
            <Box width={400}>
              <AutoComplete
                label="Dirección"
                helperText=""
                onChangePlace={onChangePlace}
              />
            </Box>
            <Box width={510}>
              <SelectComponents
                placeholder="Selecciona un estado"
                value={form.estado}
                label="Estado"
                options={estados}
                onChange={(e: any) => {
                  setform((form: any) => {
                    return {
                      ...form,
                      estado: e,
                    };
                  });
                  getEstadosDat(e);
                }}
              />
            </Box>
          </Box>
          <Box display={"none"}>
            <Inputs
              type="text"
              placeholder=""
              isRequired={false}
              label="Latitud"
              value={form.lat}
              onChange={(e: any) => {
                setform((form: any) => {
                  return {
                    ...form,
                    lat: e.target.value,
                  };
                });
              }}
            />
          </Box>
          <Box overflowX="auto" mt={4}>
            {loader && <SkeletonComponent />}
          </Box>
          <Box overflowX="auto" mt={4}>
            {data.length > 0 && (
              <DataTable
                columns={columns}
                data={data}
                edit="true"
                onOpenUpdate={onOpenUpdate}
                setArray={setArray}
              />
            )}
          </Box>
          <ModalEdit
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
              setedit(false);
            }}
            array={array}
            updateCliente={updateDistribuidor}
            estados={estados}
            setArray={setArray}
            title={title}
            zonaArray={zonaArray}
          />
        </Box>
      </Sidebar>
    </div>
  );
}

const ModalEdit = ({
  isOpen,
  onClose,
  array,
  updateCliente,
  estados,
  setArray,
  title,
  zonaArray,
}: any) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box p={5}>
          <Box p={5}>
            <TextComponent
              color={"#f06e26"}
              fontSize={"3xl"}
              fontWeight={"bolder"}
              textAlign={"center"}
              text={title}
              textTransform={"uppercase"}
            />
          </Box>
          {/* form array */}
          <Box>
            <p className="text-center text-red-500 text-xs">Razón Social</p>
            <Input
              placeholder="Razón Social"
              value={array.razon_social}
              mb={2}
              type="text"
              onChange={(e: any) => {
                setArray({ ...array, razon_social: e.target.value });
              }}
            />
            <p className="text-center text-red-500 text-xs">Nombre Comercial</p>
            <Input
              placeholder="Nombre Comercial"
              value={array.nombre_comercial}
              mb={2}
              type="email"
              onChange={(e: any) => {
                setArray({ ...array, nombre_comercial: e.target.value });
              }}
            />
            <p className="text-center text-red-500 text-xs">Número de SAP</p>
            <Input
              placeholder="Número SAP"
              value={array.sap}
              mb={2}
              type="email"
              onChange={(e: any) => {
                setArray({ ...array, sap: e.target.value });
              }}
            />
            <p className="text-center text-red-500 text-xs">Estado</p>
            <select
              value={array.provincia_comercial}
              onChange={(e) => {
                setArray({ ...array, estado: e.target.value });
              }}
              style={{
                width: "100%",
                padding: 10,
                borderRadius: 5,
                border: "1px solid lightgray",
              }}
            >
              {estados.map((res: any) => {
                return (
                  <option
                    value={res.label}
                    selected={array.provincia_comercial === res.label}
                  >
                    {res.label}
                  </option>
                );
              })}
            </select>
            <Box mb={2} />
            <p className="text-center text-red-500 text-xs">Telefono</p>
            <Input
              placeholder="Telefono"
              value={array.telefono}
              mb={2}
              type="text"
              onChange={(e: any) => {
                setArray({ ...array, telefono: e.target.value });
              }}
            />
            <p className="text-center text-red-500 text-xs">Zona</p>
            <select
              value={array.zonas_id}
              onChange={(e) => {
                setArray({ ...array, zonas_id: e.target.value });
              }}
              style={{
                width: "100%",
                padding: 10,
                borderRadius: 5,
                border: "1px solid lightgray",
              }}
            >
              {zonaArray.map((res: any) => {
                return (
                  <option value={res.label} selected={array.zona === res.label}>
                    {res.label}
                  </option>
                );
              })}
            </select>
          </Box>
        </Box>
        <ModalFooter>
          <Box
            bg={"#f06e26"}
            borderRadius={10}
            color={"white"}
            cursor={"pointer"}
            p={3}
            w={"50%"}
            _hover={{ bg: "#f06e26" }}
            textAlign={"center"}
            onClick={() => {
              updateCliente(array);
            }}
          >
            Guardar
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
