/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-lone-blocks */
import { Box, Flex, Image, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import ButtonComponents from "../../../components/Form/Button";
import SelectComponents from "../../../components/Form/Select";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import traffic from "../../../services/traffic";
import SkeletonComponent from "../../../components/Form/Skeleton";
import { createColumnHelper } from "@tanstack/react-table";
import {
  options,
  generateData,
  Columns,
  Columns2,
  select,
  generateDataFuentes,
  generateDataMedio,
} from "../../../utils/Traffic";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  ArcElement,
  Legend,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import Card from "../../../components/Form/Card";
import { DataTable } from "../../../components/HOC's/DataTableTrafico";
import { FiDollarSign } from "react-icons/fi";
import Toast from "../../../utils/Toast";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function PosiblesClientes() {
  const toast = useToast();
  const [form, setform] = useState<any>({
    desde: "",
    hasta: "",
    tipo: "",
  });
  const [total_clientes, settotal_clientes] = useState("");

  const [columns, setcolumns] = useState<any>([]);
  const [columns2, setcolumns2] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [data2, setdata2] = useState<any>([]);
  const [arrayData, setarrayData] = useState<any>([]);
  const [arrayDataFuente, setarrayDataFuente] = useState<any>([]);
  const [arrayFuentes, setarrayFuentes] = useState<any>([]);
  const [arrayFuentes2, setarrayFuentes2] = useState<any>([]);
  const [loader, setloader] = useState(false);

  const buscarReporte = () => {
    if (!form.desde) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de inicio",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    if (!form.hasta) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de finalización",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (!form.tipo) {
      toast({
        title: "Error",
        description: "Debe seleccionar un tipo de reporte",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const date = new Date(form.desde);
    const date2 = new Date(form.hasta);
    setloader(true);
    traffic
      .getTraffic({
        desde: date.toISOString().split("T")[0],
        hasta: date2.toISOString().split("T")[0],
        tipo: form.tipo.value,
      })
      .then((response: any) => {
        setloader(false);

        {
          form.tipo.value === "Clientes" &&
            settotal_clientes(response.total_clientes);
        }

        {
          form.tipo.value === "Posibles Clientes" &&
            settotal_clientes(response.total_posibles + " Posibles Clientes");
        }

        setarrayData(response.grafica);
        setarrayDataFuente(generateData(response.grafica));
        setarrayFuentes(generateDataFuentes(response.fuente_tot));

        const columnHelper = createColumnHelper<any>();
        const columns = Columns().map(({ title, campo }: any) => {
          return columnHelper.accessor(campo, {
            cell: (info: any) => info.getValue(),
            header: title ?? "",
          });
        });
        setcolumns(columns);

        const {
          displ,
          instagram,
          directo,
          youtube,
          faceb,
          google,
          espectacular,
          barda,
          expo,
          investigacion,
          ninguno,
          tienda,
        } = response;
        setdata([
          createDataTable(displ, "Display"),
          createDataTable(instagram, "Instagram"),
          createDataTable(directo, "Directo"),
          createDataTable(youtube, "Youtube"),
          createDataTable(faceb, "Facebook"),
          createDataTable(google, "Google"),
          createDataTable(espectacular, "Espectacular"),
          createDataTable(barda, "Barda"),
          createDataTable(expo, "Expo"),
          createDataTable(investigacion, "Investigacion"),
          createDataTable(ninguno, "Ninguno"),
          createDataTable(tienda, "Tienda"),
        ]);
      })
      .catch(({ message }) => {
        setloader(false);
        toast({
          title: "Error",
          description: message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const activarFuentes = (e: any) => {
    if (e.original.cantidad === 0 || e.original.cantidad === "0") {
      Toast.fire({
        icon: "error",
        title: "Error",
        text: "No hay datos para mostrar",
      });
    }
    setarrayFuentes2(generateDataMedio(e.original.array));
    createDataTable2(e.original.array);
  };

  const createDataTable = (array: any, fuente = "") => {
    if (array.length > 0) {
      const total = array.reduce((acc: any, item: any) => {
        return acc + item.total;
      }, 0);

      return {
        fuente: array[0].fuente,
        cantidad: "" + (total === "" ? 0 : total),
        array: array,
      };
    } else {
      return {
        fuente: fuente,
        cantidad: "" + 0,
        array: array,
      };
    }
  };

  const ExportExcel = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      delete res.array;
      delete res.data;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Ventas en pesos" + fileExtension);
  };

  const ExportExcelFuentes = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      delete res.array;
      delete res.data;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Fuentes" + fileExtension);
  };

  const createDataTable2 = (array: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = Columns2().map(({ title, campo }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
      });
    });
    setcolumns2(columns);
    const data = array.map((item: any) => {
      return {
        medio: item?.medio ?? "Sin información",
        cantidad: "" + (item?.total ?? 0),
      };
    });
    setdata2(data);
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiDollarSign size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Reporte de trafico"
                textTransform={"uppercase"}
              />
            </Flex>
            <Image src={"/stihl-logo.svg"} height={50} />
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={10}
            flexDirection="row"
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              mt={5}
              px={10}
              borderRadius={20}
              width={"500px"}
            >
              <Box mt={5} />
              <ReactDatePicker
                isClearable={true}
                selectsRange={true}
                startDate={form.desde}
                endDate={form.hasta}
                onChange={(date: any) => {
                  setform({ ...form, desde: date[0], hasta: date[1] });
                }}
                dateFormat="yyyy-MM-dd"
                placeholderText="Fecha de inicio - Fecha de finalización"
                className="form-control w-100 border"
                showYearDropdown
                locale={"es"}
              />
            </Box>
            <div
              style={{
                width: "400px",
              }}
            >
              <SelectComponents
                placeholder="Seleccione una opción"
                label="Tipo"
                value={form.tipo}
                onChange={(e) => setform({ ...form, tipo: e })}
                options={select}
              />
            </div>
            <Box mt={5}>
              <ButtonComponents
                text="Buscar"
                color="#FF7B05"
                onClick={buscarReporte}
                size="sm"
              />
            </Box>
            <Box mt={5} display={"flex"} gap={5}>
              {arrayData.length > 0 && (
                <ButtonComponents
                  text="Exportar Excel Ventas"
                  color="green"
                  onClick={() => {
                    ExportExcel(arrayData);
                  }}
                  size="sm"
                />
              )}
              {data.length > 0 && (
                <ButtonComponents
                  text="Exportar Excel Fuentes"
                  color="green"
                  onClick={() => {
                    ExportExcelFuentes(data);
                  }}
                  size="sm"
                />
              )}
            </Box>
          </Box>
          {loader && <SkeletonComponent />}

          <Box display={"flex"} justifyContent={"center"} mt={"5"} gap={5}>
            {total_clientes && (
              <>
                <Card text={total_clientes} />
              </>
            )}
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            mt={"5"}
            gap={5}
            px={20}
            height={500}
          >
            {arrayDataFuente?.labels?.length > 0 && (
              <Line options={options} data={arrayDataFuente} />
            )}
          </Box>

          <Box
            display={"flex"}
            justifyContent={"center"}
            mt={"5"}
            gap={5}
            height={400}
          >
            {arrayFuentes?.labels?.length > 0 && (
              <Pie options={options} data={arrayFuentes} />
            )}
            {arrayFuentes2?.labels?.length > 0 && (
              <Pie key={"2"} options={options} data={arrayFuentes2} />
            )}
          </Box>
          <Box display={"flex"} justifyContent={"center"} mt={"5"} gap={5}>
            <Box>
              {data.length > 0 && (
                <DataTable
                  activarFuentes={activarFuentes}
                  columns={columns}
                  data={data}
                  edit=""
                  onOpenUpdate={() => {}}
                  setArray={[]}
                  size="sm"
                />
              )}
            </Box>
            <Box>
              {data2?.length > 0 && (
                <DataTable
                  activarFuentes={() => {}}
                  columns={columns2}
                  data={data2}
                  edit=""
                  onOpenUpdate={() => {}}
                  setArray={[]}
                  size="sm"
                />
              )}
            </Box>
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
}
