import Login from "../pages/Auth/login";
import CRM from "../pages/CRM";
import Home from "../pages/Home";
import Web from "../pages/Web";
import ErrorPage from "../pages/Error/404";
import PosiblesClientes from "../pages/CRM/PosiblesClientes";
import ClientesNuevos from "../pages/CRM/ClientesNuevos";
import Clientes from "../pages/CRM/Clientes";
import Usuarios from "../pages/CRM/Usuarios";
import Questions from "../pages/questions";
import Reporte from "../pages/CRM/Reporte";
import Ticket from "../pages/CRM/Ticket";
import Tickets from "../pages/CRM/Tickets";
import TicketsVer from "../pages/CRM/Tickets/ver";
import Distribuidores from "../pages/CRM/Distribuidores";
import ViewDistribuidores from "../pages/CRM/Distribuidores/View";
import ReporteVentas from "../pages/CRM/ReporteVentas";
import ReporteTrafico from "../pages/CRM/ReporteTrafico";
import ReporteComparativo from "../pages/CRM/ReporteComparativo";
import ReporteDesgloseVentas from "../pages/CRM/ReporteDesgloseVentas";
import ReporteFuentes from "../pages/CRM/ReporteFuentes";
import ReporteMaster from "../pages/CRM/ReporteMaster";
import Top from "../pages/CRM/TopVentas";
import ReporteCategorias from "../pages/CRM/Categorias";
import ReporteCategoriasProducto from "../pages/CRM/CategoriasProducto";
import ReporteFuentesView from "../pages/CRM/ReporteFuentes/view";
import GeomarketingView from "../pages/CRM/Geomarketing";
import OrdenCompra from "../pages/CRM/OrdenCompra";
import PrivateRoute from "./PrivateRoute";
import { Routes, Route } from "react-router-dom";

export default function Root() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route index element={<Home />} />
        <Route path="web" element={<PrivateRoute />}>
          <Route index element={<Web />} />
        </Route>
      </Route>

      <Route path="/preguntas-satisfaccion/:id/ver" element={<Questions />} />

      <Route path="/crm" element={<PrivateRoute />}>
        <Route index element={<CRM />} />
        <Route path="ordenes-de-compra" element={<OrdenCompra />} />
        <Route path="posibles-clientes" element={<PosiblesClientes />} />
        <Route path="clientes" element={<ClientesNuevos />} />
        <Route path="tipo-de-clientes" element={<Clientes />} />
        <Route path="usuarios" element={<Usuarios />} />
        <Route path="tipo-de-reporte" element={<Reporte />} />
        <Route path="crear-ticket-de-seguimiento" element={<Ticket />} />
        <Route path="tickets-de-seguimiento" element={<Tickets />} />
        <Route path="tickets/:id/ver" element={<TicketsVer />} />
        <Route path="top-ventas" element={<Top />} />
        <Route path="categorías" element={<ReporteCategorias />} />
        <Route path="categorías-por-producto" element={<ReporteCategoriasProducto />} />
        <Route path="ventas" element={<ReporteVentas />} />
        <Route path="tráfico" element={<ReporteTrafico />} />
        <Route path="master" element={<ReporteMaster />} />
        <Route path="comparativo" element={<ReporteComparativo />} />
        <Route path="venta-distribuidor" element={<ReporteDesgloseVentas />} />
        <Route path="fuentes" element={<ReporteFuentes />} />
        <Route path="geomarketing" element={<GeomarketingView />} />
        <Route
          path="reportes-fuentes/:estado"
          element={<ReporteFuentesView />}
        />
        <Route path="distribuidores" element={<Distribuidores />} />
        <Route path="distribuidores/:id" element={<ViewDistribuidores />} />
      </Route>

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}
