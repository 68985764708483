import { Box, Flex, Image, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import ButtonComponents from "../../../components/Form/Button";
import TextComponent from "../../../components/Form/Text";
import FormControlComponent from "../../../components/Form/FormControl";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import sources from "../../../services/sources";
import { DataTable } from "../../../components/HOC's/DataTableFuentes";
import SkeletonComponent from "../../../components/Form/Skeleton";
import { createColumnHelper } from "@tanstack/react-table";
import { Columns, Fuente, Tipo } from "../../../utils/Sources";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import SelectComponents from "../../../components/Form/Select";
import { useNavigate } from "react-router-dom";
import { FiDollarSign } from "react-icons/fi";

export default function PosiblesClientes() {
  const navigate = useNavigate();
  const toast = useToast();
  const [form, setform] = useState<any>({
    desde: "",
    hasta: "",
    tipo: "",
    fuente: "",
  });
  const [loader, setloader] = useState(false);
  const [columnVentas, setcolumnVentas] = useState<any>([]);
  const [dataVentas, setdataVentas] = useState<any>([]);
  const [data, setdata] = useState<any>([]);

  const buscarReporte = () => {
    if (!form.desde) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de inicio",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    if (!form.hasta) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de finalización",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    setloader(true);
    sources
      .sourcesGet({
        desde: form.desde,
        hasta: form.hasta,
        fuente: form.fuente.value,
        tipo: form.tipo.value,
      })
      .then((response: any) => {
        createArrayListadoProductos(response);
        setloader(false);
      });
  };

  const createArrayListadoProductos = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = Columns().map(({ title, campo }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
      });
    });
    setcolumnVentas(columns);
    setdata(response);
    const array = response;
    const data = array.map((item: any, index: any) => {
      return {
        _id: index + 1,
        estado: item.estado,
        total_ventas: item.total.toString(),
        total_vendidos: precio(parseFloat(item.total_venta)),
        productos: "",
      };
    });
    setdataVentas(data);
  };

  const precio = (precio: any) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(precio);
  };

  const ExportExcel = (array: any) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(array);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte-general" + fileExtension);
  };

  const goToRoute = (array: any) => {
    navigate(
      "/crm/reportes-fuentes/" +
        array.estado +
        "?inicial=" +
        form.desde +
        "&final=" +
        form.hasta +
        "&fuente=" +
        form.fuente.value
    );
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiDollarSign size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Reporte de fuente"
                textTransform={"uppercase"}
              />
            </Flex>
            <Image
              src={"/stihl-logo.svg"}
              height={50}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={10}
            mt={3}
          >
            <FormControlComponent
              helperText=""
              placeholder=""
              label="Desde"
              type="date"
              value={form.desde}
              onChange={(e) => setform({ ...form, desde: e.target.value })}
            />
            <FormControlComponent
              helperText=""
              placeholder=""
              label="Hasta"
              type="date"
              value={form.hasta}
              onChange={(e) => setform({ ...form, hasta: e.target.value })}
            />
            <SelectComponents
              label="Tipo"
              value={form.tipo}
              onChange={(e) => setform({ ...form, tipo: e })}
              options={Tipo}
              placeholder="Seleccione un tipo"
            />
            <SelectComponents
              label="Fuente"
              value={form.fuente}
              onChange={(e) => setform({ ...form, fuente: e })}
              options={Fuente}
              placeholder="Seleccione una fuente"
            />
            <Box mt={5}>
              <ButtonComponents
                text="Buscar"
                color="#FF7B05"
                onClick={buscarReporte}
                size="lg"
              />
            </Box>
          </Box>
          {loader && <SkeletonComponent />}
          <Box overflowX="auto">
            {dataVentas.length > 0 && (
              <DataTable
                columns={columnVentas}
                data={dataVentas}
                edit="true"
                goToRoute={goToRoute}
                ExportExcel={ExportExcel}
              />
            )}
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
}
