import React, { useEffect, useState } from "react";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import { Box, Flex, Image } from "@chakra-ui/react";
import TextComponent from "../../../components/Form/Text";
import { IoEarthOutline } from "react-icons/io5";
import SelectComponents from "../../../components/Form/Select";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import ButtonComponents from "../../../components/Form/Button";
import geomark from "../../../services/geomark";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import Toast from "../../../utils/Toast";
import dealers from "../../../services/dealers";
import ReactDatePicker from "react-datepicker";

const containerStyle = {
  width: "100%",
  height: "600px",
  borderRadius: "20px",
};

const TipoBusqueda = [
  { value: 1, label: "Productos" },
  { value: 2, label: "Clientes" },
  { value: 3, label: "Distribuidor" },
];

const Meses = [
  { value: 1, label: "Enero" },
  { value: 2, label: "Febrero" },
  { value: 3, label: "Marzo" },
  { value: 4, label: "Abril" },
  { value: 5, label: "Mayo" },
  { value: 6, label: "Junio" },
  { value: 7, label: "Julio" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Septiembre" },
  { value: 10, label: "Octubre" },
  { value: 11, label: "Noviembre" },
  { value: 12, label: "Diciembre" },
];

const Anios = [
  { value: 0, label: "2024" },
  { value: 1, label: "2023" },
  { value: 2, label: "2022" },
  { value: 3, label: "2021" },
  { value: 4, label: "2020" },
  { value: 5, label: "2019" },
  { value: 6, label: "2018" },
  { value: 7, label: "2017" },
  { value: 8, label: "2016" },
];
export default function Geomarketing() {
  const [tipo_busqueda, settipo_busqueda] = useState<any>();
  const [zona, setzona] = useState<any>();
  const [producto, setproducto] = useState<any>();
  const [estado, setestado] = useState<any>();
  const [form, setform] = useState<any>({
    desde: null,
    hasta: null,
    desde_label: "",
    hasta_label: "",
    desde_ano_label: "",
    hasta_ano_label: "",
  });
  const [mes, setmes] = useState<any>();
  const [mes2, setmes2] = useState<any>();
  const [anio, setanio] = useState<any>();
  const [anio2, setanio2] = useState<any>();
  const [loading, setloading] = useState(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBg_7yMfuP2MxOiGAJKoMpokvPzRoo3HLE",
  });
  const [zoom, setzoom] = useState(5);
  const [center, setcenter] = useState({
    lat: 23.634501,
    lng: -102.552784,
  });

  const [zonaArray, setzonaArray] = useState([]);
  const [productosArray, setproductosArray] = useState([]);
  const [estadosArray, setestadosArray] = useState([]);

  const [productosData, setproductosData] = useState([]);
  const [clientesData, setclientesData] = useState([]);
  const [distribuidorData, setdistribuidorData] = useState([]);
  const [buscador, setbuscador] = useState("");

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  useEffect(() => {
    geomark.geomark().then((res) => {
      setzonaArray(res.zonas);
      setproductosArray(res.productos);
    });

    return () => {};
  }, []);

  useEffect(() => {
    dealers.estadosGet().then((res) => {
      const estados = res?.estados ?? [];
      estados.unshift({ value: "", label: "Todos" });
      setestadosArray(res?.estados ?? []);
    });
    return () => {};
  }, []);

  const buscar = () => {
    setloading(true);
    if (tipo_busqueda?.value === 1) {
      let desde_mes = form.desde.getMonth() + 1;
      let hasta_mes = form.hasta.getMonth() + 1;
      const desde_ano = form.desde.getFullYear();
      const hasta_ano = form.hasta.getFullYear();

      if (desde_mes < 10) {
        desde_mes = "0" + desde_mes;
      } else {
        desde_mes = desde_mes + "";
      }

      if (hasta_mes < 10) {
        hasta_mes = "0" + hasta_mes;
      } else {
        hasta_mes = hasta_mes + "";
      }

      const data = {
        zona: zona?.label,
        producto: producto?.value,
        desde: `${desde_ano}-${desde_mes}-01`,
        hasta: `${hasta_ano}-${hasta_mes}-${new Date(
          form.hasta.getFullYear(),
          form.hasta.getMonth() + 1,
          0
        ).getDate()}`,
      };

      geomark
        .geomarketingProductosGet(data)
        .then((res) => {
          const productos = res.productos;
          if (productos.length === 0) {
            setloading(false);
            Toast.fire({
              icon: "error",
              title: "No se encontraron resultados",
            });
            setproductosData([]);
            return;
          }
          // unir todos los label iguales
          let result: any = [];
          productos.forEach((item: any) => {
            let index = result.findIndex(
              (x: any) => x.id?.toUpperCase() === item.label?.toUpperCase()
            );
            if (index === -1) {
              result.push({
                id: item.label?.toUpperCase(),
                label: item.label,
                latitud: item.latitud,
                longitud: item.longitud,
                data: [item],
                value: 1,
              });
            } else {
              result[index].value += 1;
              result[index].data.push(item);
            }
          });

          setloading(false);
          setproductosData(result);
        })
        .catch((err) => {
          setloading(false);
        });
    } else if (tipo_busqueda?.value === 2) {
      let desde_mes = form.desde.getMonth() + 1;
      let hasta_mes = form.hasta.getMonth() + 1;
      const desde_ano = form.desde.getFullYear();
      const hasta_ano = form.hasta.getFullYear();

      if (desde_mes < 10) {
        desde_mes = "0" + desde_mes;
      } else {
        desde_mes = desde_mes + "";
      }

      if (hasta_mes < 10) {
        hasta_mes = "0" + hasta_mes;
      } else {
        hasta_mes = hasta_mes + "";
      }

      const data = {
        zona: zona?.label,
        desde: `${desde_ano}-${desde_mes}-01`,
        hasta: `${hasta_ano}-${hasta_mes}-${new Date(
          form.hasta.getFullYear(),
          form.hasta.getMonth() + 1,
          0
        ).getDate()}`,
      };

      geomark
        .geomarketingClientesGet(data)
        .then((res) => {
          if (res.clientes.length === 0) {
            setloading(false);
            Toast.fire({
              icon: "error",
              title: "No se encontraron resultados",
            });
            setclientesData([]);
            return;
          }
          setproductosData([]);
          setloading(false);
          setclientesData(res.clientes);
        })
        .catch((err) => {
          setloading(false);
        });
    } else if (tipo_busqueda?.value === 3) {
      let desde_mes = form.desde.getMonth() + 1;
      let hasta_mes = form.hasta.getMonth() + 1;
      const desde_ano = form.desde.getFullYear();
      const hasta_ano = form.hasta.getFullYear();

      if (desde_mes < 10) {
        desde_mes = "0" + desde_mes;
      } else {
        desde_mes = desde_mes + "";
      }

      if (hasta_mes < 10) {
        hasta_mes = "0" + hasta_mes;
      } else {
        hasta_mes = hasta_mes + "";
      }

      const data = {
        zona: zona?.label,
        desde: `${desde_ano}-${desde_mes}-01`,
        hasta: `${hasta_ano}-${hasta_mes}-${new Date(
          form.hasta.getFullYear(),
          form.hasta.getMonth() + 1,
          0
        ).getDate()}`,
      };

      geomark
        .geomarketingDistribuidorGet(data)
        .then((res) => {
          if (res.length === 0) {
            setloading(false);
            Toast.fire({
              icon: "error",
              title: "No se encontraron resultados",
            });
            setdistribuidorData([]);
            return;
          }
          setloading(false);
          setdistribuidorData(res);

          geomark.estadosZonas(zona?.value).then((res) => {
            const estados = res ?? [];
            estados.unshift({ value: "", label: "Todos" });
            setestadosArray(res ?? []);
          });
        })
        .catch((err) => {
          setloading(false);
        });
    }
  };

  const quitarEspacioYParse = (value: any) => {
    return parseFloat(value?.replace(" ", ""));
  };

  const obtenerTotalClientes = () => {
    let totalClientes = clientesData.filter((item: any) => {
      if (estado?.value === "") {
        return item;
      } else if (
        item.estado.toLowerCase().includes(estado?.label.toLowerCase())
      ) {
        return item;
      }
    }).length;
    if (clientesData.length > 0) {
      return (
        <Marker
          position={{
            lat: estado?.latitud
              ? quitarEspacioYParse(estado?.latitud)
              : 23.634501,
            lng: estado?.longitud
              ? quitarEspacioYParse(estado?.longitud)
              : -102.552784,
          }}
          label={estado?.label + " " + totalClientes.toString()}
          title={estado?.label + " " + totalClientes.toString()}
          visible={true}
        />
      );
    }
  };

  const ExportExcel = (array: any) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // array va a ir excel
    const dataArray: any = [];
    array.map((res: any) => {
      res?.data.map((item: any) => {
        dataArray.push({
          estado: res.id,
          nombre: item.nombre,
          apellido: item.apellido,
          fecha_venta: item.fecha_venta,
        });
        return item;
      });
      return res;
    });

    const ws = XLSX.utils.json_to_sheet(dataArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte-general" + fileExtension);
  };

  const ExportExcelClientes = (array: any) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // array va a ir excel
    const dataArray = clientesData.map((item: any) => {
      delete item.latitud;
      delete item.longitud;
      delete item.id_zonas;
      delete item.created_at;
      delete item.updated_at;
      return item;
    });

    const ws = XLSX.utils.json_to_sheet(dataArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte-general" + fileExtension);
  };

  const ExportExcelDistribuidor = (array: any) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // array va a ir excel
    const dataArray = distribuidorData.map((item: any) => {
      delete item.latitud;
      delete item.longitud;
      delete item.id_zonas;
      delete item.created_at;
      delete item.updated_at;
      return item;
    });

    const ws = XLSX.utils.json_to_sheet(dataArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte-general" + fileExtension);
  };

  return (
    <>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <IoEarthOutline size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Geolocalización"
                textTransform={"uppercase"}
              />
            </Flex>
            <Image src={"/stihl-logo.svg"} height={50} />
          </Box>

          <Flex
            width={"100%"}
            justifyContent={"space-between"}
            gap={10}
            bg={"#eeeeee"}
            borderRadius={20}
            padding={5}
            mt={5}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              px={10}
              width={"50%"}
              height={"100%"}
              borderRight={"1px solid #ccc"}
            >
              <Box mt={2} />
              <SelectComponents
                options={TipoBusqueda}
                label="Tipo de busqueda"
                placeholder="Seleccione una opcion"
                value={tipo_busqueda}
                onChange={(e) => {
                  settipo_busqueda(e);
                  if (e.value === 1) {
                    setclientesData([]);
                    setdistribuidorData([]);
                  } else if (e.value === 2) {
                    setproductosData([]);
                    setdistribuidorData([]);
                  } else if (e.value === 3) {
                    setproductosData([]);
                    setclientesData([]);
                  }
                }}
              />

              <SelectComponents
                options={zonaArray}
                label="Zonas"
                placeholder="Seleccione una opcion"
                value={zona}
                onChange={(e) => setzona(e)}
              />

              {tipo_busqueda?.value === 1 && (
                <SelectComponents
                  options={productosArray}
                  label="Productos"
                  placeholder="Seleccione una opcion"
                  value={producto}
                  onChange={(e) => setproducto(e)}
                />
              )}

              {/*(tipo_busqueda?.value === 2 || tipo_busqueda?.value === 3) && (
                <SelectComponents
                  options={estadosArray}
                  label="Estados"
                  placeholder="Seleccione una opcion"
                  value={estado}
                  onChange={(e) => setestado(e)}
                />
              )*/}

              <Box
                display={"flex"}
                flexDirection={"column"}
                mt={5}
                borderRadius={20}
              >
                <ReactDatePicker
                  isClearable={true}
                  selectsRange={false}
                  startDate={form.desde}
                  onChange={(date: any) => {
                    const desde_label = date.toLocaleString("default", {
                      month: "long",
                    });
                    const desde_ano_label = date.getFullYear();
                    setform({
                      ...form,
                      desde: date,
                      desde_label,
                      desde_ano_label,
                    });
                  }}
                  selected={form.desde}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Desde"
                  className="form-control w-100 border"
                  showMonthYearPicker
                  locale={"es"}
                  fixedHeight
                />
              </Box>

              <Box
                display={"flex"}
                flexDirection={"column"}
                mt={5}
                borderRadius={20}
              >
                <ReactDatePicker
                  isClearable={true}
                  selectsRange={false}
                  startDate={form.hasta}
                  onChange={(date: any) => {
                    const hasta_label = date.toLocaleString("default", {
                      month: "long",
                    });
                    const hasta_ano_label = date.getFullYear();
                    setform({
                      ...form,
                      hasta: date,
                      hasta_label,
                      hasta_ano_label,
                    });
                  }}
                  selected={form.hasta}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Hasta"
                  className="form-control w-100 border"
                  showMonthYearPicker
                  locale={"es"}
                  fixedHeight
                />
              </Box>

              <Box mt={10} alignSelf={"flex-end"} gap={3}>
                <ButtonComponents
                  color={"#f06e26"}
                  text={"Buscar"}
                  size={"sm"}
                  borderRadius={"10px"}
                  onClick={buscar}
                  loading={loading}
                />
                {productosData.length > 0 && (
                  <ButtonComponents
                    color={"green"}
                    text={"Exportar reporte"}
                    size={"sm"}
                    borderRadius={"10px"}
                    onClick={() => {
                      ExportExcel(productosData);
                    }}
                  />
                )}
                {clientesData.length > 0 && (
                  <ButtonComponents
                    color={"green"}
                    text={"Exportar reporte"}
                    size={"sm"}
                    borderRadius={"10px"}
                    onClick={() => {
                      ExportExcelClientes(clientesData);
                    }}
                  />
                )}
                {distribuidorData.length > 0 && (
                  <ButtonComponents
                    color={"green"}
                    text={"Exportar reporte"}
                    size={"sm"}
                    borderRadius={"10px"}
                    onClick={() => {
                      ExportExcelDistribuidor(distribuidorData);
                    }}
                  />
                )}
              </Box>

              {productosData.length > 0 && (
                <>
                  <Flex
                    mt={5}
                    justifyContent={"space-between"}
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      padding: "10px 50px",
                      bg: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(238,238,238,1) 100%)",
                    }}
                  >
                    <TextComponent
                      color={"gray"}
                      fontSize={"lg"}
                      fontWeight={"bolder"}
                      textAlign={"left"}
                      text="Estado"
                    />
                    <TextComponent
                      color={"gray"}
                      fontSize={"lg"}
                      fontWeight={"bolder"}
                      textAlign={"left"}
                      text="Productos vendidos"
                    />
                  </Flex>

                  {productosData.map((item: any) => (
                    <Flex
                      mt={5}
                      justifyContent={"space-between"}
                      sx={{
                        borderRadius: "10px",
                        border: "1px solid #ccc",
                        padding: "10px 50px",
                        bg: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(238,238,238,1) 100%)",
                      }}
                    >
                      <TextComponent
                        color={"gray"}
                        fontSize={"lg"}
                        fontWeight={"bolder"}
                        textAlign={"left"}
                        text={item.id}
                      />
                      <TextComponent
                        color={"gray"}
                        fontSize={"lg"}
                        fontWeight={"bolder"}
                        textAlign={"left"}
                        text={item.data.length}
                      />
                    </Flex>
                  ))}
                </>
              )}

              {clientesData.length > 0 && (
                <>
                  <Flex
                    mt={5}
                    justifyContent={"space-between"}
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      padding: "10px",
                      bg: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(238,238,238,1) 100%)",
                    }}
                  >
                    <TextComponent
                      color={"#000"}
                      fontSize={"lg"}
                      fontWeight={"bolder"}
                      textAlign={"left"}
                      text="Listado de clientes"
                    />
                  </Flex>

                  <Box bg={"#fff"} p={3} mt={2} borderRadius={10}>
                    <SelectComponents
                      options={estadosArray}
                      label="Estados"
                      placeholder="Seleccione una opcion"
                      value={estado}
                      onChange={(e) => setestado(e)}
                    />
                  </Box>

                  <Box height={"300px"} overflowY={"scroll"} mt={2}>
                    {clientesData
                      .filter((item: any) => {
                        if (estado?.value === "") {
                          return item;
                        } else if (
                          item.estado
                            .toLowerCase()
                            .includes(estado?.value.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item: any) => (
                        <Flex
                          justifyContent={"space-between"}
                          sx={{
                            border: "1px solid #ccc",
                            padding: "10px",
                            bg: "#eeeeee",
                          }}
                        >
                          <TextComponent
                            color={"#000"}
                            fontSize={"lg"}
                            fontWeight={"regular"}
                            textAlign={"left"}
                            text={item.nombre + " " + item.apellido}
                          />
                          <TextComponent
                            color={"#000"}
                            fontSize={"lg"}
                            fontWeight={"regular"}
                            textAlign={"left"}
                            text={item.estado}
                          />
                        </Flex>
                      ))}
                  </Box>
                </>
              )}

              {distribuidorData.length > 0 && (
                <>
                  <Flex
                    mt={5}
                    justifyContent={"space-between"}
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid #ccc",
                      padding: "10px",
                      bg: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(238,238,238,1) 100%)",
                    }}
                  >
                    <TextComponent
                      color={"#000"}
                      fontSize={"lg"}
                      fontWeight={"bolder"}
                      textAlign={"left"}
                      text="Listado de distribuidores"
                    />
                  </Flex>

                  <Box bg={"#fff"} p={3} mt={2} borderRadius={10}>
                    <SelectComponents
                      options={estadosArray}
                      label="Estados"
                      placeholder="Seleccione una opcion"
                      value={estado}
                      onChange={(e) => setestado(e)}
                    />
                  </Box>

                  <Box height={"300px"} overflowY={"scroll"} mt={2}>
                    {distribuidorData
                      .filter((item: any) => {
                        if (
                          estado?.value === "" ||
                          estado?.value === "Todos" ||
                          estado?.value === "TODOS" ||
                          estado?.value === null ||
                          estado?.value === undefined
                        ) {
                          return item;
                        } else if (
                          item.estado
                            .toLowerCase()
                            .includes(estado?.label?.toLowerCase())
                        ) {
                          return item;
                        }
                      })
                      .map((item: any) => (
                        <Flex
                          justifyContent={"space-between"}
                          sx={{
                            border: "1px solid #ccc",
                            padding: "10px",
                            bg: "#eeeeee",
                          }}
                        >
                          <div
                            style={{
                              width: "200px",
                            }}
                          >
                            <TextComponent
                              color={"#000"}
                              fontSize={"xs"}
                              fontWeight={"regular"}
                              textAlign={"left"}
                              text={item.sap}
                            />
                            <TextComponent
                              color={"#000"}
                              fontSize={"xs"}
                              fontWeight={"regular"}
                              textAlign={"left"}
                              text={item.razon_social}
                            />
                            <TextComponent
                              color={"#000"}
                              fontSize={"xs"}
                              fontWeight={"regular"}
                              textAlign={"left"}
                              text={item.nombre_comercial}
                            />
                          </div>
                          <TextComponent
                            color={"#000"}
                            fontSize={"lg"}
                            fontWeight={"regular"}
                            textAlign={"left"}
                            text={item.estado}
                          />
                          <TextComponent
                            color={"#000"}
                            fontSize={"lg"}
                            fontWeight={"regular"}
                            textAlign={"left"}
                            text={item.master ? "Master Pro" : "Master"}
                          />
                        </Flex>
                      ))}
                  </Box>
                </>
              )}
            </Box>

            <Box width={"50%"}>
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={zoom}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                >
                  {/* Child components, such as markers, info windows, etc. */}
                  <>
                    {productosData.map((item: any) => (
                      <>
                        <Marker
                          position={{
                            lat: quitarEspacioYParse(item.latitud),
                            lng: quitarEspacioYParse(item.longitud),
                          }}
                          clickable={true}
                          onClick={() => {
                            setcenter({
                              lat: quitarEspacioYParse(item.latitud),
                              lng: quitarEspacioYParse(item.longitud),
                            });
                            setzoom(10);
                          }}
                          visible={true}
                          options={{
                            icon: {
                              url: "https://cdn-icons-png.flaticon.com/512/5632/5632543.png",
                              scaledSize: new window.google.maps.Size(50, 50),
                            },
                          }}
                        />
                        <InfoWindow
                          position={{
                            lat: quitarEspacioYParse(item.latitud),
                            lng: quitarEspacioYParse(item.longitud),
                          }}
                        >
                          <div>
                            <h1>{item.label}</h1>
                            <p>{item.value}</p>
                          </div>
                        </InfoWindow>
                      </>
                    ))}

                    {obtenerTotalClientes()}
                    {distribuidorData.map((item: any) => (
                      <>
                        <Marker
                          position={{
                            lat: quitarEspacioYParse(item.latitud),
                            lng: quitarEspacioYParse(item.longitud),
                          }}
                          clickable={true}
                          onClick={() => {
                            setcenter({
                              lat: quitarEspacioYParse(item.latitud),
                              lng: quitarEspacioYParse(item.longitud),
                            });
                            setzoom(10);
                          }}
                          visible={true}
                          options={{
                            icon: {
                              url: "https://cdn-icons-png.flaticon.com/512/5632/5632543.png",
                              scaledSize: new window.google.maps.Size(50, 50),
                            },
                          }}
                        />
                        <InfoWindow
                          position={{
                            lat: quitarEspacioYParse(item.latitud),
                            lng: quitarEspacioYParse(item.longitud),
                          }}
                        >
                          <div>
                            <h1>{item.label}</h1>
                            <p>{item.value}</p>
                          </div>
                        </InfoWindow>
                      </>
                    ))}
                  </>
                </GoogleMap>
              ) : (
                <></>
              )}
            </Box>
          </Flex>
        </Box>
      </Sidebar>
    </>
  );
}
