/* eslint-disable import/no-anonymous-default-export */
const ENDPOINT = process.env.REACT_APP_URL;

async function ClienteAdd(data: any) {
  return await fetch(`${ENDPOINT}ClienteAdd`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function ClienteAddFile(data: any) {
  return await fetch(`${ENDPOINT}ClienteAdd`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: data,
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function GetTickets(id: any) {
  return await fetch(`${ENDPOINT}GetTickets/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function TicketFolioGet(folio:any) {
  return await fetch(`${ENDPOINT}TikectFolioGet/${folio}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function EncuestaAdd(data:any) {
  return await fetch(`${ENDPOINT}EncuestaAdd`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function TicketUpdate(data:any) {
  return await fetch(`${ENDPOINT}TicketUpdate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function TicketUpdateFile(data:any) {
  return await fetch(`${ENDPOINT}TicketUpdate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: data,
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function ActualizarStatus(data:any) {
  return await fetch(`${ENDPOINT}ActualizarStatus`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}


async function NotaAdd(data:any) {
  return await fetch(`${ENDPOINT}NotaAdd`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}


async function estadosGet() {
  return await fetch(`${ENDPOINT}estadosGet`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function municipiosGet(id:any) {
  return await fetch(`${ENDPOINT}municipiosGet/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function zonasGet(id:any) {
  return await fetch(`${ENDPOINT}zonasGet/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}


async function NotaGet(id:any) {
  return await fetch(`${ENDPOINT}NotaGet/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

async function sendEmail(data:any) {
  return await fetch(`${ENDPOINT}sendEmail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("_tokenDesign")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) throw new Error("Response is NOT ok");
    return res.json();
  });
}

export default {
  ClienteAddFile,
  ClienteAdd,
  GetTickets,
  TicketFolioGet,
  EncuestaAdd,
  TicketUpdate,
  TicketUpdateFile,
  ActualizarStatus,
  NotaAdd,
  NotaGet,
  estadosGet,
  municipiosGet,
  zonasGet,
  sendEmail
};
