import { Box, Flex, Image, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ButtonComponents from "../../../components/Form/Button";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import sources from "../../../services/sources";
import { DataTable } from "../../../components/HOC's/DataTableFuenteView";
import SkeletonComponent from "../../../components/Form/Skeleton";
import { createColumnHelper } from "@tanstack/react-table";
import { ColumnsFuente, Fuente, Tipo } from "../../../utils/Sources";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { useLocation } from "react-router-dom";
import { IoEarthOutline } from "react-icons/io5";

export default function PosiblesClientes() {
  const query = new URLSearchParams(useLocation().search);
  const { pathname }: any = useLocation();
  const [, , , Estado] = pathname.split("/");
  const toast = useToast();
  const [loader, setloader] = useState(false);
  const [columnVentas, setcolumnVentas] = useState<any>([]);
  const [dataVentas, setdataVentas] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const inicial = query.get("inicial");
  const final = query.get("final");
  const fuente = query.get("fuente");

  useEffect(() => {
    buscarReporte();
    return () => {};
  }, []);

  const buscarReporte = () => {
    setloader(true);
    sources
      .productView(inicial, final, fuente, Estado)
      .then((response: any) => {
        console.log(response);
        createArrayListadoProductos(response);
        setloader(false);
      });
  };

  const createArrayListadoProductos = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = ColumnsFuente().map(({ title, campo }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
      });
    });
    setcolumnVentas(columns);
    setdata(response);
    const array = response;
    const data = array.map((item: any, index: any) => {
      return {
        _id: (index + 1).toString(),
        imagen: item.imagen,
        modelo: item.modelo,
        cantidad: item.cantidad.toString(),
        precio: precio(parseFloat(item.precio)),
      };
    });
    setdataVentas(data);
  };

  const precio = (precio: any) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(precio);
  };

  const ExportExcel = (array: any) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(array);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `Productos-vendidos-en-${Estado}-por-${fuente}` + fileExtension
    );
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"} mb={3}>
            <Flex gridGap={3} alignItems={"center"}>
              <IoEarthOutline size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text={`Productos vendidos en ${Estado} por ${fuente}`}
                textTransform={"uppercase"}
              />
            </Flex>
            <Image
              src={"/stihl-logo.svg"}
              height={50}
            />
          </Box>

          {loader && <SkeletonComponent />}
          <Box overflowX="auto">
            {dataVentas.length > 0 && (
              <DataTable
                columns={columnVentas}
                data={dataVentas}
                edit=""
                onOpenUpdate={() => {}}
                setArray={[]}
                ExportExcel={ExportExcel}
              />
            )}
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
}
