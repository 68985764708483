import { Box, Flex, Image, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import ButtonComponents from "../../../components/Form/Button";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import SkeletonComponent from "../../../components/Form/Skeleton";
import { createColumnHelper } from "@tanstack/react-table";
import comparative from "../../../services/comparative";
import {
  options,
  generateData,
  Columns,
  generateDataComparativo,
} from "../../../utils/Comparative";
import CheckboxComponents from "../../../components/Form/Checkbox";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { FiDollarSign } from "react-icons/fi";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import ReactDatePicker from "react-datepicker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function PosiblesClientes() {
  const toast = useToast();
  const [form, setform] = useState<any>({
    desde: "",
    desde_ano: "",
    hasta: "",
    hasta_ano: "",
    opcion: "",
    tipo: "",
    tipo2: "",
    tipo3: "",
  });
  const [columns, setcolumns] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [arrayDataFuente, setarrayDataFuente] = useState<any>([]);
  const [arrayFuentes, setarrayFuentes] = useState<any>([]);
  const [arrayVentas, setarrayVentas] = useState<any>([]);
  const [arrayFirst, setarrayFirst] = useState<any>([]);
  const [loader, setloader] = useState(false);

  const buscarReporte = () => {
    setloader(true);

    let desde_mes = form.desde.getMonth() + 1;
    let hasta_mes = form.hasta.getMonth() + 1;
    const desde_ano = form.desde.getFullYear();
    const hasta_ano = form.hasta.getFullYear();

    if(desde_mes < 10) {
      desde_mes = "0" + desde_mes;
    }else{
      desde_mes = desde_mes + ""
    }

    if(hasta_mes < 10) {
      hasta_mes = "0" + hasta_mes;
    }else{
      hasta_mes = hasta_mes + ""
    }

    comparative
      .comparativeGet({
        desde: desde_mes,
        desde_ano: desde_ano,
        hasta: hasta_mes,
        hasta_ano: hasta_ano,
        opcion: "mes",
        tipo: form.tipo ? 1 : 0,
        tipo2: form.tipo2 ? 1 : 0,
        tipo3: form.tipo3 ? 1 : 0,
      })
      .then((response: any) => {
        const {
          clientes,
          clientes_fuente,
          posibles_clientes,
          posibles_clientes_fuente,
          ventas,
          ventas_fuente
        } = response;
        setloader(false);

        setarrayFirst(
          generateDataComparativo(
            clientes,
            posibles_clientes,
            ventas,
            form.tipo,
            form.tipo2,
            form.tipo3
          )
        );

        setarrayDataFuente(
          generateData(
            clientes_fuente,
            posibles_clientes_fuente,
            ventas_fuente ?? [],
            form.tipo,
            form.tipo2,
            form.tipo3
          )
        );

        const columnHelper = createColumnHelper<any>();
        const columns = Columns().map(({ title, campo }: any) => {
          return columnHelper.accessor(campo, {
            cell: (info: any) => info.getValue(),
            header: title ?? "",
          });
        });
        setcolumns(columns);
      })
      .catch(({ message }) => {
        setloader(false);
        toast({
          title: "Error",
          description: message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const ExportExcel = (array: any): any => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Comparativo" + fileExtension);
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiDollarSign size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Reporte comparativo"
                textTransform={"uppercase"}
              />
            </Flex>
            <Image src={"/stihl-logo.svg"} height={50} />
          </Box>

          <Flex>
            <Box
              display={"flex"}
              flexDirection={"column"}
              mt={5}
              px={10}
              borderRadius={20}
              width={"500px"}
            >
              <ReactDatePicker
                isClearable={true}
                selectsRange={false}
                startDate={form.desde}
                onChange={(date: any) => {
                  const desde_label = date.toLocaleString("default", {
                    month: "long",
                  });
                  const desde_ano_label = date.getFullYear();
                  setform({ ...form, desde: date, desde_label , desde_ano_label});
                }}
                selected={form.desde}
                dateFormat="yyyy-MM-dd"
                placeholderText="Desde"
                className="form-control w-100 border"
                showMonthYearPicker
                locale={"es"}
                fixedHeight
              />
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              mt={5}
              px={10}
              borderRadius={20}
              width={"500px"}
            >
              <ReactDatePicker
                isClearable={true}
                selectsRange={false}
                startDate={form.hasta}
                onChange={(date: any) => {
                  const hasta_label = date.toLocaleString("default", {
                    month: "long",
                  });
                  const hasta_ano_label = date.getFullYear();
                  setform({ ...form, hasta: date, hasta_label, hasta_ano_label });
                }}
                selected={form.hasta}
                dateFormat="yyyy-MM-dd"
                placeholderText="Hasta"
                className="form-control w-100 border"
                showMonthYearPicker
                locale={"es"}
                fixedHeight
              />
            </Box>

            <Flex gap={4}>
              <CheckboxComponents
                text="Clientes"
                value={form.tipo}
                onChange={(e) => setform({ ...form, tipo: e.target.checked })}
                defaultChecked={false}
              />
              <CheckboxComponents
                text="Posibles clientes"
                value={form.tipo2}
                onChange={(e) => setform({ ...form, tipo2: e.target.checked })}
                defaultChecked={false}
              />
              <CheckboxComponents
                text="Ventas"
                value={form.tipo3}
                onChange={(e) => setform({ ...form, tipo3: e.target.checked })}
                defaultChecked={false}
              />
            </Flex>

            <Box display={"flex"} mt={2} gap={3} justifyContent={"end"} ml={10}>
              <ButtonComponents
                text="Buscar"
                color="#FF7B05"
                onClick={buscarReporte}
                size="sm"
              />
              <ButtonComponents
                text="Exportar Excel"
                color="green"
                onClick={() => {
                  ExportExcel(data);
                }}
                size="sm"
              />
            </Box>
          </Flex>

          {loader && <SkeletonComponent />}

          <Box
            height={500}
            display={"flex"}
            justifyContent={"center"}
            mt={"5"}
            gap={5}
          >
            {arrayFirst?.labels?.length > 0 && (
              <Line
                options={options(
                  form.desde_label,
                  form.desde_ano_label,
                  form.hasta_label,
                  form.hasta_ano_label,
                  form.tipo && form.tipo2 && form.tipo3
                    ? "Clientes, posibles clientes y ventas"
                    : form.tipo && form.tipo2
                    ? "Clientes y posibles clientes"
                    : form.tipo && form.tipo3
                    ? "Clientes y ventas"
                    : form.tipo2 && form.tipo3
                    ? "Posibles clientes y ventas"
                    : form.tipo
                    ? "Clientes"
                    : form.tipo2
                    ? "Posibles clientes"
                    : form.tipo3
                    ? "Ventas"
                    : ""
                )}
                data={arrayFirst}
              />
            )}
          </Box>

          <Box
            height={500}
            display={"flex"}
            justifyContent={"center"}
            mt={"5"}
            gap={5}
          >
            {arrayDataFuente?.labels?.length > 0 && (
              <Line
                options={options(
                  form.desde_label,
                  form.desde_ano_label,
                  form.hasta_label,
                  form.hasta_ano_label,
                  form.tipo && form.tipo2 && form.tipo3
                    ? "Clientes, posibles clientes y ventas"
                    : form.tipo && form.tipo2
                    ? "Clientes y posibles clientes"
                    : form.tipo && form.tipo3
                    ? "Clientes y ventas"
                    : form.tipo2 && form.tipo3
                    ? "Posibles clientes y ventas"
                    : form.tipo
                    ? "Clientes"
                    : form.tipo2
                    ? "Posibles clientes"
                    : form.tipo3
                    ? "Ventas"
                    : ""
                )}
                data={arrayDataFuente}
              />
            )}
          </Box>

          {/*<Box
            height={500}
            display={"flex"}
            justifyContent={"center"}
            mt={"5"}
            gap={5}
          >
            {arrayFuentes?.labels?.length > 0 && (
              <Line
                options={options(
                  form.desde.label,
                  form.desde_ano.label,
                  form.hasta.label,
                  form.hasta_ano.label,
                  "posibles clientes"
                )}
                data={arrayFuentes}
              />
            )}
          </Box>*/}

          {/*<Box
            height={500}
            display={"flex"}
            justifyContent={"center"}
            mt={"5"}
            gap={5}
          >
            {arrayVentas?.labels?.length > 0 && (
              <Line
                options={options(
                  form.desde.label,
                  form.desde_ano.label,
                  form.hasta.label,
                  form.hasta_ano.label,
                  "ventas"
                )}
                data={arrayVentas}
              />
            )}
          </Box>*/}
        </Box>
      </Sidebar>
    </div>
  );
}
