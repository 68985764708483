import Select from "react-select";
import { FormControl, FormLabel, FormHelperText } from "@chakra-ui/react";

interface SelectInterface {
  options: Array<any>;
  value: any;
  onChange: (event: any) => void;
  placeholder: string;
  label: string;
}
export default function SelectComponents({
  options,
  value,
  onChange,
  placeholder,
  label,
}: SelectInterface) {
  return (
    <FormControl mt={2}>
      <FormLabel>{label}</FormLabel>
      <Select
        options={options}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        styles={{
          control: (base) => ({
            ...base,
            border: "1px solid #e2e8f0",
            boxShadow: "none",
            "&:hover": {
              border: "1px solid #e2e8f0",
            },
            borderRadius: "10px",
          }),
        }}
      />
      <FormHelperText></FormHelperText>
    </FormControl>
  );
}
