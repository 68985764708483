/* eslint-disable react-hooks/rules-of-hooks */
// add filter table
import * as React from "react";
import {
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
  TableContainer,
} from "@chakra-ui/react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useColorModeValue } from "@chakra-ui/react";
import ButtonComponents from "../Form/Button";
import SelectForm from "../Form/SelectForm";
import TextComponent from "../Form/Text";
import FormControlDefaultComponent from "../Form/FormControlDefault";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  edit: any;
  onOpenUpdate: any;
  onOpenCrear: any;
  setArray: any;
  ExportExcel?: any;
  size?: string;
  convertirCliente?: any;
  onOpenNotas?: any;
};

export function DataTable<Data extends object>({
  data,
  columns,
  edit,
  onOpenUpdate,
  onOpenCrear,
  setArray,
  ExportExcel = () => {},
  size = "",
  convertirCliente = () => {},
  onOpenNotas = () => {},
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <>
      <TableContainer>
        <Table variant="striped" colorScheme="gray" size="md">
          {size === "" ? (
            <Tbody>
              <Tr></Tr>
              <Tr>
                <Td
                  colSpan={edit ? columns.length + 1 : columns.length}
                  textAlign={"end"}
                  style={{ backgroundColor: "#fff !important" }}
                  className="bg-white"
                >
                  <ButtonComponents
                    text="Reporte general"
                    color="#3aa817"
                    onClick={() => ExportExcel(data)}
                    size="sm"
                  />
                </Td>
                <Td
                  colSpan={1}
                  textAlign={"end"}
                  style={{ backgroundColor: "#fff !important" }}
                  className="bg-white"
                >
                  <ButtonComponents
                    text="Crear Posible Cliente"
                    color="#000"
                    onClick={() => {
                      onOpenCrear();
                    }}
                    size="sm"
                  />
                </Td>
              </Tr>
            </Tbody>
          ) : null}
          <Tbody>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => {
                  return (
                    <Th
                      key={header.id}
                      color={useColorModeValue("black", "black")}
                    >
                      <div>
                        <Text
                          fontSize={14}
                          fontWeight={600}
                          textAlign={"center"}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Text>
                      </div>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Tbody>

          {table.getRowModel().rows.map((row: any) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell: any) => {
                const meta: any = cell.column.columnDef.meta;
                // obtener el value de factura
                if (cell.column.columnDef.header === "Acciones") {
                  return (
                    <>
                      <Td
                        key={cell.id}
                        isNumeric={meta?.isNumeric}
                        color={useColorModeValue("black", "black")}
                        fontSize={size === "" ? "20px" : "14px"}
                        padding={size === "" ? "15px" : "5px"}
                        textAlign={size === "" ? "center" : "center"}
                      >
                        <ButtonComponents
                          color={
                            row.original?.estatus == "Convertido a cliente"
                              ? "green"
                              : "orange"
                          }
                          size="xs"
                          text={
                            row.original?.estatus == "Convertido a cliente"
                              ? "Regresar a posible cliente"
                              : "Convertir a cliente"
                          }
                          onClick={() => {
                            convertirCliente(row.original);
                          }}
                        />
                      </Td>
                      <Td
                        key={cell.id}
                        isNumeric={meta?.isNumeric}
                        color={useColorModeValue("black", "black")}
                        fontSize={size === "" ? "20px" : "14px"}
                        padding={size === "" ? "15px" : "5px"}
                        textAlign={size === "" ? "center" : "center"}
                      >
                        <ButtonComponents
                          color={"#711c3d"}
                          size="xs"
                          text="Editar"
                          onClick={() => {
                            setArray(row.original);
                            onOpenUpdate();
                          }}
                        />
                      </Td>
                      <Td
                        key={cell.id}
                        isNumeric={meta?.isNumeric}
                        color={useColorModeValue("black", "black")}
                        fontSize={size === "" ? "20px" : "14px"}
                        padding={size === "" ? "15px" : "5px"}
                        textAlign={size === "" ? "center" : "center"}
                      >
                        <ButtonComponents
                          color={"#711c3d"}
                          size="xs"
                          text="Notas"
                          onClick={() => {
                            setArray(row.original);
                            onOpenNotas(row.original);
                          }}
                        />
                      </Td>
                    </>
                  );
                }
                return (
                  <Td
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    color={useColorModeValue("black", "black")}
                    fontSize={size === "" ? "20px" : "14px"}
                    padding={size === "" ? "15px" : "5px"}
                    textAlign={size === "" ? "center" : "center"}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Table>
      </TableContainer>
      <Box
        display={"flex"}
        justifyContent="end"
        alignItems={"center"}
        gap={5}
        mt={5}
      >
        <TextComponent
          text={`Pagina ${
            table.getState().pagination.pageIndex + 1
          } de ${table.getPageCount()}`}
          color="#000"
          fontSize={"15px"}
          fontWeight="600"
          textAlign={"center"}
        />

        {table.getCanPreviousPage() && (
          <ButtonComponents
            color={useColorModeValue("#f06e26", "#711c3d")}
            onClick={() => table.setPageIndex(0)}
            size="sm"
            text="<<"
            borderRadius="10px"
          />
        )}
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.previousPage()}
          size="sm"
          text="<"
          borderRadius="10px"
        />
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.nextPage()}
          size="sm"
          text=">"
          borderRadius="10px"
        />
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          size="sm"
          text=">>"
          borderRadius="10px"
        />

        <Box mt={5}>
          <FormControlDefaultComponent
            label=""
            helperText="Ir a la pagina"
            type="text"
            placeholder="Ir a la pagina"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </Box>

        <Box>
          <SelectForm
            helperText=""
            placeholder="Seleccione una opcion"
            label=""
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </SelectForm>
        </Box>
      </Box>
    </>
  );
}
