/* eslint-disable eqeqeq */
import { Box, Flex, Image, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import ButtonComponents from "../../../components/Form/Button";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import categorias from "../../../services/categorias";
import { DataTable } from "../../../components/HOC's/DataTable";
import SkeletonComponent from "../../../components/Form/Skeleton";
import { createColumnHelper } from "@tanstack/react-table";
import { ColumnsProducts } from "../../../utils/Categorias";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { FiDollarSign } from "react-icons/fi";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import breakdown from "../../../services/breakdown";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function PosiblesClientes() {
  const toast = useToast();
  const [form, setform] = useState({
    desde: null,
    hasta: null,
  });
  const [columns, setcolumns] = useState<any>([]);
  const [columnsEliminados, setcolumnsEliminados] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [dataEliminados, setdataEliminados] = useState<any>([]);
  const [loader, setloader] = useState(false);

  const buscarReporte = () => {
    if (!form.desde) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de inicio",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    if (!form.hasta) {
      toast({
        title: "Error",
        description: "Debe seleccionar una fecha de finalización",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    setloader(true);
    categorias
      .CategoryProductsGet({
        desde: new Date(form.desde).toISOString().split("T")[0],
        hasta: new Date(form.hasta).toISOString().split("T")[0],
      })
      .then((response: any) => {
        createArrayListadoProductos(response.category);
        setloader(false);
      });
  };

  const ExportExcel = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      delete res.cantidad_number;
      delete res.monto_number;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Categorias" + fileExtension);
  };


  const createArrayListadoProductos = (response: any) => {
    const columnHelper = createColumnHelper<any>();
    const columns = ColumnsProducts().map(({ title, campo }: any) => {
      return columnHelper.accessor(campo, {
        cell: (info: any) => info.getValue(),
        header: title ?? "",
      });
    });
    setcolumns(columns);
    setdata(response);
  };

  const subirFactura = (file: any, e: any) => {
    const formData = new FormData();
    formData.append("factura", file.target.files[0]);
    formData.append("id", e.id);

    breakdown.facturaAdd(formData).then((response: any) => {
      if (response.error) {
        toast({
          title: "Error",
          description: response.error,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Exito",
          description: response.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });

        buscarReporte()
      }
    });
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiDollarSign size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Reporte de categorias por equipo"
                textTransform={"uppercase"}
              />
            </Flex>
            <Image
              src={"/stihl-logo.svg"}
              height={50}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={10}
            mt={3}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              mt={5}
              px={10}
              borderRadius={20}
              width={"50%"}
              height={"100%"}
            >
              <DatePicker
                isClearable={true}
                selectsRange={true}
                startDate={form.desde}
                endDate={form.hasta}
                onChange={(date: any) => {
                  setform({ ...form, desde: date[0], hasta: date[1] });
                }}
                dateFormat="yyyy-MM-dd"
                placeholderText="Fecha de inicio - Fecha de fin"
                className="form-control w-100 border"
                showYearDropdown
                locale={"es"}
              />
            </Box>

            <Box mt={5}>
              <ButtonComponents
                text="Buscar"
                color="#FF7B05"
                onClick={buscarReporte}
                size="lg"
              />
            </Box>
          </Box>
          {loader && <SkeletonComponent />}
          <Box overflowX="auto" mt={4}>
            {data.length > 0 && (
              <>
                <TextComponent
                  color={"#000"}
                  fontSize={"2xl"}
                  fontWeight={"bold"}
                  textAlign={"start"}
                  text="Listado de categorias por equipo"
                />
                <Box mt={5}></Box>
                <DataTable
                  columns={columns}
                  data={data}
                  edit=""
                  onOpenUpdate={() => {}}
                  setArray={[]}
                  ExportExcel={ExportExcel}
                  size=""
                  subirFactura={subirFactura}
                  subirfactura_bool={true}
                />
              </>
            )}
          </Box>
        </Box>
      </Sidebar>
    </div>
  );
}
