import { Flex, Box, Image, useColorModeValue } from "@chakra-ui/react";
import Link from "../Form/Link";
import Text from "../Form/Text";
import { useNavigate } from "react-router-dom";

interface CardProps {
  image: string;
  title: string;
  url: string;
}

export default function Card({ image, title, url }: CardProps) {
  const navigation = useNavigate();
  const gotoUrl = () => {
    navigation("/crm/");
  };
  return (
    <Flex w="full" alignItems="flex-end" justifyContent="center">
      <Box rounded="lg" shadow="lg" position="relative">
        <Box paddingInline={30} paddingTop={10} paddingBottom={10}>
          <Text
            fontSize="6xl"
            text={title}
            textAlign="center"
            color="white"
            fontWeight="800"
          />

          <div
            style={{
              width: "100%",
              marginTop: "5px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link action={gotoUrl} color="orange" text={"Ingresa"} key={url} width={300} />
          </div>
        </Box>
      </Box>
    </Flex>
  );
}
