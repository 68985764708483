import { Box } from "@chakra-ui/react";
import Select from "react-select";

interface SelectInterface {
  options: Array<any>;
  value: any;
  onChange: (event: any) => void;
  placeholder: string;
  label: string;
  disabled?: boolean;
}
export default function SelectComponentsName({
  options,
  value,
  onChange,
  placeholder,
  disabled,
}: SelectInterface) {
  return (
    <Box width={"100%"}>
      <Select
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={options}
        isDisabled={disabled}
      ></Select>
    </Box>
  );
}
