/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Flex, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TextComponent from "../components/Form/Text";
import { Input, Button, useToast, Textarea } from "@chakra-ui/react";
import ticket from "../services/ticket";
import { useLocation } from "react-router-dom";

const preguntaCentroServicio = [
  {
    type: 1,
    pregunta: "¿Solucionamos todas las dudas técnicas de tu solicitud?",
  },
  {
    type: 2,
    pregunta:
      "¿Cómo consideras que fue la atención de nuestro centro de servicio?",
  },
  {
    type: 3,
    pregunta: "¿Qué tan probable es que recomiendes nuestros servicios?",
  },
  {
    type: 1,
    pregunta:
      "¿Estás interesado en recibir información sobre nuestros nuevos productos?",
  },
  {
    type: 4,
    pregunta:
      "¿Te gustaría hacer algún comentario o sugerencia para mejorar el servicio?",
  },
];

const preguntaDistribuidor = [
  {
    type: 1,
    pregunta:
      "¿Se te brindó la información necesaria acerca de un disitribuidor cercano?",
  },
  {
    type: 2,
    pregunta:
      "¿Cómo consideras que fue la atención con nuestro distribuidor autorizado?",
  },
  {
    type: 1,
    pregunta:
      "¿Lograste adquirir tu equipo/refacción/accesorio STIHL con un distribuidor autorizado?",
  },
  {
    type: 1,
    pregunta:
      "¿Estás interesado en recibir información sobre nuestros nuevos productos?",
  },
  {
    type: 4,
    pregunta:
      "¿Te gustaría hacer algún comentario o sugerencia para mejorar el servicio?",
  },
];

const preguntaRefacciones = [
  {
    type: 2,
    pregunta:
      "¿Cómo consideras que fue la atención brindada para adquirir tu equipo/refacción/accesorio STIHL?",
  },
  {
    type: 1,
    pregunta:
      "¿Lograste adquirir tu equipo/refacción/accesorio STIHL con un distribuidor autorizado?",
  },
  {
    type: 3,
    pregunta: "¿Qué tan probable es que recomiendes nuestros servicios?",
  },
  {
    type: 1,
    pregunta:
      "¿Estás interesado en recibir información relacinada al equipo/refacción/accesorio STIHL que adquiriste?",
  },
  {
    type: 4,
    pregunta:
      "¿Te gustaría hacer algún comentario o sugerencia para mejorar el servicio?",
  },
];

const preguntaReportes = [
  {
    type: 3,
    pregunta: "¿Qué tan satisfecho estás con el producto que adquiriste?",
  },
  {
    type: 3,
    pregunta: "¿Qué tan probable es que recomiendes nuestros servicios?",
  },
  {
    type: 2,
    pregunta: "¿Cómo consideras que fue la atención para resolver tu duda?",
  },
  {
    type: 1,
    pregunta:
      "¿Estás interesado en recibir información sobre nuestros nuevos productos?",
  },
  {
    type: 4,
    pregunta:
      "¿Te gustaría hacer algún comentario o sugerencia para mejorar el servicio?",
  },
];

export default function Ticket() {
  const location = useLocation();
  const folio = location.pathname.split("/")[2];

  const toast = useToast();
  const [loading, setloading] = useState(false);
  const [loader, setloader] = useState(false);

  const [form, setform] = useState<any>({
    id: "",
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    cp: "",
    estado: "",
    municipio: "",
    fuente: "",
    medio: "",
    prioridad: "",
    observaciones: "",
    tipo_ticket: "",
    otro_tipo_ticket: "",
    tipo_reporte: "",
    status: "",

    equipo: "",
    fecha_compra: "",
    pieza: "",
    num_serie: "",
    distribuidor: "",
    foto_video: null,
  });

  const [ticketData, setticketData] = useState<any>();
  const [satistaccion, setsatistaccion] = useState<any>({
    pregunta_0: "",
    pregunta_1: "",
    pregunta_2: "",
    pregunta_3: "",
    pregunta_4: "",

    pregunta_0_observaciones: "",
    pregunta_1_observaciones: "",
    pregunta_2_observaciones: "",
    pregunta_3_observaciones: "",
    pregunta_4_observaciones: "",

    success: true,
  });

  const cleanForm = () => {
    setform({
      id: "",
      nombre: "",
      apellido: "",
      correo: "",
      telefono: "",
      cp: "",
      estado: "",
      municipio: "",
      fuente: "",
      medio: "",
      prioridad: "",
      observaciones: "",
      tipo_ticket: "",
      otro_tipo_ticket: "",
      tipo_reporte: "",
      status: "",

      equipo: "",
      fecha_compra: "",
      pieza: "",
      num_serie: "",
      distribuidor: "",
      foto_video: null,
    });
  };

  const getInformacion = () => {
    setloader(true);
    ticket.TicketFolioGet(folio).then((res) => {
      if (res.ticket) {
        setticketData(res.ticket);

        if (res?.ticket?.datos) {
          const datos = JSON.parse(res?.ticket?.datos ?? []);
          setform({
            ...form,
            id: res.ticket.id,
            nombre: res.ticket.nombre,
            apellido: res.ticket.apellido,
            correo: res.ticket.correo,
            telefono: res.ticket.telefono,
            cp: res.ticket.cp,
            estado: res.ticket.estado,
            municipio: res.ticket.municipio,
            fuente: res.ticket.fuente,
            medio: res.ticket.medio,
            prioridad: res.ticket.prioridad,
            observaciones: res.ticket.observaciones,
            tipo_ticket: res.ticket.tipo_reporte,
            otro_tipo_ticket: res.ticket.otro_tipo_ticket,
            tipo_reporte: res.ticket.tipo_reporte,
            status: res.ticket.status,
            equipo: datos?.equipo ?? "",
            fecha_compra: datos?.fecha_compra ?? "",
            pieza: datos?.pieza ?? "",
            num_serie: datos?.serie ?? "",
            distribuidor: datos?.distribuidor ?? "",
            foto_video: datos?.foto_video ?? "",
          });
        } else {
          setform({
            id: res.ticket.id,
            nombre: res.ticket.nombre,
            apellido: res.ticket.apellido,
            correo: res.ticket.correo,
            telefono: res.ticket.telefono,
            cp: res.ticket.cp,
            estado: res.ticket.estado,
            municipio: res.ticket.municipio,
            fuente: res.ticket.fuente,
            medio: res.ticket.medio,
            prioridad: res.ticket.prioridad,
            observaciones: res.ticket.observaciones,
            tipo_ticket: res.ticket.tipo_reporte,
            otro_tipo_ticket: res.ticket.otro_tipo_ticket,
            tipo_reporte: res.ticket.tipo_reporte,
            status: res.ticket.status,
          });
        }

        if (res?.ticket?.respuestas) {
          const respuestas = JSON.parse(res?.ticket?.respuestas ?? []);
          setsatistaccion({
            pregunta_0: respuestas?.pregunta_0 ?? "",
            pregunta_1: respuestas?.pregunta_1 ?? "",
            pregunta_2: respuestas?.pregunta_2 ?? "",
            pregunta_3: respuestas?.pregunta_3 ?? "",
            pregunta_4: respuestas?.pregunta_4 ?? "",
            pregunta_0_observaciones:
              respuestas?.pregunta_0_observaciones ?? "",
            pregunta_1_observaciones:
              respuestas?.pregunta_1_observaciones ?? "",
            pregunta_2_observaciones:
              respuestas?.pregunta_2_observaciones ?? "",
            pregunta_3_observaciones:
              respuestas?.pregunta_3_observaciones ?? "",
            pregunta_4_observaciones:
              respuestas?.pregunta_4_observaciones ?? "",
            success:
              respuestas?.pregunta_0 &&
              respuestas?.pregunta_1 &&
              respuestas?.pregunta_2 &&
              respuestas?.pregunta_3
                ? false
                : true,
          });
        }
      }
    });
  };

  useEffect(() => {
    getInformacion();
    return () => {};
  }, []);

  const createTicket = () => {
    if (form.status === "Terminado") {
      if (
        satistaccion.pregunta_0 === "" ||
        satistaccion.pregunta_1 === "" ||
        satistaccion.pregunta_2 === "" ||
        satistaccion.pregunta_3 === ""
      ) {
        toast({
          title: "Error",
          description: "Debe responder todas las preguntas de satisfaccion",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      setloading(true);

      const arraySatisfaccion: any = {
        pregunta_0: satistaccion.pregunta_0,
        pregunta_1: satistaccion.pregunta_1,
        pregunta_2: satistaccion.pregunta_2,
        pregunta_3: satistaccion.pregunta_3,
        pregunta_4: satistaccion.pregunta_4,
        pregunta_0_observaciones: satistaccion.pregunta_0_observaciones,
        pregunta_1_observaciones: satistaccion.pregunta_1_observaciones,
        pregunta_2_observaciones: satistaccion.pregunta_2_observaciones,
        pregunta_3_observaciones: satistaccion.pregunta_3_observaciones,
        pregunta_4_observaciones: satistaccion.pregunta_4_observaciones,
      };

      const suma =
        parseFloat(satistaccion.pregunta_0) +
        parseFloat(satistaccion.pregunta_1) +
        parseFloat(satistaccion.pregunta_2) +
        parseFloat(satistaccion.pregunta_3);
      ticket
        .EncuestaAdd({
          id: ticketData.id,
          suma: suma / 4,
          respuestas: JSON.stringify(arraySatisfaccion),
        })
        .then((res) => {
          if (res.ticket) {
            setloading(false);
            toast({
              title: "Ticket creado",
              description:
                "Se ha enviado la encuesta de satisfaccion correctamente",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });

            getInformacion();
          }
        });
    }
  };

  const preguntas = (tipo: any) => {
    let array: any = [];
    if (tipo === "Centro de servicio") {
      array = preguntaCentroServicio;
    } else if (tipo === "Distribuidor cercano" || tipo === "Ser distribuidor") {
      array = preguntaDistribuidor;
    } else if (tipo === "Refacciones" || tipo === "Cotización") {
      array = preguntaRefacciones;
    } else if (tipo === "Reporte (Proceso de garantia)") {
      array = preguntaReportes;
    }
    return array.map((pregunta: any, index: any) => {
      if (pregunta.type === 1) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "30%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>

            <RadioGroup
              onChange={(e) => {
                setsatistaccion({
                  ...satistaccion,
                  ["pregunta_" + index]: e,
                });
              }}
              value={satistaccion["pregunta_" + index]}
            >
              <Stack direction="row">
                <Radio value="4">Si</Radio>
                <Radio value="3">No</Radio>

                {satistaccion["pregunta_" + index] === "3" && (
                  <Input
                    placeholder="Observaciones"
                    value={satistaccion["pregunta_" + index + "_observaciones"]}
                    onChange={(e) =>
                      setsatistaccion({
                        ...satistaccion,
                        ["pregunta_" + index + "_observaciones"]:
                          e.target.value,
                      })
                    }
                  />
                )}
              </Stack>
            </RadioGroup>
          </Flex>
        );
      } else if (pregunta.type === 2) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "30%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>

            <RadioGroup
              onChange={(e) => {
                setsatistaccion({
                  ...satistaccion,
                  ["pregunta_" + index]: e,
                });
              }}
              value={satistaccion["pregunta_" + index]}
            >
              <Stack direction="row">
                <Radio value="4">Muy buena</Radio>
                <Radio value="3">Buena</Radio>
                <Radio value="2">Regular</Radio>
                <Radio value="1">Mala</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        );
      } else if (pregunta.type === 3) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "30%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>

            <RadioGroup
              onChange={(e) => {
                setsatistaccion({
                  ...satistaccion,
                  ["pregunta_" + index]: e,
                });
              }}
              value={satistaccion["pregunta_" + index]}
            >
              <Stack direction="row">
                <Radio value="4">Muy probable</Radio>
                <Radio value="3">Probable</Radio>
                <Radio value="2">Poco probable</Radio>
                <Radio value="1">Nada probable</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        );
      } else if (pregunta.type === 4) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "50%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>
            <Textarea
              placeholder="Observaciones"
              value={satistaccion["pregunta_" + index] ?? ""}
              onChange={(e) =>
                setsatistaccion({
                  ...satistaccion,
                  ["pregunta_" + index]: e.target.value,
                })
              }
            />
          </Flex>
        );
      }
    });
  };

  const validarContestadas = () => {
    if (satistaccion.success) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box bg={"white"} p={10} borderRadius={20}>
      {!validarContestadas() && (
        <Box>
          <Box
            borderBottom={"1px solid #f06e26"}
            borderTop={"1px solid #f06e26"}
            mt={3}
          >
            <TextComponent
              color={"#f06e26"}
              fontSize={"3xl"}
              fontWeight={"bold"}
              textAlign={"center"}
              text={
                "Encuesta de satisfacción " + form.tipo_ticket + " " + folio
              }
              textTransform={"uppercase"}
            />
          </Box>

          <Box mt={5}>
            <TextComponent
              color={"black"}
              fontSize={"2xl"}
              fontWeight={"medium"}
              textAlign={"center"}
              text={"La encuesta de satisfacción ha sido contestada con exito"}
            />
          </Box>
        </Box>
      )}

      {form.status === "Terminado" && validarContestadas() && (
        <Box>
          <Box
            borderBottom={"1px solid #f06e26"}
            borderTop={"1px solid #f06e26"}
            mt={3}
          >
            <TextComponent
              color={"#f06e26"}
              fontSize={"3xl"}
              fontWeight={"bold"}
              textAlign={"center"}
              text={
                "Encuesta de satisfacción " + form.tipo_ticket + " " + folio
              }
              textTransform={"uppercase"}
            />
          </Box>

          <Flex>
            <Flex width={"100%"} flexDirection={"column"}>
              <Flex width={"100%"}>
                <Divider orientation="horizontal" mt={3} mb={3} />
              </Flex>
              {preguntas(form.tipo_ticket)}
            </Flex>
            <Box></Box>
          </Flex>
        </Box>
      )}

      {form.status === "Terminado" && validarContestadas() && (
        <Box display={"flex"} justifyContent={"flex-end"} mt={5} gap={2}>
          <Button
            colorScheme="red"
            onClick={() => {
              cleanForm();
            }}
            size="md"
          >
            Cancelar
          </Button>
          <Button
            colorScheme="green"
            size="md"
            isLoading={loading}
            onClick={() => {
              createTicket();
            }}
          >
            Enviar encuesta
          </Button>
        </Box>
      )}
    </Box>
  );
}
