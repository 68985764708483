/* eslint-disable import/no-anonymous-default-export */
function Columns() {
  return [
    { title: "Folio", campo: "folio", buscador: true },
    { title: "Fecha", campo: "fecha", buscador: true },
    { title: "Tipo reporte", campo: "tipo_reporte", buscador: false },
    { title: "Responsable contacto STIHL", campo: "name", buscador: false },
    { title: "Responsable STIHL", campo: "name2", buscador: false },
    { title: "Zona", campo: "zona", buscador: true },
    { title: "Prioridad", campo: "prioridad" , buscador: false},
    { title: "Estatus", campo: "status", buscador: true },
  ];
}

export default {
  Columns,
};
