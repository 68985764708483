/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Textarea,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import customers from "../../../services/customers";
import dealers from "../../../services/dealers";
import product_s from "../../../services/productos";
import { DataTable } from "../../../components/HOC's/DataTableOrdenes";
import OrdersArray from "../../../utils/Orders";
import { createColumnHelper } from "@tanstack/react-table";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { FiUsers } from "react-icons/fi";
import orders from "../../../services/orders";
import Toast from "../../../utils/Toast";
import ButtonComponents from "../../../components/Form/Button";
import ReactDatePicker from "react-datepicker";
import SelectComponentsName from "../../../components/Form/SelectName";
import { getValues } from "../../../reducers/loginReducer";
import { useSelector } from "react-redux";

export default function PosiblesClientes() {
  const [columns, setcolumns] = useState<any>([]);
  const [data, setdata] = useState<any>([]);
  const [estados, setestados] = useState<any>([]);
  const [edit, setedit] = useState(false);
  const [openFile, setopenFile] = useState(false);
  const [array, setArray] = useState<any>({
    distribuidor_id: "",
    cliente_id: "",
    titulo: "",
    subtotal: "",
    total: "",
    fecha_venta: "",
    user_id: "",
    productos: [],
  });
  const [start_date, setstart_date] = useState<any>("");
  const [end_date, setend_date] = useState<any>("");
  const [clientes, setclientes] = useState<any>([]);
  const [distribuidores, setdistribuidores] = useState<any>([]);
  const [productos, setproductos] = useState<any>([]);
  const { id } = useSelector(getValues);

  useEffect(() => {
    customers.getCustomers().then((res) => {
      const newArray = res.map((res: any) => {
        return {
          label:
            res.nombre +
            " " +
            (res?.apellido ?? "") +
            " " +
            (res.telefono ?? "") +
            " " +
            (res?.correo ?? ""),
          value: res.id,
        };
      });
      setclientes(newArray);
    });
    dealers.dealers().then((res) => {
      const newArray = res?.distribuidor.map((res: any) => {
        return {
          label: res.nombre_comercial + " " + res.numero,
          value: res.id,
        };
      });
      setdistribuidores(newArray);
    });

    product_s.productos().then((res) => {
      const newArray = res.map((res: any) => {
        return {
          ...res,
          label: res.modelo,
          value: res.id,
        };
      });
      setproductos(newArray);
    });
  }, []);

  const getInformacion = async () => {
    const informacion = await orders.orderGet(
      new Date(start_date).toISOString(),
      new Date(end_date).toISOString()
    );

    const columnHelper = createColumnHelper<any>();
    const columns = OrdersArray.Columns().map(
      ({ title, campo, buscador }: any) => {
        return columnHelper.accessor(campo, {
          cell: (info: any) => info.getValue(),
          header: title ?? "",
          enableColumnFilter: buscador ?? false,
        });
      }
    );

    setcolumns(columns);
    // add new campo to array
    const newArray = informacion?.reverse().map((res: any) => {
      return {
        ...res,
        folio: res?.folio ?? "No proporcionado",
        fecha: res?.fecha ?? "No proporcionado",
        titulo: res?.titulo ?? "No proporcionado",
        total: res?.total ?? "No proporcionado",
      };
    });
    setdata(newArray);
  };

  const ExportExcel = (array: any) => {
    const newArray = array.map((res: any) => {
      delete res.created_at;
      delete res.updated_at;
      delete res.id_user_zoho;
      delete res.longitud;
      delete res.latitud;
      delete res.zonas_id;
      delete res.id_zonas;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Reporte-general" + fileExtension);
  };

  const onOpenUpdate = () => {
    setopenFile(true);
  };

  const orderAddFactura = async (array: any) => {
    const formData = new FormData();
    formData.append("factura", array.factura);
    formData.append("id", array.id);
    const informacion = await orders.orderAddFactura(formData);
    if (informacion.guardado) {
      Toast.fire({
        icon: "success",
        title: "Guardado",
      });
      setArray({
        distribuidor_id: "",
        cliente_id: "",
        titulo: "",
        subtotal: "",
        total: "",
        productos: [],
      });
      setopenFile(false);
      getInformacion();
    } else {
      Toast.fire({
        icon: "error",
        title: "Error al actualizar",
      });
    }
  };

  const orderAdd = async (array: any) => {
    if (
      array.distribuidor_id === "" ||
      array.cliente_id === "" ||
      array.titulo === "" ||
      array.productos.length === 0
    ) {
      Toast.fire({
        icon: "error",
        title: "Faltan campos por llenar",
      });
      return;
    }

    const informacion = await orders.orderAdd({
      distribuidor_id: array.distribuidor_id?.value,
      cliente_id: array.cliente_id?.value,
      titulo: array.titulo,
      subtotal: array?.productos.reduce(
        (acc: any, res: any) => acc + parseFloat(res.precio),
        0
      ),
      fecha_venta: array.fecha_venta,
      user_id: id,
      total: array?.productos.reduce(
        (acc: any, res: any) => acc + parseFloat(res.total),
        0
      ),
      observaciones: array.observaciones,
      productos: array.productos.map((res: any) => {
        return {
          id_producto: res.id_producto,
          cantidad: parseFloat(res.cantidad),
          precio: parseFloat(res.precio),
          total: parseFloat(res.total),
          serial: res.serial,
        };
      }),
    });
    if (informacion.guardado) {
      Toast.fire({
        icon: "success",
        title: "Guardado",
      });
      setArray({
        distribuidor_id: "",
        cliente_id: "",
        titulo: "",
        subtotal: "",
        total: "",
        productos: [],
      });
      setedit(false);
      getInformacion();
    } else {
      Toast.fire({
        icon: "error",
        title: "Error al actualizar",
      });
    }
  };

  return (
    <div>
      <Nav />
      <Sidebar>
        {edit && (
          <ModalEdit
            isOpen={edit}
            onClose={() => setedit(false)}
            array={array}
            setArray={setArray}
            clientes={clientes}
            distribuidores={distribuidores}
            productos={productos}
            title={"Orden de compra"}
            updateCliente={array.id ? orderAddFactura : orderAdd}
          />
        )}
        {openFile && (
          <ModalFile
            isOpen={openFile}
            onClose={() => setopenFile(false)}
            array={array}
            setArray={setArray}
            title={"Factura"}
            orderAddFactura={orderAddFactura}
          />
        )}
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiUsers size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text="Ordenes de compra"
                textTransform={"uppercase"}
              />
            </Flex>
            <Image src={"/stihl-logo.svg"} height={50} />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            mt={5}
            bg={"#eeeeee"}
            px={10}
            borderRadius={20}
            width={"50%"}
            height={"100%"}
          >
            <Box mt={2} />
            <ReactDatePicker
              isClearable={true}
              selectsRange={true}
              startDate={start_date}
              endDate={end_date}
              onChange={(date: any) => {
                setstart_date(date[0]);
                setend_date(date[1]);
              }}
              dateFormat="yyyy-MM-dd"
              placeholderText="Fecha de inicio - Fecha de finalización"
              className="form-control w-100"
              showYearDropdown
              locale={"es"}
            />

            <Flex justifyContent={"flex-end"} gap={4} pb={5}>
              <Box mt={5}>
                <ButtonComponents
                  text="Buscar"
                  color="#FF7B05"
                  onClick={getInformacion}
                  size="md"
                />
              </Box>
            </Flex>
          </Box>

          <Flex justifyContent={"flex-end"} gap={4} pb={5}>
            <Box mt={5}>
              <ButtonComponents
                text="Agregar orden de compra"
                color="#FF7B05"
                onClick={() => {
                  setArray({
                    distribuidor_id: "",
                    cliente_id: "",
                    titulo: "",
                    subtotal: "",
                    total: "",
                    productos: [],
                  });
                  setedit(true);
                }}
                size="md"
              />
            </Box>
          </Flex>

          {data.length > 0 && (
            <Box overflowX="auto" mt={4}>
              <DataTable
                columns={columns}
                data={data}
                edit="false"
                onOpenUpdate={onOpenUpdate}
                setArray={setArray}
                ExportExcel={ExportExcel}
                estados={estados}
              />
            </Box>
          )}
        </Box>
      </Sidebar>
    </div>
  );
}

const ModalEdit = ({
  isOpen,
  onClose,
  array,
  updateCliente,
  clientes,
  distribuidores,
  setArray,
  title,
  productos,
}: any) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
      <ModalOverlay />
      <ModalContent>
        <Box p={5}>
          <Box p={5}>
            <TextComponent
              color={"#f06e26"}
              fontSize={"3xl"}
              fontWeight={"bolder"}
              textAlign={"center"}
              text={title}
              textTransform={"uppercase"}
            />
          </Box>
          {/* form array */}
          <Box>
            <Input
              placeholder="Titulo"
              defaultValue={array.titulo}
              mb={2}
              type="text"
              onChange={(e) => {
                setArray({ ...array, titulo: e.target.value });
              }}
            />
            <Textarea
              placeholder="Observaciones"
              defaultValue={array.observaciones}
              mb={2}
              onChange={(e) => {
                setArray({ ...array, observaciones: e.target.value });
              }}
            />
            <p style={{ fontSize: 12, margin: 3 }}>Fecha de venta</p>
            <Input
              placeholder="Fecha de venta"
              defaultValue={array.fecha_venta}
              mb={2}
              type="date"
              onChange={(e) => {
                setArray({ ...array, fecha_venta: e.target.value });
              }}
            />
            <SelectComponentsName
              options={distribuidores}
              value={array.distribuidor_id}
              onChange={(e) => {
                setArray({ ...array, distribuidor_id: e });
              }}
              placeholder="Distribuidor"
              label="Distribuidor"
            />
            <Box mb={2} />
            <SelectComponentsName
              options={clientes}
              value={array.cliente_id}
              onChange={(e) => {
                setArray({ ...array, cliente_id: e });
              }}
              placeholder="Cliente"
              label="Cliente"
            />
            <Box mb={2} />
            <SelectComponentsName
              options={productos}
              onChange={(e) => {
                setArray({
                  ...array,
                  productos: [
                    ...array.productos,
                    {
                      label: e.label,
                      id_producto: e.value,
                      serial: "",
                      cantidad: 0,
                      precio: 0,
                      total: 0,
                    },
                  ],
                });
              }}
              placeholder="Productos"
              label="Productos"
              value={""}
            />
          </Box>

          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Producto</Th>
                  <Th>Serial</Th>
                  <Th>Cantidad</Th>
                  <Th>Precio</Th>
                  <Th>Total</Th>
                  <Th>Eliminar</Th>
                </Tr>
              </Thead>
              <Tbody>
                {array.productos.map((res: any, index: any) => {
                  return (
                    <Tr key={index}>
                      <Td>{res.label}</Td>
                      <Td>
                        <Input
                          type="text"
                          onChange={(e: any) => {
                            const newArray = array.productos.map(
                              (res_: any) => {
                                if (res.id_producto === res_.id_producto) {
                                  return {
                                    ...res_,
                                    serial: e.target.value,
                                  };
                                }
                                return res_;
                              }
                            );
                            setArray({
                              ...array,
                              productos: newArray,
                            });
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          onChange={(e: any) => {
                            const newArray = array.productos.map(
                              (res_: any) => {
                                if (res.id_producto === res_.id_producto) {
                                  return {
                                    ...res_,
                                    cantidad: e.target.value,
                                    total: e.target.value * res_.precio,
                                  };
                                }
                                return res_;
                              }
                            );
                            setArray({
                              ...array,
                              productos: newArray,
                            });
                          }}
                        />
                      </Td>
                      <Td>
                        <Input
                          type="number"
                          onChange={(e: any) => {
                            const newArray = array.productos.map(
                              (res_: any) => {
                                if (res.id_producto === res_.id_producto) {
                                  return {
                                    ...res_,
                                    precio: e.target.value,
                                    total: e.target.value * res_.cantidad,
                                  };
                                }
                                return res_;
                              }
                            );
                            setArray({
                              ...array,
                              productos: newArray,
                            });
                          }}
                        />
                      </Td>
                      <Td textAlign={"center"}>{res.cantidad * res.precio}</Td>
                      <Td
                        onClick={() => {
                          const newArray = array.productos.filter(
                            (res: any) => res.id_producto !== res.id_producto
                          );
                          setArray({
                            ...array,
                            productos: newArray,
                          });
                        }}
                        style={{ cursor: "pointer", color: "red" }}
                      >
                        Eliminar
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th>Total</Th>
                  <Th></Th>
                  <Th textAlign={"center"}>
                    {array.productos.reduce(
                      (acc: any, res: any) => acc + parseFloat(res.cantidad),
                      0
                    )}
                  </Th>
                  <Th textAlign={"center"}>
                    {array.productos.reduce(
                      (acc: any, res: any) => acc + parseFloat(res.precio),
                      0
                    )}
                  </Th>
                  <Th textAlign={"center"}>
                    {array.productos.reduce(
                      (acc: any, res: any) => acc + parseFloat(res.total),
                      0
                    )}
                  </Th>
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </Box>
        <ModalFooter>
          <Box
            bg={"#f06e26"}
            borderRadius={10}
            color={"white"}
            cursor={"pointer"}
            p={3}
            w={"50%"}
            _hover={{ bg: "#f06e26" }}
            textAlign={"center"}
            onClick={() => {
              updateCliente(array);
            }}
          >
            Guardar
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ModalFile = ({
  isOpen,
  onClose,
  title,
  array,
  setArray,
  orderAddFactura,
}: any) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <Box p={5}>
          <Box p={5}>
            <TextComponent
              color={"#f06e26"}
              fontSize={"3xl"}
              fontWeight={"bolder"}
              textAlign={"center"}
              text={title}
              textTransform={"uppercase"}
            />
          </Box>
          {/* form array */}
          <Box>
            <Input
              placeholder="Factura"
              defaultValue={array.factura}
              mb={2}
              type="file"
              accept=".pdf"
              onChange={(e: any) => {
                setArray({ ...array, factura: e.target.files[0] });
              }}
            />
          </Box>
        </Box>
        <ModalFooter>
          <Box
            bg={"#f06e26"}
            borderRadius={10}
            color={"white"}
            cursor={"pointer"}
            p={3}
            w={"50%"}
            _hover={{ bg: "#f06e26" }}
            textAlign={"center"}
            onClick={() => {
              orderAddFactura(array);
            }}
          >
            Guardar
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
