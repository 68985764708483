/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Input } from "@chakra-ui/react";

export default function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  size = "",
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  size?: string;
  [key: string]: any;
}) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Input
      {...props}
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      bg={"white"}
      border={"1px solid #e2e8f0"}
      borderRadius={"4px"}
      _focus={{
        border: "1px solid #f06e26",
      }}
      w={size === "" ? "100%" : "100px"}
    />
  );
}
