import React from "react";
import { Flex, Box, Image } from "@chakra-ui/react";
import Nav from "../../components/UI/Navbar";
import Card from "../../components/UI/Card";
import Image_CRM from "../../images/STIHL_Menschen_D_0192.png";

export default function Index() {
  return (
    <div>
      <Nav />
      <div style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: 1,
        backgroundColor: "rgba(0,0,0,0.5)"
      }}>
        <Box
          position={"relative"}
          top={"50%"}
          left={"50%"}
          transform={"translate(-50%, -50%)"}
          zIndex={999}
        >
          <Card title="EDICIÓN CRM" image={Image_CRM} url="/crm" />
        </Box>
      </div>
      <Image
        objectFit="cover"
        src={Image_CRM}
        alt={`Picture of EDICIÓN CRM`}
        roundedTop="lg"
        width={"100%"}
        height={"100vh"}
        backgroundPosition={"top"}
        position={"relative"}
      />
    </div>
  );
}
