/* eslint-disable import/no-anonymous-default-export */
function Columns() {
  return [
    { title: "Nombre", campo: "nombre" },
    { title: "Estatus", campo: "status" },
  ];
}

export default {
  Columns,
};
