/* eslint-disable import/no-anonymous-default-export */
function Columns() {
  return [
    { title: "Nombre", campo: "nombre_completo" },
    { title: "Direccion", campo: "direccion_completa" },
    { title: "Distancia", campo: "distancia" },
  ];
}

function Columns2() {
  return [
    { title: "Nombre", campo: "nombre_completo" },
    { title: "Direccion", campo: "direccion_completa" },
  ];
}

export default {
  Columns,
  Columns2
};
