import React from "react";
import { Button } from "@chakra-ui/react";

interface LinkProps {
  color: any;
  text: string;
  action: any;
  [x: string]: any;
}

export default function Link({ color, text, action, ...rest }: LinkProps) {
  return (
    <Button onClick={action} colorScheme={color} {...rest}>
      {text}
    </Button>
  );
}
