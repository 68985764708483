/* eslint-disable import/no-anonymous-default-export */
function Columns() {
  return [
    { title: "Nombre", campo: "nombre", buscador: false },
    { title: "Apellido", campo: "apellido", buscador: false },
    { title: "Correo", campo: "correo", buscador: false },
    { title: "Telefono", campo: "telefono", buscador: false },
    { title: "Fecha", campo: "fecha_creacion", buscador: true },
    { title: "Cliente/Posible", campo: "tipo_cliente", buscador: true },
    { title: "Ciudad", campo: "ciudad", buscador: false },
    { title: "Estado", campo: "estado", buscador: true },
  ];
}

export default {
  Columns,
};
