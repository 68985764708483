import {
  Box,
  Flex,
  Image,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import Image_CRM from "../../images/contacto_2019.png";
import { FaUser } from "react-icons/fa";
import TextComponent from "../Form/Text";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getValues, setCleanValues } from "../../reducers/loginReducer";

export default function Nav() {
  const { username } = useSelector(getValues);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeSesion = () => {
    dispatch(setCleanValues());
    navigate("/login");
  };
  return (
    <>
      <Box
        bg={useColorModeValue("#f06e26", "gray.900")}
        px={4}
        position="fixed"
        w="100%"
        zIndex={1}
        marginBottom={4}
      >
        <Flex
          h={16}
          alignItems={"center"}
          justifyContent={"end"}
          paddingInline={20}
        >
          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={7}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Flex alignItems={"center"} gap={4}>
                    <FaUser scale={1} color={"#fff"} />
                    <TextComponent
                      color={"#fff"}
                      fontSize="lg"
                      fontWeight={600}
                      text={username}
                      textAlign={"center"}
                    />
                  </Flex>
                </MenuButton>
                <MenuList alignItems={"center"}>
                  <MenuDivider />
                  <TextComponent
                    color={"#000"}
                    fontSize="lg"
                    fontWeight={600}
                    text={username}
                    textAlign={"center"}
                  />
                  <MenuDivider />
                  <MenuItem onClick={closeSesion}>Cerrar sesión</MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
