/* eslint-disable import/no-anonymous-default-export */
const ENDPOINT = process.env.REACT_APP_URL;

export async function Login(data: any) {
  return fetch(`${ENDPOINT}auth/login`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    method: "post",
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        return {
          error: true,
        };
      }
    })
    .then((response) => {
      return response;
    });
}
