import { Button } from "@chakra-ui/react";

interface ButtonInterface {
  color: string;
  size: string;
  text: string;
  borderRadius?: string;
  loading?: boolean;
  onClick: () => void;
}
export default function ButtonComponents({
  color,
  size,
  text,
  onClick,
  loading = false,
  borderRadius = "20px",
  ...rest
}: ButtonInterface) {
  return (
    <Button
      _hover={{ bg: color }}
      bg={color}
      color={"white"}
      size={size}
      onClick={onClick}
      borderRadius={borderRadius}
      textTransform={"uppercase"}
      fontSize={"14px"}
      isLoading={loading}
      {...rest}
    >
      {text}
    </Button>
  );
}
