/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Image,
  FormControl,
  InputRightElement,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { Login as LoginService } from "../../services/login";
import { useDispatch } from "react-redux";
import { setAllValues } from "../../reducers/loginReducer";
import Image_CRM from "../../images/contacto_2019.png";
import Toast from "../../utils/Toast";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

export default function Index() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [form, setform] = useState({
    email: "",
    password: "",
  });
  const [loading, setloading] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);

  const navigate = useNavigate();

  const Login = () => {
    setloading(true);
    if (form.email === "" || form.password === "") {
      // swal alert
      setloading(false);
      Toast.fire({
        icon: "error",
        text: "Todos los campos son obligatorios!",
      });
    } else {
      LoginService({
        email: form.email,
        password: form.password,
      })
        .then((res: any) => {
          setloading(false);
          if (!res.error) {
            const newdata = res
            newdata.id = res.user_id
            dispatch(setAllValues(newdata));
            navigate("/");
          } else {
            Toast.fire({
              icon: "error",
              text: "Usuario o contraseña incorrectos!",
            });
          }
        })
        .catch((err: any) => {
          setloading(false);
          Toast.fire({
            icon: "error",
            text: err.message,
          });
        });
    }
  };

  return (
    <>
      <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          flexDir="column"
          mb="2"
          justifyContent="center"
          alignItems="center"
        >
          <Box minW={{ base: "90%", md: "468px" }} mt={10}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                Login();
              }}
            >
              <Stack
                spacing={4}
                p="1rem"
                backgroundColor="whiteAlpha.900"
                boxShadow="md"
              >
                <Stack textAlign="center" justifyContent={"center"} display={"flex"} flexDirection={"unset"}>
                  <Image src={Image_CRM} roundedTop="lg" width={200} />
                </Stack>
                <Heading textAlign={"center"} color="orange.400">
                  Inicio de sesión
                </Heading>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaUserAlt color="gray.300" />}
                    />
                    <Input
                      type="email"
                      placeholder="Correo electronico"
                      value={form.email}
                      onChange={(e) =>
                        setform({ ...form, email: e.target.value })
                      }
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<CFaLock color="gray.300" />}
                    />
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Contraseña"
                      value={form.password}
                      onChange={(e) =>
                        setform({ ...form, password: e.target.value })
                      }
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                        {showPassword ? "Ocultar" : "Mostrar"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Button
                  borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="orange"
                  width="full"
                  isLoading={loading}
                >
                  Iniciar sesión
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Flex>
    </>
  );
}
