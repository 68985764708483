import { createSlice } from "@reduxjs/toolkit";
import validateToken from "../utils/validateToken";

export const loginReducer = createSlice({
  name: "loader",
  initialState: {
    id: localStorage.getItem("_tokenNewDesign")
      ? validateToken.decode(localStorage.getItem("_tokenNewDesign"))?.id
      : "",
    username: localStorage.getItem("_tokenNewDesign")
      ? validateToken.decode(localStorage.getItem("_tokenNewDesign"))?.username
      : "",
    email: localStorage.getItem("_tokenNewDesign")
      ? validateToken.decode(localStorage.getItem("_tokenNewDesign"))?.email
      : "",
    access_token: localStorage.getItem("_tokenNewDesign")
      ? validateToken.decode(localStorage.getItem("_tokenNewDesign"))
          ?.access_token
      : "",
      rol: localStorage.getItem("_tokenNewDesign")
      ? validateToken.decode(localStorage.getItem("_tokenNewDesign"))
          ?.rol
      : "",
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setToken: (state, action) => {
      state.access_token = action.payload;
    },
    setAllValues: (state, action) => {
      state.id = action.payload.user_id;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.access_token = action.payload.access_token;
      state.rol = action.payload.rol;
      localStorage.setItem("_tokenDesign", action.payload.access_token)
      localStorage.setItem(
        "_tokenNewDesign",
        validateToken.encode(action.payload)
      );
    },

    setCleanValues: (state) => {
      state.id = "";
      state.username = "";
      state.email = "";
      state.access_token = "";
      state.rol = "";
      localStorage.removeItem("_tokenNewDesign");
    },
  },
});

export const { setUsername, setEmail, setToken, setAllValues, setCleanValues } =
  loginReducer.actions;

export const getValues = (state) => state.login;

export default loginReducer.reducer;
