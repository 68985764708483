/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TextComponent from "../../../components/Form/Text";
import Nav from "../../../components/UI/Navbar";
import Sidebar from "../../../components/UI/Sidebar";
import { Input, Select, Button, useToast, Textarea } from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import ticket from "../../../services/ticket";
import { getValues } from "../../../reducers/loginReducer";
import { useSelector } from "react-redux";
import SelectComponentsName from "../../../components/Form/SelectName";
import geomark from "../../../services/geomark";
import dealers from "../../../services/dealers";

const preguntaCentroServicio = [
  {
    type: 1,
    pregunta: "¿Solucionamos todas las dudas técnicas de tu solicitud?",
  },
  {
    type: 2,
    pregunta:
      "¿Cómo consideras que fue la atención de nuestro Centro de servicio autorizado más cercano?",
  },
  {
    type: 3,
    pregunta: "¿Qué tan probable es que recomiendes nuestros servicios?",
  },
  {
    type: 1,
    pregunta:
      "¿Estás interesado en recibir información sobre nuestros nuevos productos?",
  },
  {
    type: 4,
    pregunta:
      "¿Te gustaría hacer algún comentario o sugerencia para mejorar el servicio?",
  },
];

const preguntaRefacciones = [
  {
    type: 2,
    pregunta:
      "¿Cómo consideras que fue la atención brindada para adquirir tu equipo/refacción/accesorio STIHL?",
  },
  {
    type: 1,
    pregunta:
      "¿Lograste adquirir tu equipo/refacción/accesorio STIHL con un distribuidor autorizado?",
  },
  {
    type: 3,
    pregunta: "¿Qué tan probable es que recomiendes nuestros servicios?",
  },
  {
    type: 1,
    pregunta:
      "¿Estás interesado en recibir información relacinada al equipo/refacción/accesorio STIHL que adquiriste?",
  },
  {
    type: 4,
    pregunta:
      "¿Te gustaría hacer algún comentario o sugerencia para mejorar el servicio?",
  },
];

const preguntaReportes = [
  {
    type: 3,
    pregunta: "¿Qué tan satisfecho estás con el producto que adquiriste?",
  },
  {
    type: 3,
    pregunta: "¿Qué tan probable es que recomiendes nuestros servicios?",
  },
  {
    type: 2,
    pregunta: "¿Cómo consideras que fue la atención para resolver tu duda?",
  },
  {
    type: 1,
    pregunta:
      "¿Estás interesado en recibir información sobre nuestros nuevos productos?",
  },
  {
    type: 4,
    pregunta:
      "¿Te gustaría hacer algún comentario o sugerencia para mejorar el servicio?",
  },
];

export default function Ticket() {
  const location = useLocation();
  const folio = location.pathname.split("/")[3];
  const { id } = useSelector(getValues);

  const toast = useToast();
  const [loading, setloading] = useState(false);
  const [loader, setloader] = useState(false);
  const [estadosArray, setestadosArray] = useState([]);
  const [municipiosArray, setmunicipiosArray] = useState([]);
  const [productosArrayVenta, setproductosArrayVenta] = useState([]);
  const [ventaArray, setventaArray] = useState<any>({});

  const [form, setform] = useState<any>({
    id: "",
    nombre: "",
    apellido: "",
    correo: "",
    telefono: "",
    cp: "",
    estado: "",
    municipio: "",
    fuente: "",
    medio: "",
    prioridad: "",
    observaciones: "",
    tipo_ticket: "",
    otro_tipo_ticket: "",
    tipo_reporte: "",
    status: "",
    nota_final: "",
    pregunta: "",

    equipo: "",
    fecha_compra: "",
    pieza: "",
    num_serie: "",
    distribuidor: "",
    foto_video: null,
    arreglo_imagen: "",
  });

  const [observaciones, setobservaciones] = useState("");
  const [notas, setnotas] = useState([]);
  const [isOpen, setisOpen] = useState(false);
  const onClose = () => setisOpen(false);

  const [ticketData, setticketData] = useState<any>();

  const [satistaccion, setsatistaccion] = useState<any>({
    pregunta_0: "",
    pregunta_1: "",
    pregunta_2: "",
    pregunta_3: "",
    pregunta_4: "",

    pregunta_0_observaciones: "",
    pregunta_1_observaciones: "",
    pregunta_2_observaciones: "",
    pregunta_3_observaciones: "",
    pregunta_4_observaciones: "",
  });

  const cleanForm = () => {
    setform({
      id: "",
      nombre: "",
      apellido: "",
      correo: "",
      telefono: "",
      cp: "",
      estado: "",
      municipio: "",
      fuente: "",
      medio: "",
      prioridad: "",
      observaciones: "",
      tipo_ticket: "",
      otro_tipo_ticket: "",
      tipo_reporte: "",
      status: "",
      nota_final: "",
      pregunta: "",

      equipo: "",
      fecha_compra: "",
      pieza: "",
      num_serie: "",
      distribuidor: "",
      foto_video: null,
    });
  };

  const [productosArray, setproductosArray] = useState([]);
  const [dealersArray, setdealersArray] = useState([]);

  useEffect(() => {
    geomark.geomark().then((res) => {
      const newArray = res.productos.map((res: any) => {
        return {
          ...res,
          value: res.label,
        };
      });
      setproductosArray(newArray);
    });

    dealers.dealers().then((res) => {
      const newArray = res.distribuidor.map((res: any) => {
        return {
          ...res,
          value:
            res.numero +
            " - " +
            res.razon_social +
            " - " +
            res.nombre_comercial,
          label:
            res.numero +
            " - " +
            res.razon_social +
            " - " +
            res.nombre_comercial,
        };
      });
      setdealersArray(newArray);
    });
  }, []);

  const updateStatusTicket = (status: any) => {
    const data = {
      id: ticketData.id,
      status,
    };

    ticket.ActualizarStatus(data).then((res) => {
      if (res.error) {
        toast({
          title: "Error",
          description: res.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Ticket actualizado",
          description: "El ticket se ha actualizado correctamente",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        getInformacion();
      }
    });
  };

  const getInformacion = () => {
    setloader(true);
    ticket.TicketFolioGet(folio).then((res) => {
      if (res.ticket) {
        setticketData(res.ticket);

        if (res?.ticket?.datos) {
          const datos = JSON.parse(res?.ticket?.datos ?? []);

          const productos = JSON.parse(res?.productos ?? []);
          setproductosArrayVenta(productos);

          setventaArray(res.venta);
          setform({
            ...form,
            id: res.ticket.id,
            nombre: res.ticket.nombre,
            apellido: res.ticket.apellido,
            correo: res.ticket.correo,
            telefono: res.ticket.telefono,
            cp: res.ticket.cp,
            estado: res.ticket.estado,
            municipio: res.ticket.municipio,
            pregunta: res.ticket.pregunta,
            fuente: res.ticket.fuente,
            medio: res.ticket.medio,
            prioridad: res.ticket.prioridad,
            observaciones: res.ticket.observaciones,
            tipo_ticket: res.ticket.tipo_reporte,
            otro_tipo_ticket: res.ticket.otro_tipo_ticket,
            tipo_reporte: res.ticket.tipo_reporte,
            status: res.ticket.status,
            equipo: {
              label: datos?.equipo ?? "",
              value: datos?.equipo ?? "",
            },
            fecha_compra: datos?.fecha_compra ?? "",
            pieza: datos?.pieza ?? "",
            num_serie: datos?.serie ?? "",
            distribuidor: {
              label: datos?.distribuidor ?? "",
              value: datos?.distribuidor ?? "",
            },
            foto_video: datos?.foto_video ?? "",
            arreglo_imagen: res.ticket?.arreglo_imagen
              ? JSON.parse(res.ticket?.arreglo_imagen)
              : "",
          });
        } else {
          const productos = JSON.parse(res?.productos ?? []);
          setproductosArrayVenta(productos);
          setventaArray(res.venta);
          setform({
            id: res.ticket.id,
            nombre: res.ticket.nombre,
            apellido: res.ticket.apellido,
            correo: res.ticket.correo,
            telefono: res.ticket.telefono,
            cp: res.ticket.cp,
            estado: res.ticket.estado,
            municipio: res.ticket.municipio,
            pregunta: res.ticket.pregunta,
            fuente: res.ticket.fuente,
            medio: res.ticket.medio,
            prioridad: res.ticket.prioridad,
            observaciones: res.ticket.observaciones,
            tipo_ticket: res.ticket.tipo_reporte,
            otro_tipo_ticket: res.ticket.otro_tipo_ticket,
            tipo_reporte: res.ticket.tipo_reporte,
            status: res.ticket.status,
            arreglo_imagen: res.ticket?.arreglo_imagen
              ? JSON.parse(res.ticket?.arreglo_imagen)
              : "",
          });
        }

        if (res?.ticket?.respuestas) {
          const respuestas = JSON.parse(res?.ticket?.respuestas ?? []);
          setsatistaccion({
            pregunta_0: respuestas?.pregunta_0 ?? "",
            pregunta_1: respuestas?.pregunta_1 ?? "",
            pregunta_2: respuestas?.pregunta_2 ?? "",
            pregunta_3: respuestas?.pregunta_3 ?? "",
            pregunta_4: respuestas?.pregunta_4 ?? "",
            pregunta_0_observaciones:
              respuestas?.pregunta_0_observaciones ?? "",
            pregunta_1_observaciones:
              respuestas?.pregunta_1_observaciones ?? "",
            pregunta_2_observaciones:
              respuestas?.pregunta_2_observaciones ?? "",
            pregunta_3_observaciones:
              respuestas?.pregunta_3_observaciones ?? "",
            pregunta_4_observaciones:
              respuestas?.pregunta_4_observaciones ?? "",
          });
        }
      }
    });
  };

  const sendEmail = () => {
    ticket
      .sendEmail({
        correo: form.correo,
        folio: ticketData.folio,
        nombre: form.nombre,
        apellido: form.apellido,
      })
      .then((res) => {
        if (res.error) {
          toast({
            title: "Error",
            description: res.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          toast({
            title: "Correo enviado",
            description: "El correo se ha enviado correctamente",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  };

  const getInfo = () => {
    ticket.estadosGet().then((res) => {
      setestadosArray(res.estados);
    });
  };

  const getMunicipios = () => {
    const estado: any = estadosArray.find(
      (res: any) => res.label === form.estado
    );
    if (!estado) return setmunicipiosArray([]);

    ticket.municipiosGet(estado?.value).then((res) => {
      setmunicipiosArray(res.municipios);
    });

    ticket.zonasGet(estado?.zonas_id).then((res) => {
      setform({ ...form, zone: res.zonas[0].label });
    });
  };

  useEffect(() => {
    getMunicipios();
  }, [form.estado]);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    getInformacion();
    return () => {};
  }, []);

  const createTicket = () => {
    if (form.status === "Terminado") {
      if (
        satistaccion.pregunta_0 === "" ||
        satistaccion.pregunta_1 === "" ||
        satistaccion.pregunta_2 === "" ||
        satistaccion.pregunta_3 === ""
      ) {
        toast({
          title: "Error",
          description: "Debe responder todas las preguntas de satisfaccion",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      setloading(true);

      const arraySatisfaccion: any = {
        pregunta_0: satistaccion.pregunta_0,
        pregunta_1: satistaccion.pregunta_1,
        pregunta_2: satistaccion.pregunta_2,
        pregunta_3: satistaccion.pregunta_3,
        pregunta_4: satistaccion.pregunta_4,
        pregunta_0_observaciones: satistaccion.pregunta_0_observaciones,
        pregunta_1_observaciones: satistaccion.pregunta_1_observaciones,
        pregunta_2_observaciones: satistaccion.pregunta_2_observaciones,
        pregunta_3_observaciones: satistaccion.pregunta_3_observaciones,
        pregunta_4_observaciones: satistaccion.pregunta_4_observaciones,
      };

      const suma =
        parseFloat(satistaccion.pregunta_0) +
        parseFloat(satistaccion.pregunta_1) +
        parseFloat(satistaccion.pregunta_2) +
        parseFloat(satistaccion.pregunta_3);
      ticket
        .EncuestaAdd({
          id: ticketData.id,
          suma: suma / 4,
          respuestas: JSON.stringify(arraySatisfaccion),
        })
        .then((res) => {
          if (res.ticket) {
            setloading(false);
            toast({
              title: "Ticket creado",
              description: "El ticket se ha actualizado correctamente",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });

            getInformacion();
          }
        });
    }
  };

  const actualizarTicket = () => {
    setloading(true);
    if (
      form.nombre === "" ||
      form.apellido === "" ||
      form.correo === "" ||
      form.telefono === "" ||
      form.cp === "" ||
      form.estado === "" ||
      form.fuente === "" ||
      form.medio === "" ||
      form.prioridad === "" ||
      form.observaciones === ""
    ) {
      setloading(false);
      toast({
        title: "Error",
        description: "Todos los campos son obligatorios",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    if (form.foto_video === "") {
      form.tipo_reporte = form.tipo_ticket;
      form.equipo = form.equipo_value ?? form.equipo?.value;
      form.distribuidor = form.distribuidor_value ?? form.distribuidor?.value;

      const dataEnvio = {
        ...ticketData,
        ...form,
      };

      ticket
        .TicketUpdate(dataEnvio)
        .then((res) => {
          if (res.error) {
            setloading(false);
            toast({
              title: "Error",
              description: res.message,
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            setloading(false);
            toast({
              title: "Ticket actualizado",
              description: "El ticket se ha creado correctamente",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
            getInformacion();
          }
        })
        .catch((err) => {
          setloading(false);
          toast({
            title: "Error",
            description: err.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        });
    } else {
      const formData = new FormData();
      formData.append("id", form.id);
      formData.append("nombre", form.nombre);
      formData.append("apellido", form.apellido);
      formData.append("correo", form.correo);
      formData.append("telefono", form.telefono);
      formData.append("cp", form.cp);
      formData.append("estado", form.estado);
      formData.append("municipio", form.municipio);
      formData.append("fuente", form.fuente);
      formData.append("medio", form.medio);
      formData.append("prioridad", form.prioridad);
      formData.append("observaciones", form.observaciones);
      formData.append("tipo_ticket", form.tipo_ticket);
      formData.append("otro_tipo_ticket", form.otro_tipo_ticket);
      formData.append("tipo_reporte", form.tipo_ticket);
      formData.append("zone", form.zone);
      formData.append("equipo", form.equipo_value ?? form.equipo?.value);
      formData.append("fecha_compra", form.fecha_compra);
      formData.append("pieza", form.pieza);
      formData.append("num_serie", form.num_serie);
      formData.append(
        "distribuidor",
        form.distribuidor_value ?? form.distribuidor?.value
      );
      formData.append("foto_video", form.foto_video);

      ticket
        .TicketUpdateFile(formData)
        .then((res) => {
          if (res.error) {
            setloading(false);
            toast({
              title: "Error",
              description: res.message,
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            setloading(false);
            toast({
              title: "Ticket actualizado",
              description: "El ticket se ha creado correctamente",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
            getInformacion();
          }
        })
        .catch((err) => {
          setloading(false);
          toast({
            title: "Error",
            description: err.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        });
    }
  };

  const aceptarTicket = () => {
    setloading(true);
    const data = {
      id: ticketData.id,
      descripcion: observaciones,
      status: "Recibido",
    };

    ticket
      .NotaAdd(data)
      .then((res) => {
        if (res.error) {
          setloading(false);
          toast({
            title: "Error",
            description: res.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        } else {
          setloading(false);
          toast({
            title: "Ticket actualizado",
            description: "El ticket se ha aceptado correctamente",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          setobservaciones("");
          notasGet();
          getInformacion();
        }
      })
      .catch((err) => {
        setloading(false);
        toast({
          title: "Error",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  useEffect(() => {
    if (ticketData?.id) {
      notasGet();
    }
  }, [ticketData]);

  const notasGet = () => {
    if (
      form.status === "Recibido" ||
      form.status === "En proceso" ||
      form.status === "Terminado"
    ) {
      ticket.NotaGet(ticketData.id).then((res) => {
        if (res.tickets) {
          setnotas(res?.tickets?.reverse() ?? []);
        }
      });
    }
  };

  const guardarTicket = (status = "Recibido") => {
    setloading(true);
    const data = {
      id: ticketData.id,
      descripcion: observaciones,
      status: status,
      user_id: id,
      nota_final: status === "Terminado" ? form.nota_final_value : "",
    };

    ticket
      .NotaAdd(data)
      .then((res) => {
        if (res.error) {
          setloading(false);
          toast({
            title: "Error",
            description: res.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });

          onClose();
        } else {
          setloading(false);
          setobservaciones("");
          toast({
            title: "Ticket actualizado",
            description: "El ticket se ha aceptado correctamente",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          if (status === "Terminado") {
            getInformacion();
            sendEmail();
          }
          notasGet();
          onClose();
        }
      })
      .catch((err) => {
        setloading(false);
        toast({
          title: "Error",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });

        onClose();
      });
  };

  const preguntas = (tipo: any) => {
    let array: any = [];
    if (tipo === "Centro de servicio autorizado más cercano") {
      array = preguntaCentroServicio;
    } else if (tipo === "Refacciones" || tipo === "Cotización" || tipo === "Venta") {
      array = preguntaRefacciones;
    } else if (tipo === "Reporte (Proceso de garantia)") {
      array = preguntaReportes;
    }
    return array.map((pregunta: any, index: any) => {
      if (pregunta.type === 1) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "30%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>

            <RadioGroup
              onChange={(e) => {
                setsatistaccion({
                  ...satistaccion,
                  ["pregunta_" + index]: e,
                });
              }}
              value={satistaccion["pregunta_" + index]}
            >
              <Stack direction="row">
                <Radio value="4">Si</Radio>
                <Radio value="3">No</Radio>

                {satistaccion["pregunta_" + index] === "3" && (
                  <Input
                    placeholder="Observaciones"
                    value={satistaccion["pregunta_" + index + "_observaciones"]}
                    onChange={(e) =>
                      setsatistaccion({
                        ...satistaccion,
                        ["pregunta_" + index + "_observaciones"]:
                          e.target.value,
                      })
                    }
                  />
                )}
              </Stack>
            </RadioGroup>
          </Flex>
        );
      } else if (pregunta.type === 2) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "30%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>

            <RadioGroup
              onChange={(e) => {
                setsatistaccion({
                  ...satistaccion,
                  ["pregunta_" + index]: e,
                });
              }}
              value={satistaccion["pregunta_" + index]}
            >
              <Stack direction="row">
                <Radio value="4">Muy buena</Radio>
                <Radio value="3">Buena</Radio>
                <Radio value="2">Regular</Radio>
                <Radio value="1">Mala</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        );
      } else if (pregunta.type === 3) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "30%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>

            <RadioGroup
              onChange={(e) => {
                setsatistaccion({
                  ...satistaccion,
                  ["pregunta_" + index]: e,
                });
              }}
              value={satistaccion["pregunta_" + index]}
            >
              <Stack direction="row">
                <Radio value="4">Muy probable</Radio>
                <Radio value="3">Probable</Radio>
                <Radio value="2">Poco probable</Radio>
                <Radio value="1">Nada probable</Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        );
      } else if (pregunta.type === 4) {
        return (
          <Flex gap={10} mt={4}>
            <div
              style={{
                width: "50%",
              }}
            >
              <TextComponent
                color={"black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text={pregunta.pregunta}
              />
            </div>
            <Textarea
              placeholder="Observaciones"
              value={satistaccion["pregunta_" + index] ?? ""}
              onChange={(e) =>
                setsatistaccion({
                  ...satistaccion,
                  ["pregunta_" + index]: e.target.value,
                })
              }
            />
          </Flex>
        );
      }
    });
  };

  const parseMoney = (value: any) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(value);
  };

  return (
    <div>
      <Nav />
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"} isCentered>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalHeader>Finalizar ticket {ticketData?.folio}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Tipo de ticket: {ticketData?.tipo_reporte}</FormLabel>
              <SelectComponentsName
                label="Tipo de ticket"
                onChange={(e: any) => {
                  setform({
                    ...form,
                    nota_final_value: e.value,
                    nota_final: e,
                  });
                }}
                options={[
                  { value: "Incidencia cliente", label: "Incidencia cliente" },
                  {
                    value: "Incidencia distribuidor",
                    label: "Incidencia distribuidor",
                  },
                  {
                    value: "Incidencia de producto",
                    label: "Incidencia de producto",
                  },
                ]}
                placeholder="Tipo de ticket"
                value={form.nota_final}
              />
            </FormControl>
            <FormControl mt={2}>
              <FormLabel>Nota</FormLabel>
              <Textarea
                placeholder="Nota"
                value={observaciones}
                onChange={(e) => setobservaciones(e.target.value)}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              mr={3}
              onClick={() => {
                guardarTicket("Terminado");
              }}
              isLoading={loading}
            >
              Guardar
            </Button>
            <Button onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Sidebar>
        <Box bg={"white"} p={10} borderRadius={20}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Flex gridGap={3} alignItems={"center"}>
              <FiFile size={50} />
              <TextComponent
                color={"#f06e26"}
                fontSize={"3xl"}
                fontWeight={"bolder"}
                textAlign={"left"}
                text={"Ticket " + folio}
                textTransform={"uppercase"}
              />
            </Flex>
            <Box>
              <Image src={"/stihl-logo.svg"} height={50} />
            </Box>
          </Box>

          <Box
            bg={"white"}
            borderRadius={20}
            gap={2}
            display="flex"
            flexDirection="row"
            marginTop={3}
          >
            <Select
              placeholder="Seleccione un tipo de ticket"
              value={form.tipo_ticket}
              onChange={(e) =>
                setform({ ...form, tipo_ticket: e.target.value })
              }
            >
              {[
                "Centro de servicio autorizado más cercano",
                "Ser distribuidor",
                "Refacciones",
                "Cotización",
                "Reporte (Proceso de garantia)",
                "Marketing",
                "Dudas tecnicas",
                "Reporte de pedido",
                "Reporte de servicio de atención",
                "Venta",
                "Otro",
              ].map((rol) => (
                <option key={rol} value={rol}>
                  {rol}
                </option>
              ))}
            </Select>
          </Box>

          {form.tipo_ticket === "Otro" && (
            <Box
              bg={"white"}
              borderRadius={20}
              gap={2}
              display="flex"
              flexDirection="row"
              marginTop={3}
            >
              <Input
                type="text"
                placeholder="Otro tipo de ticket"
                value={form.otro_tipo_ticket}
                onChange={(e) =>
                  setform({ ...form, otro_tipo_ticket: e.target.value })
                }
                title="Otro tipo de ticket"
              />
            </Box>
          )}

          <Box mt={5} width={400}>
            <TextComponent
              color={"black"}
              fontSize={"sm"}
              fontWeight={"medium"}
              textAlign={"left"}
              text="Estado de seguimiento"
            />

            {/* Generar un Flex en donde se usara justify-between y acomodar el bg por el tipo de status*/}
            <Flex
              borderRadius={20}
              gap={2}
              display="flex"
              flexDirection="row"
              marginTop={1}
              justifyContent={"space-between"}
              padding={3}
              border={"1px solid lightgray"}
              bg={
                form.status === "Creado"
                  ? "linear-gradient(90deg, rgba(226,19,70,1) 0%, rgba(255,255,255,1) 23%);"
                  : form.status === "En proceso" || form.status === "Recibido"
                  ? "linear-gradient(90deg, rgba(226,19,70,1) 0%, rgba(207,101,5,1) 50%, rgba(255,255,255,1) 63%);"
                  : "green"
              }
            >
              <TextComponent
                color={"white"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text="Iniciado"
                onClick={() => {
                  updateStatusTicket("Creado");
                }}
                role="button"
              />
              <TextComponent
                color={form.status === "Creado" ? "black" : "white"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text="En proceso"
                onClick={() => {
                  updateStatusTicket("Recibido");
                }}
                role="button"
              />
              <TextComponent
                color={form.status === "Terminado" ? "white" : "black"}
                fontSize={"sm"}
                fontWeight={"medium"}
                textAlign={"left"}
                text="Concluido/Cerrado"
                onClick={() => {
                  updateStatusTicket("Terminado");
                }}
                role="button"
              />
            </Flex>
          </Box>

          {form.tipo_ticket !== "" && (
            <>
              <Flex gap={4}>
                <Box
                  bg={"white"}
                  borderRadius={20}
                  gap={2}
                  display="flex"
                  marginTop={5}
                  flexDirection={"column"}
                  width={
                    form.tipo_ticket === "Distribuidor" ||
                    form.tipo_ticket === "Refacciones" ||
                    form.tipo_ticket === "Cotización" ||
                    form.tipo_ticket === "Reporte (Proceso de garantia)" ||
                    form.tipo_ticket === "Dudas tecnicas"
                      ? "50%"
                      : "100%"
                  }
                >
                  <Box borderBottom={"1px solid #f06e26"}>
                    <TextComponent
                      color={"#f06e26"}
                      fontSize={"xl"}
                      fontWeight={"bolder"}
                      textAlign={"center"}
                      text="Datos del reporte"
                      textTransform={"uppercase"}
                    />
                  </Box>
                  <Box>
                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Box width={"100%"}>
                        <Text mb="8px">
                          Nombre <span style={{ color: "red" }}>*</span>
                        </Text>
                        <Input
                          type="text"
                          placeholder="Nombre"
                          value={form.nombre}
                          onChange={(e) =>
                            setform({ ...form, nombre: e.target.value })
                          }
                          title="Nombre"
                        />
                      </Box>
                      <Box width={"100%"}>
                        <Text mb="8px">
                          Apellidos <span style={{ color: "red" }}>*</span>
                        </Text>
                        <Input
                          type="text"
                          placeholder="Apellidos"
                          value={form.apellido}
                          onChange={(e) =>
                            setform({ ...form, apellido: e.target.value })
                          }
                          title="Apellidos"
                        />
                      </Box>
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Box width={"100%"}>
                        <Text mb="8px">
                          Correo electronico{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Text>
                        <Input
                          type="email"
                          placeholder="Correo electronico"
                          value={form.correo}
                          onChange={(e) =>
                            setform({ ...form, correo: e.target.value })
                          }
                          title="Correo electronico"
                        />
                      </Box>
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Box width={"100%"}>
                        <Text mb="8px">
                          Telefono <span style={{ color: "red" }}>*</span>
                        </Text>
                        <Input
                          type="number"
                          placeholder="Telefono"
                          value={form.telefono}
                          onChange={(e) =>
                            setform({ ...form, telefono: e.target.value })
                          }
                          title="Telefono"
                        />
                      </Box>
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Box width={"100%"}>
                        <Text mb="8px">Codigo postal</Text>
                        <Input
                          type="number"
                          placeholder="Codigo postal"
                          value={form.cp}
                          onChange={(e) =>
                            setform({ ...form, cp: e.target.value })
                          }
                          title="Codigo postal"
                        />
                      </Box>
                      <Box width={"100%"}>
                        <Text mb="8px">
                          Estado <span style={{ color: "red" }}>*</span>
                        </Text>
                        <Select
                          placeholder="Seleccione un estado"
                          value={form.estado}
                          onChange={(e) =>
                            setform({ ...form, estado: e.target.value })
                          }
                          title="Estado"
                        >
                          {estadosArray.map((rol: any) => (
                            <option
                              key={rol.label}
                              value={rol.label}
                              selected={rol.label === form.estado}
                            >
                              {rol.label}
                            </option>
                          ))}
                        </Select>
                      </Box>

                      <Box width={"100%"}>
                        <Text mb="8px">
                          Municipio <span style={{ color: "red" }}>*</span>
                        </Text>
                        <Select
                          placeholder="Seleccione un municipio"
                          value={form.municipio}
                          onChange={(e) =>
                            setform({ ...form, municipio: e.target.value })
                          }
                          title="Municipio"
                        >
                          {municipiosArray.map((rol: any) => (
                            <option
                              key={rol.label}
                              value={rol.label}
                              selected={rol.label === form.municipio}
                            >
                              {rol.label}
                            </option>
                          ))}
                        </Select>
                      </Box>
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Box width={"100%"}>
                        <Text mb="8px">Tipo de fuente</Text>
                        <Select
                          placeholder="Seleccione una opcion de fuente"
                          value={form.fuente}
                          onChange={(e) =>
                            setform({ ...form, fuente: e.target.value })
                          }
                        >
                          {[
                            "Tienda en línea",
                            "Investigación web",
                            "Google AdWords",
                            "Instagram",
                            "Directo",
                            "Facebook",
                            "Display",
                            "YouTube",
                            "Ninguno",
                            "Waze",
                            "Twitter",
                            "Barda pintada",
                            "Espectacular",
                          ].map((rol) => (
                            <option key={rol} value={rol}>
                              {rol}
                            </option>
                          ))}
                        </Select>
                      </Box>
                      <Box width={"100%"}>
                        <Text mb="8px">Tipo de medio</Text>
                        <Select
                          placeholder="Seleccione una opcion de medio"
                          value={form.medio}
                          onChange={(e) =>
                            setform({ ...form, medio: e.target.value })
                          }
                        >
                          {[
                            "Chat",
                            "Correo electronico",
                            "Formulario",
                            "Llamada",
                            "Messenger",
                            "ML",
                            "Ninguno",
                            "SMS",
                            "Whatsapp",
                          ].map((rol) => (
                            <option key={rol} value={rol}>
                              {rol}
                            </option>
                          ))}
                        </Select>
                      </Box>
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Box width={"100%"}>
                        <Text mb="8px">Pregunta</Text>
                        <Input
                          placeholder="Pregunta cliente"
                          value={form.pregunta}
                          onChange={(e) =>
                            setform({ ...form, pregunta: e.target.value })
                          }
                        />
                      </Box>
                    </Box>

                    {form.tipo_ticket === "Marketing" && (
                      <>
                        <Box
                          bg={"white"}
                          borderRadius={20}
                          gap={2}
                          display="flex"
                          flexDirection="row"
                          marginTop={3}
                          marginBottom={3}
                        >
                          <TextComponent
                            color={"black"}
                            fontSize={"sm"}
                            fontWeight={"medium"}
                            textAlign={"center"}
                            text="Fotos"
                          />
                        </Box>

                        <Flex mt={3} gap={3}>
                          {form.arreglo_imagen &&
                            form.arreglo_imagen.map((foto: any) => (
                              <Image
                                src={
                                  "https://api-crm.contactostihl.com/img/" +
                                  foto
                                }
                                height={100}
                                onClick={() => {
                                  window.open(
                                    "https://api-crm.contactostihl.com/img/" +
                                      foto,
                                    "_blank"
                                  );
                                }}
                                role="button"
                              />
                            ))}
                        </Flex>
                      </>
                    )}

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Box width={"100%"}>
                        <Text mb="8px">Observaciones</Text>
                        <Textarea
                          placeholder="Observaciones"
                          value={form.observaciones}
                          onChange={(e) =>
                            setform({ ...form, observaciones: e.target.value })
                          }
                        />
                      </Box>
                    </Box>

                    {(form.tipo_ticket ===
                      "Centro de servicio autorizado más cercano" ||
                      form.tipo_ticket === "Ser distribuidor" ||
                      form.tipo_ticket === "Distribuidor cercano" ||
                      form.tipo_ticket === "Otro") && (
                      <Box
                        bg={"white"}
                        borderRadius={20}
                        gap={5}
                        display="flex"
                        flexDirection="row"
                        marginTop={3}
                        alignItems={"center"}
                      >
                        <Box width={"100%"}>
                          <Text mb="8px">Complejidad</Text>
                          <Select
                            placeholder="Seleccione una complejidad"
                            value={form.prioridad}
                            onChange={(e) =>
                              setform({ ...form, prioridad: e.target.value })
                            }
                            width={300}
                          >
                            {["Critico", "Urgente", "Normal"].map((rol) => (
                              <option key={rol} value={rol}>
                                {rol}
                              </option>
                            ))}
                          </Select>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>

                {form.tipo_ticket === "Cotización" && (
                  <Box
                    bg={"white"}
                    borderRadius={20}
                    gap={2}
                    display="flex"
                    marginTop={5}
                    flexDirection={"column"}
                    width={"50%"}
                  >
                    <Box borderBottom={"1px solid #f06e26"}>
                      <TextComponent
                        color={"#f06e26"}
                        fontSize={"xl"}
                        fontWeight={"bolder"}
                        textAlign={"center"}
                        text="Datos de compra"
                        textTransform={"uppercase"}
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Box width={"100%"}>
                        <Text mb="8px">Equipo</Text>
                        <SelectComponentsName
                          options={productosArray}
                          label="Equipo"
                          placeholder="Seleccione un equipo"
                          value={form.equipo}
                          onChange={(e) => {
                            setform({
                              ...form,
                              equipo_value: e.value,
                              equipo: e,
                            });
                          }}
                        />
                      </Box>

                      <Box width={"100%"}>
                        <Text mb="8px">Distribuidor</Text>
                        <SelectComponentsName
                          options={dealersArray}
                          label="Distribuidor"
                          placeholder="Seleccione un distribuidor"
                          value={form.distribuidor}
                          onChange={(e) => {
                            setform({
                              ...form,
                              distribuidor_value: e.value,
                              distribuidor: e,
                            });
                          }}
                        />
                      </Box>
                    </Box>

                    <Box>
                      <Box
                        bg={"white"}
                        borderRadius={20}
                        gap={2}
                        display="flex"
                        flexDirection="row"
                        marginTop={3}
                        marginBottom={3}
                      >
                        <TextComponent
                          color={"black"}
                          fontSize={"sm"}
                          fontWeight={"medium"}
                          textAlign={"center"}
                          text="Cotización"
                        />
                      </Box>
                      {form.foto_video && (
                        <Button
                          color={"white"}
                          backgroundColor={"#f06e26"}
                          fontSize={"xs"}
                          onClick={() => {
                            window.open(
                              "https://api-crm.contactostihl.com/img/" +
                                form.foto_video,
                              "_blank"
                            );
                          }}
                        >
                          Ver cotización
                        </Button>
                      )}
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={5}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                      alignItems={"center"}
                    >
                      <Box width={"100%"}>
                        <Text mb="8px">Complejidad</Text>
                        <Select
                          placeholder="Seleccione una complejidad"
                          value={form.prioridad}
                          onChange={(e) =>
                            setform({ ...form, prioridad: e.target.value })
                          }
                          width={300}
                        >
                          {["Critico", "Urgente", "Normal"].map((rol) => (
                            <option key={rol} value={rol}>
                              {rol}
                            </option>
                          ))}
                        </Select>
                      </Box>
                    </Box>
                  </Box>
                )}

                {form.tipo_ticket === "Venta" && (
                  <Box
                    bg={"white"}
                    borderRadius={20}
                    gap={2}
                    display="flex"
                    marginTop={5}
                    flexDirection={"column"}
                    width={"50%"}
                  >
                    <Box borderBottom={"1px solid #f06e26"}>
                      <TextComponent
                        color={"#f06e26"}
                        fontSize={"xl"}
                        fontWeight={"bolder"}
                        textAlign={"center"}
                        text="Datos de compra"
                        textTransform={"uppercase"}
                      />
                    </Box>

                    <Box mt={3}>
                      <p style={{ fontSize: 16, margin: 3 }}>Folio</p>
                      <Input
                        placeholder="Titulo de orden de compra"
                        value={ventaArray.folio_interno}
                        mb={2}
                        type="text"
                      />
                      <p style={{ fontSize: 16, margin: 3 }}>Titulo</p>
                      <Input
                        placeholder="Titulo de orden de compra"
                        value={ventaArray.titulo}
                        mb={2}
                        type="text"
                      />
                      <p style={{ fontSize: 16, margin: 3 }}>Fecha de venta</p>
                      <Input
                        placeholder="Fecha de venta"
                        value={ventaArray.fecha_venta}
                        mb={2}
                        type="date"
                      />
                      <p style={{ fontSize: 16, margin: 3 }}>Distribuidor</p>
                      <Input
                        placeholder="Distribuidor"
                        value={ventaArray.nombre_comercial + " - " + ventaArray.sap}
                        mb={2}
                        type="text"
                      />
                      <Box mb={2} />
                    </Box>

                    <TableContainer>
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th>Producto</Th>
                            <Th>Serial</Th>
                            <Th>Cantidad</Th>
                            <Th>Precio</Th>
                            <Th>Total</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {productosArrayVenta.map((res: any, index: any) => {
                            return (
                              <Tr key={index}>
                                <Td>{res.modelo}</Td>
                                <Td>{res.serial}</Td>
                                <Td>{res.cantidad}</Td>
                                <Td>{parseMoney(res.precio)}</Td>
                                <Td textAlign={"center"}>
                                  {res.cantidad * res.precio}
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                        <Tfoot>
                          <Tr>
                            <Th>Total</Th>
                            <Th></Th>
                            <Th textAlign={"center"}>
                              {productosArrayVenta.reduce(
                                (acc: any, res: any) =>
                                  acc + parseFloat(res.cantidad),
                                0
                              )}
                            </Th>
                            <Th textAlign={"center"}>
                              {productosArrayVenta.reduce(
                                (acc: any, res: any) =>
                                  acc + parseFloat(res.precio),
                                0
                              )}
                            </Th>
                            <Th textAlign={"center"}>
                              {productosArrayVenta.reduce(
                                (acc: any, res: any) =>
                                  acc + parseFloat(res.total),
                                0
                              )}
                            </Th>
                          </Tr>
                        </Tfoot>
                      </Table>
                    </TableContainer>
                  </Box>
                )}

                {(form.tipo_ticket === "Distribuidor" ||
                  form.tipo_ticket === "Refacciones" ||
                  form.tipo_ticket === "Reporte (Proceso de garantia)" ||
                  form.tipo_ticket === "Dudas tecnicas") && (
                  <Box
                    bg={"white"}
                    borderRadius={20}
                    gap={2}
                    display="flex"
                    marginTop={5}
                    flexDirection={"column"}
                    width={"50%"}
                  >
                    <Box borderBottom={"1px solid #f06e26"}>
                      <TextComponent
                        color={"#f06e26"}
                        fontSize={"xl"}
                        fontWeight={"bolder"}
                        textAlign={"center"}
                        text="Datos de compra"
                        textTransform={"uppercase"}
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Box width={"100%"}>
                        <Text mb="8px">Equipo</Text>
                        <SelectComponentsName
                          options={productosArray}
                          label="Equipo"
                          placeholder="Seleccione un equipo"
                          value={form.equipo}
                          onChange={(e) => {
                            setform({
                              ...form,
                              equipo_value: e.value,
                              equipo: e,
                            });
                          }}
                        />
                      </Box>

                      <Box width={"100%"}>
                        <Text mb="8px">Fecha de compra</Text>
                        <Input
                          type="date"
                          placeholder="Fecha de compra"
                          value={form.fecha_compra}
                          onChange={(e) =>
                            setform({
                              ...form,
                              fecha_compra: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <Box width={"100%"}>
                        <Text mb="8px">Pieza</Text>
                        <Input
                          type="text"
                          placeholder="Pieza"
                          value={form.pieza}
                          onChange={(e) =>
                            setform({ ...form, pieza: e.target.value })
                          }
                        />
                      </Box>
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <Box width={"100%"}>
                        <Text mb="8px">Numero de serie</Text>
                        <Input
                          type="text"
                          placeholder="Num. Serie"
                          value={form.num_serie}
                          onChange={(e) =>
                            setform({ ...form, num_serie: e.target.value })
                          }
                        />
                      </Box>
                      <Box width={"100%"}>
                        <Text mb="8px">Distribuidor</Text>
                        <SelectComponentsName
                          options={dealersArray}
                          label="Distribuidor"
                          placeholder="Seleccione un distribuidor"
                          value={form.distribuidor}
                          onChange={(e) => {
                            setform({
                              ...form,
                              distribuidor_value: e.value,
                              distribuidor: e,
                            });
                          }}
                        />
                      </Box>
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={2}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                    >
                      <TextComponent
                        color={"black"}
                        fontSize={"sm"}
                        fontWeight={"medium"}
                        textAlign={"center"}
                        text="Foto"
                      />
                      {form.foto_video && (
                        <Button
                          color={"white"}
                          backgroundColor={"#f06e26"}
                          fontSize={"xs"}
                          onClick={() => {
                            window.open(
                              "https://api-crm.contactostihl.com/img/" +
                                form.foto_video,
                              "_blank"
                            );
                          }}
                        >
                          Ver fotos
                        </Button>
                      )}
                    </Box>
                    <Flex mt={3} gap={3}>
                      {form.arreglo_imagen &&
                        form.arreglo_imagen.map((foto: any) => (
                          <Image
                            src={
                              "https://api-crm.contactostihl.com/img/" + foto
                            }
                            height={100}
                            onClick={() => {
                              window.open(
                                "https://api-crm.contactostihl.com/img/" + foto,
                                "_blank"
                              );
                            }}
                            role="button"
                          />
                        ))}
                    </Flex>

                    <Box
                      borderBottom={"1px solid #f06e26"}
                      borderTop={"1px solid #f06e26"}
                      mt={3}
                    >
                      <TextComponent
                        color={"#f06e26"}
                        fontSize={"xl"}
                        fontWeight={"bolder"}
                        textAlign={"center"}
                        text="Acciones de seguimiento"
                        textTransform={"uppercase"}
                      />
                    </Box>

                    <Box
                      bg={"white"}
                      borderRadius={20}
                      gap={5}
                      display="flex"
                      flexDirection="row"
                      marginTop={3}
                      alignItems={"center"}
                    >
                      <Select
                        placeholder="Seleccione una complejidad"
                        value={form.prioridad}
                        onChange={(e) =>
                          setform({ ...form, prioridad: e.target.value })
                        }
                        width={300}
                      >
                        {["Critico", "Urgente", "Normal"].map((rol) => (
                          <option key={rol} value={rol}>
                            {rol}
                          </option>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                )}
              </Flex>

              <Box display={"flex"} justifyContent={"flex-end"} mt={5} gap={2}>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    cleanForm();
                  }}
                  size="md"
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  size="md"
                  isLoading={loading}
                  onClick={() => {
                    actualizarTicket();
                  }}
                >
                  Actualizar datos de ticket
                </Button>
              </Box>

              {form.status === "Creado" && (
                <Box>
                  <Box
                    borderBottom={"1px solid #f06e26"}
                    borderTop={"1px solid #f06e26"}
                    mt={3}
                  >
                    <TextComponent
                      color={"#f06e26"}
                      fontSize={"xl"}
                      fontWeight={"bolder"}
                      textAlign={"center"}
                      text="Aceptar ticket"
                      textTransform={"uppercase"}
                    />
                  </Box>

                  <Box mt={3}>
                    <TextComponent
                      color={"black"}
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      textAlign={"left"}
                      text="Respuesta de aceptación"
                    />

                    <Textarea
                      placeholder="Observaciones"
                      value={observaciones}
                      onChange={(e) => setobservaciones(e.target.value)}
                    />
                  </Box>

                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    mt={5}
                    gap={2}
                  >
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        cleanForm();
                      }}
                      size="md"
                    >
                      Cancelar
                    </Button>
                    <Button
                      colorScheme="green"
                      size="md"
                      isLoading={loading}
                      onClick={() => {
                        aceptarTicket();
                      }}
                    >
                      Aceptar ticket
                    </Button>
                  </Box>
                </Box>
              )}

              {(form.status === "En proceso" || form.status === "Recibido") && (
                <Box>
                  <Box
                    borderBottom={"1px solid #f06e26"}
                    borderTop={"1px solid #f06e26"}
                    mt={3}
                  >
                    <TextComponent
                      color={"#f06e26"}
                      fontSize={"xl"}
                      fontWeight={"bolder"}
                      textAlign={"center"}
                      text="Seguimiento"
                      textTransform={"uppercase"}
                    />
                  </Box>

                  <Flex>
                    <Box mt={3} width={"100%"}>
                      <TextComponent
                        color={"black"}
                        fontSize={"sm"}
                        fontWeight={"medium"}
                        textAlign={"left"}
                        text="Respuesta de seguimiento"
                      />

                      <Textarea
                        placeholder="Observaciones"
                        value={observaciones}
                        onChange={(e) => setobservaciones(e.target.value)}
                      />

                      <Flex display={"flex"} justifyContent={"flex-end"} mt={3}>
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            cleanForm();
                          }}
                          size="md"
                        >
                          Cancelar
                        </Button>
                        <Button
                          colorScheme="green"
                          size="md"
                          isLoading={loading}
                          onClick={() => {
                            guardarTicket();
                          }}
                        >
                          Guardar nota
                        </Button>
                      </Flex>
                    </Box>

                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      gap={2}
                      paddingInline={10}
                      width={"100%"}
                    >
                      <Box
                        bg={"white"}
                        borderRadius={20}
                        display="flex"
                        flexDirection={"column"}
                        width={"100%"}
                        scrollBehavior={"smooth"}
                        overflowY={"scroll"}
                      >
                        <Box borderBottom={"1px solid #f06e26"}>
                          <TextComponent
                            color={"#f06e26"}
                            fontSize={"xl"}
                            fontWeight={"bolder"}
                            textAlign={"center"}
                            text="Historial de notas"
                            textTransform={"uppercase"}
                          />
                        </Box>

                        {notas.map((nota: any) => (
                          <Box
                            sx={{
                              borderBottom: "1px solid lightgray",
                              padding: "10px",
                              display: "flex",
                              gap: "10px",
                              justifyContent: "space-between",
                            }}
                          >
                            <TextComponent
                              color={"black"}
                              fontSize={"md"}
                              fontWeight={"medium"}
                              textAlign={"left"}
                              text={nota.nota}
                            />

                            <TextComponent
                              color={"black"}
                              fontSize={"md"}
                              fontWeight={"medium"}
                              textAlign={"left"}
                              text={new Date(nota.created_at)
                                .toLocaleString()
                                .substring(0, 16)}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              )}

              {(form.status === "En proceso" || form.status === "Recibido") && (
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  mt={5}
                  gap={2}
                >
                  <Button
                    colorScheme="green"
                    size="md"
                    isLoading={loading}
                    onClick={() => {
                      setisOpen(true);
                    }}
                  >
                    Finalizar ticket
                  </Button>
                </Box>
              )}

              {form.status === "Terminado" && (
                <Box>
                  <Box
                    borderBottom={"1px solid #f06e26"}
                    borderTop={"1px solid #f06e26"}
                    mt={3}
                  >
                    <TextComponent
                      color={"#f06e26"}
                      fontSize={"xl"}
                      fontWeight={"bolder"}
                      textAlign={"center"}
                      text="Actividades post-servicio"
                      textTransform={"uppercase"}
                    />
                  </Box>

                  <Flex>
                    <Flex width={"100%"} flexDirection={"column"}>
                      <Flex width={"100%"}>
                        <Divider orientation="horizontal" mt={3} mb={3} />
                      </Flex>

                      {preguntas(form.tipo_ticket)}
                    </Flex>
                    <Box></Box>
                  </Flex>
                </Box>
              )}

              {form.status === "Terminado" && (
                <Box
                  display={"flex"}
                  justifyContent={"flex-end"}
                  mt={5}
                  gap={2}
                >
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      cleanForm();
                    }}
                    size="md"
                  >
                    Cancelar
                  </Button>
                  <Button
                    colorScheme="blue"
                    onClick={() => {
                      sendEmail();
                    }}
                    size="md"
                  >
                    Envíar encuesta a cliente
                  </Button>
                  <Button
                    colorScheme="green"
                    size="md"
                    isLoading={loading}
                    onClick={() => {
                      createTicket();
                    }}
                  >
                    Actualizar informacion de encuesta
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Sidebar>
    </div>
  );
}
