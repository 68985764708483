import { Navigate, Outlet } from "react-router";
import { getValues } from "../reducers/loginReducer";
import { useSelector } from "react-redux";

export default function PrivateRoute() {
  const { access_token, rol } = useSelector(getValues);

  console.log("access_token", rol);
  if (access_token) {
    return <Outlet />;
  } else {
    return <Navigate to={"/login"} />;
  }
}
