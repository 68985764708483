import React from "react";
import { Text } from "@chakra-ui/react";

interface TextProps {
  fontSize: any;
  text: string;
  textAlign: any;
  color: any;
  fontWeight: any;
  textTransform?: any;
  onClick?: () => void;
  role?: string;
}

export default function TextComponent({
  textAlign,
  fontSize,
  text,
  color,
  fontWeight,
  textTransform,
  onClick,
  role,
}: TextProps) {
  return (
    <Text
      fontWeight={fontWeight}
      color={color}
      textAlign={textAlign}
      fontSize={fontSize}
      textTransform={textTransform}
      onClick={onClick}
      role={role}
    >
      {text}
    </Text>
  );
}
