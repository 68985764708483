export const options = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const,
    },
    title: {
      display: true,
      text: "Ventas en pesos",
    },
  },
};

export function generateData(array: any) {
  const labels = array.map((item: any) => item.fuente);
  return {
    labels,
    datasets: [
      {
        label: "Ventas en pesos",
        data: array.map((item: any) => item.resultado),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
}

export const Tipo = [
  { value: "1", label: "Clientes" },
  { value: "2", label: "Posibles Clientes" },
];

export const Fuente = [
  { value: "Facebook", label: "Facebook" },
  { value: "Instagram", label: "Instagram" },
  { value: "Display", label: "Display" },
  { value: "Directo", label: "Directo" },
  { value: "Google AdWords", label: "Google AdWords" },
  { value: "Youtube", label: "Youtube" },
  { value: "Waze", label: "Waze" },
];


export function Columns() {
  return [
    { title: "#", campo: "_id" },
    { title: "Estado", campo: "estado" },
    { title: "Total de ventas", campo: "total_ventas" },
    { title: "Total vendido", campo: "total_vendidos" },
  ];
}



export function ColumnsFuente() {
  return [
    { title: "#", campo: "_id" },
    { title: "Imagen", campo: "imagen" },
    { title: "Modelo", campo: "modelo" },
    { title: "Cantidad", campo: "cantidad" },
    { title: "Precio", campo: "precio" },
  ];
}
