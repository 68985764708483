import { Flex, FlexProps, Link, Icon } from "@chakra-ui/react";
import { ReactText } from "react";
import { IconType } from "react-icons/lib";
import { useLocation, Link as LinkReact } from "react-router-dom";

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
}
export default function NavItem({ icon, children, ...rest }: NavItemProps) {
  const { pathname } = useLocation();
  const path = pathname.split("/")[2];
  const createUrl =
    children === "Inicio"
      ? ""
      : children === "Geolocalización"
      ? "geomarketing"
      : children.toString().toLowerCase().replace(/ /g, "-");
  return (
    <LinkReact to={"/crm/" + createUrl} style={{ textDecoration: "none" }}>
      <Link
        href={"/crm/" + createUrl}
        style={{ textDecoration: "none" }}
        _focus={{ boxShadow: "none" }}
      >
        <Flex
          align="center"
          paddingInline="5"
          paddingBlock="5"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          bg={path === createUrl ? "#f06e26" : "transparent"}
          color={path === createUrl ? "white" : "black"}
          _hover={{
            bg: "#f06e26",
            color: "white",
          }}
          fontSize={"xs"}
          boxShadow="md"
          fontWeight={"bolder"}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="14"
              _groupHover={{
                color: "white",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    </LinkReact>
  );
}
