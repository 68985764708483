/* eslint-disable react-hooks/rules-of-hooks */
// add filter table
import * as React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Box,
  Tfoot,
  TableContainer,
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useColorModeValue } from "@chakra-ui/react";
import ButtonComponents from "../Form/Button";
import Filter from "./Filter";
import SelectForm from "../Form/SelectForm";
import TextComponent from "../Form/Text";
import FormControlDefaultComponent from "../Form/FormControlDefault";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  edit: any;
  onOpenUpdate: any;
  setArray: any;
  total?: boolean;
  ExportExcel?: any;
  size?: string;
  estados?: any;
  totalcategorias?: boolean;
  totalcategorias_eliminados?: boolean;
  subirfactura_bool?: any;
  subirFactura?: any;
};

export function DataTable<Data extends object>({
  data,
  columns,
  edit,
  onOpenUpdate,
  setArray,
  total = false,
  ExportExcel = () => {},
  size = "",
  estados = [],
  totalcategorias = false,
  totalcategorias_eliminados = false,
  subirfactura_bool = false,
  subirFactura = () => {},
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
    },
  });

  const dinero = (value: number) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(value);
  };

  return (
    <>
      <TableContainer>
        <Table variant="striped" colorScheme="gray" size="md">
          <Thead
            borderBottom="2px solid"
            borderBottomColor="#f06e26"
            style={{
              backgroundColor: "#f3f3f3",
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => {
                  const meta: any = header.column.columnDef.meta;
                  return (
                    <Th
                      key={header.id}
                      isNumeric={meta?.isNumeric}
                      color={useColorModeValue("black", "black")}
                    >
                      <div>
                        <Text
                          fontSize={14}
                          fontWeight={600}
                          textAlign={"center"}
                          marginTop={header.column.getCanFilter() ? "0px" : -30}
                          marginBottom={
                            header.column.getCanFilter() ? "5px" : 0
                          }
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Text>
                      </div>

                      {header.column.getCanFilter() ? (
                        // filtrar si viene Cliente/Posible que sea con un cehckbox
                        header.column.columnDef.header === "Cliente/Posible" ? (
                          <div
                            style={{
                              padding: "12px",
                            }}
                          >
                            <CheckboxGroup>
                              <Checkbox
                                colorScheme="green"
                                size="md"
                                onChange={(e) => {
                                  header.column.setFilterValue(
                                    e.target.checked ? "Cliente" : ""
                                  );
                                }}
                                marginRight={3}
                              >
                                Cliente
                              </Checkbox>
                              <Checkbox
                                colorScheme="green"
                                size="md"
                                onChange={(e) => {
                                  header.column.setFilterValue(
                                    e.target.checked ? "Posible cliente" : ""
                                  );
                                }}
                              >
                                Posible Cliente
                              </Checkbox>
                            </CheckboxGroup>
                          </div>
                        ) : header.column.columnDef.header === "Estado" ? (
                          <div>
                            <select
                              onChange={(e) => {
                                header.column.setFilterValue(e.target.value);
                              }}
                              style={{
                                padding: "10px",
                                width: "200px",
                                borderRadius: "10px",
                              }}
                            >
                              <option value="">Todos</option>
                              {estados.map((estado: any) => (
                                <option key={estado.id} value={estado.id}>
                                  {estado.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <div>
                            <Filter
                              column={header.column}
                              table={table}
                              size={size}
                            />
                          </div>
                        )
                      ) : null}
                    </Th>
                  );
                })}
                {edit && <Th></Th>}
                {subirfactura_bool && (
                  <Th>
                    <Text
                      fontSize={14}
                      fontWeight={600}
                      textAlign={"center"}
                      marginTop={-31}
                      marginBottom={"5px"}
                      color={"black"}
                    >
                      Factura
                    </Text>
                  </Th>
                )}
              </Tr>
            ))}
          </Thead>
          {size === "" ? (
            <Tbody>
              <Tr></Tr>
              <Tr>
                <Td
                  colSpan={edit ? columns.length + 1 : columns.length}
                  textAlign={"end"}
                  style={{ backgroundColor: "#fff !important" }}
                  className="bg-white"
                >
                  <ButtonComponents
                    text="Reporte general"
                    color="#3aa817"
                    onClick={() => ExportExcel(data)}
                    size="sm"
                  />
                </Td>
              </Tr>
            </Tbody>
          ) : null}
          <Tbody>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => {
                  return (
                    <Th
                      key={header.id}
                      color={useColorModeValue("black", "black")}
                    >
                      <div>
                        <Text
                          fontSize={14}
                          fontWeight={600}
                          textAlign={"center"}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Text>
                      </div>
                    </Th>
                  );
                })}
                {subirfactura_bool && (
                  <Th>
                    <Text
                      fontSize={14}
                      fontWeight={600}
                      textAlign={"center"}
                      marginBottom={"5px"}
                      color={"black"}
                    >
                      Factura
                    </Text>
                  </Th>
                )}
              </Tr>
            ))}

            {table.getRowModel().rows.map((row: any) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell: any) => {
                  const meta: any = cell.column.columnDef.meta;
                  return (
                    <Td
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      color={useColorModeValue("black", "black")}
                      fontSize={size === "" ? "20px" : "14px"}
                      padding={size === "" ? "15px" : "5px"}
                      textAlign={size === "" ? "center" : "center"}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  );
                })}
                {subirfactura_bool && (
                  <Td>
                    {row?.original?.factura ? (
                      <ButtonComponents
                        color={useColorModeValue("green", "#711c3d")}
                        size="sm"
                        text="Ver factura"
                        onClick={() => {
                          const url =
                            "https://api-crm.contactostihl.com/images/factura/" +
                            row?.original?.factura;
                          window.open(url, "_blank");
                        }}
                      />
                    ) : (
                      <>
                        <input
                          type="file"
                          className="form-control"
                          style={{
                            width: "200px",
                          }}
                          accept="application/pdf, image/*"
                          onChange={(e) => subirFactura(e, row.original)}
                        />
                      </>
                    )}
                  </Td>
                )}
                {edit ? (
                  <Td textAlign={"center"}>
                    <ButtonComponents
                      color={useColorModeValue("#2f5941", "#711c3d")}
                      size="xs"
                      text="Editar"
                      onClick={() => {
                        setArray(row.original);
                        onOpenUpdate();
                      }}
                    />
                  </Td>
                ) : null}
              </Tr>
            ))}
          </Tbody>
          {total ? (
            <Tfoot>
              <Tr style={{ backgroundColor: "#f3f3f3", textAlign: "start" }}>
                <Th>Total</Th>
                <Th>
                  {table.getRowModel().rows.reduce((acc, row): any => {
                    const array: any = row.original;
                    return acc + parseInt(array?.cantidad);
                  }, 0)}
                </Th>
                <Th>
                  {dinero(
                    table.getRowModel().rows.reduce((acc, row): any => {
                      const array: any = row.original;
                      return acc + parseFloat(array?.precio);
                    }, 0)
                  )}
                </Th>
                <Th>
                  {dinero(
                    table.getRowModel().rows.reduce((acc, row): any => {
                      const array: any = row.original;
                      return acc + parseFloat(array?.total);
                    }, 0)
                  )}
                </Th>
              </Tr>
            </Tfoot>
          ) : null}

          {totalcategorias ? (
            <Tfoot>
              <Tr style={{ backgroundColor: "#f3f3f3", textAlign: "center" }}>
                <Th textAlign={"center"} fontSize={15}>
                  Total
                </Th>
                <Th textAlign={"center"} fontSize={15}>
                  {table.getRowModel().rows.reduce((acc, row): any => {
                    const array: any = row.original;
                    return acc + parseInt(array?.cantidad);
                  }, 0)}
                </Th>
                <Th textAlign={"center"} fontSize={15}>
                  {dinero(
                    table.getRowModel().rows.reduce((acc, row): any => {
                      const array: any = row.original;
                      return acc + parseFloat(array?.total ?? 0);
                    }, 0)
                  )}
                </Th>
              </Tr>
            </Tfoot>
          ) : null}

          {totalcategorias_eliminados ? (
            <Tfoot>
              <Tr style={{ backgroundColor: "#f3f3f3", textAlign: "center" }}>
                <Th textAlign={"center"} fontSize={15}>
                  Total
                </Th>
                <Th></Th>
                <Th textAlign={"center"} fontSize={15}>
                  {table.getRowModel().rows.reduce((acc, row): any => {
                    const array: any = row.original;
                    return acc + parseInt(array?.cantidad);
                  }, 0)}
                </Th>
                <Th textAlign={"center"} fontSize={15}>
                  {dinero(
                    table.getRowModel().rows.reduce((acc, row): any => {
                      const array: any = row.original;
                      return acc + parseFloat(array?.total ?? 0);
                    }, 0)
                  )}
                </Th>
              </Tr>
            </Tfoot>
          ) : null}
        </Table>
      </TableContainer>
      <Box
        display={"flex"}
        justifyContent="end"
        alignItems={"center"}
        gap={5}
        mt={5}
      >
        <TextComponent
          text={`Pagina ${
            table.getState().pagination.pageIndex + 1
          } de ${table.getPageCount()}`}
          color="#000"
          fontSize={"15px"}
          fontWeight="600"
          textAlign={"center"}
        />

        {table.getCanPreviousPage() && (
          <ButtonComponents
            color={useColorModeValue("#f06e26", "#711c3d")}
            onClick={() => table.setPageIndex(0)}
            size="sm"
            text="<<"
            borderRadius="10px"
          />
        )}
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.previousPage()}
          size="sm"
          text="<"
          borderRadius="10px"
        />
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.nextPage()}
          size="sm"
          text=">"
          borderRadius="10px"
        />
        <ButtonComponents
          color={useColorModeValue("#f06e26", "#711c3d")}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          size="sm"
          text=">>"
          borderRadius="10px"
        />

        <Box mt={5}>
          <FormControlDefaultComponent
            label=""
            helperText="Ir a la pagina"
            type="text"
            placeholder="Ir a la pagina"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
        </Box>

        <Box>
          <SelectForm
            helperText=""
            placeholder="Seleccione una opcion"
            label=""
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </SelectForm>
        </Box>
      </Box>
    </>
  );
}
