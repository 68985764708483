export function options(mes: any, anio: any, mes2: any, anio2: any, tipo: any) {
  return {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: `Registro comparativo de ${tipo} desde [ ${mes} ${anio} - ${mes2} ${anio2}]`,
      },
    },
  };
}

export function generateData(
  clientes_fuente: any,
  posibles_fuente: any,
  ventas_fuente: any,
  tipoCliente: any,
  tipoLead: any,
  tipoVenta: any
) {
  let data = [];
  const fuentes = clientes_fuente.map((item: any) => item.fuente);
  const fuentes_posibles = posibles_fuente.map((item: any) => item.fuente);
  const fuentes_ventas = ventas_fuente.map((item: any) => item.fuente);

  // unir los arreglos de fuentes y eliminar repetidos
  const fuentes_unidas = fuentes.concat(fuentes_posibles, fuentes_ventas);
  const fuentes_sin_repetir = fuentes_unidas.filter(
    (item: any, index: any) => fuentes_unidas.indexOf(item) === index
  );

  // clientes [] tiene que unir la fuente_sin_repetir con la fuente del cliente y dar el total en la posicion de ese arreglo
  const clientes = fuentes_sin_repetir.map((item: any) => {
    const index = clientes_fuente.findIndex(
      (element: any) => element.fuente === item
    );
    return index === -1 ? 0 : clientes_fuente[index].total;
  });

  const dataClientes = {
    label: "Clientes",
    data: clientes,
    borderColor: "rgb(255, 99, 132)",
  };

  if(tipoCliente) {
    data.push(dataClientes);
  }

  const posibles = fuentes_sin_repetir.map((item: any) => {
    const index = posibles_fuente.findIndex(
      (element: any) => element.fuente === item
    );
    return index === -1 ? 0 : posibles_fuente[index].total;
  })

  // hacer lo mismo con posibles y ventas
  const dataPosibles = {
    label: "Posibles clientes",
    data: posibles,
    borderColor: "rgb(54, 162, 235)",
  };

  if(tipoLead) {
    data.push(dataPosibles);
  }

  const ventas = fuentes_sin_repetir.map((item: any) => {
    const index = ventas_fuente.findIndex(
      (element: any) => element.fuente === item
    );
    return index === -1 ? 0 : ventas_fuente[index].total;
  })

  const dataVentas = {
    label: "Ventas en pesos",
    data: ventas,
    borderColor: "rgb(255, 205, 86)",
  };

  if(tipoVenta) {
    data.push(dataVentas);
  }

  return {
    labels: fuentes_sin_repetir,
    datasets: data,
  };
}

export function generateDataComparativo(
  clientes: any,
  posibles: any,
  ventas: any,
  tipoCliente: any,
  tipoLead: any,
  tipoVenta: any
) {
  const clientes_datos = clientes.map((item: any) => item.total);
  const leads = posibles.map((item: any) => item.total);
  const ventas_echa = ventas.map((item: any) => {
    const total = item.total;
    const replacecomas = total.replace(/,/g, "");
    return parseFloat(replacecomas);
  });
  const labels = clientes.map((item: any) => item.mes);
  let data = [];

  const dataClientes = {
    label: "Clientes",
    data: clientes_datos,
    borderColor: "rgb(255, 99, 132)",
  };

  if (tipoCliente) {
    data.push(dataClientes);
  }

  const dataLeads = {
    label: "Posibles clientes",
    data: leads,
    borderColor: "rgb(54, 162, 235)",
  };

  if (tipoLead) {
    data.push(dataLeads);
  }

  const dataVentas = {
    label: "Ventas en pesos",
    data: ventas_echa,
    borderColor: "rgb(255, 205, 86)",
  };

  if (tipoVenta) {
    data.push(dataVentas);
  }

  return {
    labels,
    datasets: data,
  };
}

export function Columns() {
  return [
    { title: "Fuente", campo: "fuente" },
    { title: "Cantidad", campo: "cantidad" },
  ];
}

export const selectYears = [
  { value: "2018", label: "2018" },
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
  { value: "2023", label: "2023" },
  { value: "2024", label: "2024" },
  { value: "2025", label: "2025" },
];

export const selectMeses = [
  { value: "01", label: "Enero" },
  { value: "02", label: "Febrero" },
  { value: "03", label: "Marzo" },
  { value: "04", label: "Abril" },
  { value: "05", label: "Mayo" },
  { value: "06", label: "Junio" },
  { value: "07", label: "Julio" },
  { value: "08", label: "Agosto" },
  { value: "09", label: "Septiembre" },
  { value: "10", label: "Octubre" },
  { value: "11", label: "Noviembre" },
  { value: "12", label: "Diciembre" },
];

export const selectOpcion = [
  { value: "1", label: "Semana" },
  { value: "2", label: "Mes" },
];
