/* eslint-disable import/no-anonymous-default-export */
function Columns() {
  return [
    { title: "Nombre", campo: "name" },
    { title: "Correo", campo: "correo" },
    { title: "Telefono", campo: "telefono" },
    { title: "Fecha", campo: "fecha_creacion" },
    { title: "Estado", campo: "estado" },
    { title: "Estatus", campo: "estatus" },
    { title: "Acciones", campo: "acciones" },
  ];
}

function ColumnsCliente() {
  return [
    { title: "Nombre", campo: "name" },
    { title: "Correo", campo: "correo" },
    { title: "Telefono", campo: "telefono" },
    { title: "Fecha", campo: "fecha_creacion" },
    { title: "Estado", campo: "estado" },
    { title: "Acciones", campo: "acciones" },
  ];
}


export default {
  Columns,
  ColumnsCliente
};
